import "../../StyleSheet/dashboard/compliancehome.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import svg from "../../../Assets/Client/svgviewer-output.svg";
import { useFetchCompliance } from "../../../Api/PropertiesQuery";
import { useFetchComplianceStats } from "../../../Api/ComplianceQuery";
import { useDispatch, useSelector } from "react-redux";
import CompliancePopup from "./popups/compliencepopup";
import { propertycounters } from "../../../redux/reducer/counterslice";
export default function ComplianceHome() {
  // const [Landlord1, setLandlord1] = useState(12);
  const [popupstate, setPopupstate] = useState(false);
  const [popuptext, setPopuptext] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [selecteddata, setSelectedData] = useState([]);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.counter.loginuserdata.id);
  const clientid = useSelector((state) => state.counter.clientid);

  const fetchCompliance = useFetchCompliance({
    params: { page: 1, page_size: 1000, type: "", client: clientid },
  });
  const fetchComplianceStats = useFetchComplianceStats(userId);
  const [complianceStatus, setComplianceStatus] = useState([]);
  useEffect(() => {
    console.log(fetchComplianceStats.data?.data, "adfkjdflfkdj");
    if (fetchComplianceStats.data?.data) {
      setComplianceStatus(fetchComplianceStats.data?.data.document_count);
      dispatch(
        propertycounters(fetchComplianceStats.data?.data.property_count)
      );
    }
  }, [fetchComplianceStats.data]);

  return (
    <>
      <div className="compliancepage_wrapper h-100">
        <div className="complaince-details h-100">
          <div className="complaince_list_header" style={{}}>
            <h1> Compliance </h1>
            <div
              className="due-overdue"
              style={{
                display: "flex",
                gap: "30px",
                height: "100%",
                alignItems: "flex-end",
                width: "calc(100% - 498px)",
                justifyContent: "space-between",
              }}
            >
              <div className="nodue">
                <span>Not Due</span>
                <div className="badge1"> </div>
              </div>
              <div className="due">
                <span>Due</span>
                <div className="badge2"> </div>
              </div>
              <div className="overdue">
                <span>Overdue</span>
                <div className="badge3"> </div>
              </div>
            </div>
          </div>
          <div className="due-details-list">
            <ul className="list-view" style={{ border: "none" }}>
              {complianceStatus.length > 0 &&
                complianceStatus.map((ele, index) => {
                  console.log(ele);
                  return (
                    <>
                      <li
                        onClick={() => {
                          setPopupstate(true);
                          setPopuptext(ele.document_type);
                          setActiveTab("All");
                          setSelectedData(ele);
                        }}
                        className={`lists ${ele.over_due > 0 && ""} ${
                          ele.renewal_due > 0 && ""
                        }`}
                        //   style={{
                        //     display: "flex",
                        //     justifyContent: "space-between",
                        //   }}
                        style={{
                          borderBottom: "3px dashed  rgba(210, 214, 224, 0.6)",
                          borderBottomStyle: "dashed",
                          // borderTopWidth: "2px",
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "space-between",
                          borderTop:
                            index === 0
                              ? "3px dashed rgba(210, 214, 224, 0.6)"
                              : "none",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          {" "}
                          {ele?.icon ? (
                            <div
                              style={{
                                background: "#F3F3F7",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40px",
                                height: "40px",
                                padding: "25px",
                              }}
                            >
                              <img
                                src={ele?.icon ? ele?.icon : svg}
                                style={{ width: "25px", height: "25px" }}
                              ></img>
                            </div>
                          ) : (
                            <img src={svg} />
                          )}
                          {ele.document_type}
                        </span>
                        <div
                          className="listdue"
                          style={{
                            display: "flex",
                            gap: 63,
                            width: "calc(100% - 580px)",
                            justifyContent: "space-between",
                            marginRight: "44px",
                          }}
                        >
                          <div
                            style={{ color: "#4DDE87" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (ele.not_due_count != 0) {
                                setActiveTab("Not Due");
                                setSelectedData(ele);
                                setPopupstate(true);
                              }
                            }}
                          >
                            {ele.not_due_count}
                          </div>
                          <div
                            style={{ color: "#FFB200" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (ele.due_count != 0) {
                                setActiveTab("Due");
                                setSelectedData(ele);
                                setPopupstate(true);
                              }
                            }}
                          >
                            {" "}
                            {ele.due_count}
                          </div>
                          <div
                            style={{ color: "#FF3A29" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (ele.overdue_count != 0) {
                                setActiveTab("Overdue");
                                setSelectedData(ele);
                                setPopupstate(true);
                              }
                            }}
                          >
                            {ele.overdue_count}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      {popupstate && (
        <>
          <CompliancePopup
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setPopupstate={setPopupstate}
            popuptext={popuptext}
            selecteddata={selecteddata}
          />
        </>
      )}
    </>
  );
}
