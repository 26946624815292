import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./Routes/routes";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "../src/redux/store/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import { PersistGate } from "redux-persist/integration/react";
import CircularIndeterminate from "./MainComponent/Loader";
import SideBarComponent from "./Client/GlobalComponents/Sidebar/sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Toaster richColors />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={Routes}>
          <CircularIndeterminate />
        </RouterProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
