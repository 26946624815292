import React, { useState, useEffect } from "react";
import "./style.scss";
import { PasswordInput } from "../../GlobalComponents/othercomponents/PasswordInput";
import logo from "../../../Assets/Login/Logo_main.svg";
import solace from "../../../Assets/Login/Solace.svg";
import solaceCorner from "../../../Assets/Login/Solace_corner.svg";
// import { useLogin } from '../../Context/queries';
import Arrow from "../../../Assets/Common/Arrow.svg";
import axios from "axios";
import { loginuserdata, clientid } from "../../../redux/reducer/counterslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLogin } from "../../../Api/PropertiesQuery";
export default function ActivateForm() {
  const [UserDetails, setUserDetails] = useState({ email: "", password: "" });
  const userdata = useSelector((state) => state.counter.loginuserdata);
  const dispatch = useDispatch();
  const loginApi = useLogin({
    email: UserDetails.email,
    password: UserDetails.password,
  });
  useEffect(() => {
    console.log(userdata, "kjhkjfdhuserdata");
    dispatch(clientid(userdata.id));
  }, [userdata]);

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append("email", UserDetails.email);
    formdata.append("password", UserDetails.password);
    loginApi.mutate();
    console.log(loginApi, "ajfkdjlkj");
  };
  return (
    <>
      <div className="loginMain">
        <div className="loginLeft">
          <div className="backgroundImage">
            <div className="innerContent">
              <div className="innerTop">
                <img src={logo} alt="logo" />
                <img src={solace} alt="solace" />
                <div className="borderDiv" />
                <p>Client Portal</p>
              </div>
            </div>
          </div>
        </div>
        <div className="loginRight">
          <div className="loginRightMainImg1">
            <img src={solaceCorner} alt="solaceCorner" />
          </div>
          <form
            className="innerContent"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2>Welcome</h2>
            <p>Sign into your Solace Account</p>
            <input
              className="inputStyle"
              type="text"
              placeholder="Email"
              value={UserDetails.email}
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
            />
            <div style={{ width: "85%", marginBottom: "20px" }}>
              <PasswordInput
                name="password"
                type="text"
                placeholder="Password"
                value={UserDetails.password}
                onChange={(e) => {
                  setUserDetails((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }));
                }}
              />
            </div>
            <a href="/ForgetPassword">Forgot Password?</a>
            <div className="button_container">
              <button
                type="submit"
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Login
                <img
                  src={Arrow}
                  alt="arrow"
                  style={{ marginLeft: "10px" }}
                ></img>
              </button>
            </div>
          </form>
        </div>
        <div className="loginMainImg1">
          <img src={solaceCorner} alt="solaceCorner" />
        </div>
      </div>
    </>
  );
}
