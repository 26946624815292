import React, { useEffect, useState } from "react";
import "../../../StyleSheet/Property/popups/propertydetails.scss";
import name from "../../../../Assets/Common/name.svg";
import email from "../../../../Assets/Common/email.svg";
import telephone from "../../../../Assets/Common/telephone.svg";
import address from "../../../../Assets/Common/address.svg";
import { formatAddress } from "../../../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setContactDetailsFilter } from "../../../../redux/reducer/counterslice";

const PropertyPropertyContacts = (props) => {
  const { dataFromParent, fromContact } = props;
  const tagActive = useSelector((state) => state.counter.contactDetailsFilter);
  const dispatch = useDispatch();
  const formatUKPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return ""; // Return an empty string or handle the case as needed
    }

    return phoneNumber.replace(/^0/, "+44 ").replace(/(\d{4})(\d{6})/, "$1 $2");
  };
  const data = [
    {
      image: name,
      title: "Name",
      data:
        dataFromParent?.landlord?.contact_detail?.[0]?.title +
        " " +
        dataFromParent?.landlord?.contact_detail?.[0]?.first_name +
        " " +
        dataFromParent?.landlord?.contact_detail?.[0]?.last_name,
    },
    {
      image: telephone,
      title: "Telephone",
      data: formatUKPhoneNumber(
        dataFromParent?.landlord?.contact_detail?.[0]?.telephone?.[0]?.number
      ),
    },
    {
      image: email,
      title: "Email",

      data: dataFromParent?.landlord?.contact_detail?.[0]?.email?.[0],
    },
    {
      image: address,
      title: "Address",
      data: formatAddress([
        dataFromParent?.landlord?.property_landlord_address?.[0]?.Name +
          " " +
          dataFromParent?.landlord?.property_landlord_address?.[0]?.street,
        dataFromParent?.landlord?.property_landlord_address?.[0]?.town,
        dataFromParent?.landlord?.property_landlord_address?.[0]?.postcode,
      ]),
    },
  ];
  const tenantData = [
    {
      image: name,
      title: "Name",
      data:
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.title +
        " " +
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.first_name +
        " " +
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.last_name,
    },
    {
      image: telephone,
      title: "Telephone",
      data: formatUKPhoneNumber(
        dataFromParent?.tenant?.tenant__contact_detail?.[0]?.telephone?.[0]
          ?.number
      ),
    },
    {
      image: email,
      title: "Email",

      data: dataFromParent?.tenant?.tenant__contact_detail?.[0]?.email?.[0],
    },
  ];
  const contactTenantData = [
    {
      image: name,
      title: "Name",
      data:
        dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
          ?.contact_detail?.[0]?.title +
        " " +
        dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
          ?.contact_detail?.[0]?.first_name +
        " " +
        dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
          ?.contact_detail?.[0]?.last_name,
    },
    {
      image: telephone,
      title: "Telephone",
      data: formatUKPhoneNumber(
        dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
          ?.contact_detail?.[0]?.telephone?.[0]?.number
      ),
    },
    {
      image: email,
      title: "Email",

      data: dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
        ?.contact_detail?.[0]?.email?.[0],
    },
  ];

  useEffect(() => {
    console.log(dataFromParent, "lam;lfkaskfasfla;sfk");
    console.log(
      dataFromParent?.tenancies?.[0]?.tenancies?.[0]?.tenant
        ?.contact_detail?.[0]?.title,
      "lam;lfkaskfasfla;sfk"
    );
  }, [dataFromParent]);
  const title = dataFromParent?.tenant?.tenant__contact_detail?.[0]?.title;

  return (
    <div className="propertyContactsWrapper">
      <div className="button_wrapper">
        <button
          className={tagActive === 1 ? "active" : ""}
          onClick={() => {
            dispatch(setContactDetailsFilter(1));
          }}
        >
          Landlord
        </button>
        <button
          className={tagActive === 2 ? "active" : ""}
          onClick={() => {
            dispatch(setContactDetailsFilter(2));
          }}
        >
          Tenant
        </button>
      </div>

      <div className="contentDiv">
        {tagActive === 1 && (
          <div className="landlordDetails">
            <p className="title">Landlord Contact Details</p>
            <div className="detailsSectionWrapper">
              {data.map((items, index) => {
                return (
                  <div key={index} className="singleRecord">
                    <div className="leftSection">
                      <img src={items.image} alt={index} />
                      <span>{items.title}</span>
                    </div>
                    <p>{items.data}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {tagActive === 2 && (
          <div className="landlordDetails">
            <p className="title">Tenant Details</p>
            <div className="detailsSectionWrapper">
              <div className="detailsSectionWrapper">
                {!fromContact ? (
                  title ? (
                    tenantData.map((items, index) => (
                      <div key={index} className="singleRecord">
                        <div className="leftSection">
                          <img src={items.image} alt={index} />
                          <span>{items.title}</span>
                        </div>
                        <p>{items.data}</p>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p>No tenant assigned to this property</p>
                    </div>
                  )
                ) : (
                  contactTenantData.map((items, index) => (
                    <div key={index} className="singleRecord">
                      <div className="leftSection">
                        <img src={items.image} alt={index} />
                        <span>{items.title}</span>
                      </div>
                      <p>{items.data}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyPropertyContacts;
