import { useState } from "react";
import "../../StyleSheet/clientmoney/clientmoney.scss";
import ClientMoneyOverview from "./clientmoneyoverview";
import Accounts from "./accounts";
import PaymentDue from "./paymentdue";
import Reports from "./reports";
import { useDispatch, useSelector } from "react-redux";
import { setClientMoneyStatus } from "../../../redux/reducer/counterslice";
export default function ClientMoney() {
  const toggle = useSelector((state) => state.counter.clientMoneyStatus);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="ClientMoney">
      <div className="ClientMoney_main">
        <div className="toggle_header">
          <p
            onClick={(e) => {
              dispatch(setClientMoneyStatus(e.target.innerText));
            }}
            className={toggle === "Overview" ? "active" : ""}
          >
            Overview
          </p>
          <p
            onClick={(e) => {
              dispatch(setClientMoneyStatus(e.target.innerText));
            }}
            className={toggle === "Accounts" ? "active" : ""}
          >
            Accounts
          </p>
          <p
            onClick={(e) => {
              dispatch(setClientMoneyStatus(e.target.innerText));
            }}
            className={toggle === "Payments" ? "active" : ""}
          >
            Payments
          </p>
          <p
            onClick={(e) => {
              dispatch(setClientMoneyStatus(e.target.innerText));
            }}
            className={toggle === "Reports" ? "active" : ""}
          >
            Reports
          </p>
        </div>
        <div className="overview_main">
          {toggle === "Overview" ? (
            <>
              <ClientMoneyOverview />
            </>
          ) : toggle === "Accounts" ? (
            <Accounts />
          ) : toggle === "Payments" ? (
            <PaymentDue />
          ) : toggle === "Reports" ? (
            <Reports />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
