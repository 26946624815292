import { useState, useEffect, useMemo } from "react";
import "../../StyleSheet/insepection/periodicinsepection.scss";
import GenerateReport from "../../../MainComponent/fields/generatereport";
import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import {
  useFetchComplianceSpecId,
  useFetchComplianceStatscount,
  useFetchPeriodicId,
} from "../../../Api/ComplianceQuery";
import { useDispatch, useSelector } from "react-redux";
import download from "../../../Assets/Common/Download.svg";
import dayjs from "dayjs";
import { addLeadingZero } from "../../../Utils/Utils";
import { setPeriodicInspectionStatus } from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";

export default function Insepection() {
  const [chartData, setChartData] = useState([0, 0, 0]);
  const dispatch = useDispatch();
  const [search, setsearch] = useState("");
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const subColor = ["#dbf8e7", "#fff0cc", "#ffd8d4"];

  const text = ["Not Due", "Due", "Overdue"];
  const columnHelper = createColumnHelper();
  const [Generate, setGenerate] = useState("");
  const clientid = useSelector((state) => state.counter.clientid);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const complianceList = useSelector(
    (state) => state.counter.periodicInspection
  );

  const fetchCompliance = useFetchPeriodicId({
    type: "property",
    status: complianceList,
    client: clientid,
    serach: search,
  });
  const Fetchcountdata = useFetchComplianceStatscount({
    params: {
      client: clientid,
    },
  });
  useEffect(() => {
    // console.log(complianceSelected, complianceType, "lakjsldkfljsdljf");
    console.log(fetchCompliance?.data?.data?.results, "lakjsfdljasjfdl");
    if (fetchCompliance.data?.data?.results) {
      setTableData(fetchCompliance.data.data.results);
      setCount(fetchCompliance.data?.data?.count);
    }
  }, [fetchCompliance.data, complianceList, search]);
  const [totalProperties, setTotalProperties] = useState(0);

  useEffect(() => {
    // console.log(Fetchcountdata.data.data, "lakjsldflaskldjfajsf");
    if (Fetchcountdata?.data?.data != undefined) {
      setChartData([
        Fetchcountdata?.data?.data?.document_count[0]?.not_due_count,
        Fetchcountdata?.data?.data?.document_count[0]?.due_count,
        Fetchcountdata?.data?.data?.document_count[0]?.overdue_count,
      ]);
      setTotalProperties(
        Fetchcountdata?.data?.data?.document_count[0]?.not_due_count +
          Fetchcountdata?.data?.data?.document_count[0]?.due_count +
          Fetchcountdata?.data?.data?.document_count[0]?.overdue_count
      );
    }
  }, [Fetchcountdata.data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("expiry_date", {
        header: "Next Due Date",
        cell: (info) => (
          <p style={{ wordBreak: "break-all" }}>
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        ),
      }),
      columnHelper.accessor("status", {
        cell: (info) => {
          console.log(info.row.original, "kajslfjaksdf");
          const data = info.row.original.status;
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <div
                className="status_button"
                style={{
                  background:
                    data === "overdue"
                      ? "#FFD3CF"
                      : data === "due"
                      ? "#FFE9B5"
                      : data === "not_due" && "#CEFFE1",
                  width: "15px",
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15px",
                }}
              ></div>
              <span>
                {data === "overdue"
                  ? "Overdue"
                  : data === "due"
                  ? "Due"
                  : data === "not_due" && "Not Due"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("property_address", {
        header: "Address",

        cell: (info) => (
          <p style={{ wordBreak: "break-all" }}>{info.getValue()}</p>
        ),
      }),

      columnHelper.accessor("file", {
        header: "Document",
        enableSorting: false,
        cell: (info) => (
          <p
            style={{
              color: "#817EFF",
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={() => {
              window.open(info.getValue(), "_blank");
            }}
          >
            Download
            <img src={download} alt="download" />
          </p>
        ),
      }),
    ],
    []
  );
  const handleFilerChange = (value) => {
    dispatch(setPeriodicInspectionStatus(value));
  };
  return (
    <div className="Insepection">
      <div className="Insepection_main">
        <div className="Insepection-header">
          <h1>Periodic Inspection</h1>
          <div className="progress_wrapper row">
            {chartData.length > 0 &&
              chartData.map((item, index) => {
                // Calculate the percentage width based on the total number of properties
                const percentage = (item / totalProperties) * 100;

                return (
                  <div className="col-3" style={{ width: "29%" }} key={index}>
                    <div className="progress_main">
                      <div className="main_text">
                        <span className="text">
                          {text[index % text.length]}
                        </span>
                        <span style={{ color: colors[index % colors.length] }}>
                          {addLeadingZero(item)}
                        </span>
                      </div>

                      <div className="progress_bar">
                        <div
                          className="progress progress-sm"
                          style={{
                            height: "8px",
                            backgroundColor: subColor[index % subColor.length],
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              width: `${percentage}%`, // Use the calculated percentage
                              borderRadius: "1.25rem",
                              backgroundColor: colors[index % colors.length],
                            }}
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="divider"></div>
        <div className="main_tablecontent">
          <div className="table_filter">
            <div className="button_container">
              <button
                className={`${complianceList === "all" && "active"}`}
                onClick={() => {
                  handleFilerChange("all");
                }}
              >
                All
                <span>
                  {addLeadingZero(chartData[0] + chartData[1] + chartData[2])}
                </span>
              </button>
              <button
                className={`${complianceList === "not_due" && "active"}`}
                onClick={() => {
                  handleFilerChange("not_due");
                }}
              >
                Not Due
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[0])}
                </span>
              </button>{" "}
              <button
                className={`${complianceList === "due" && "active"}`}
                onClick={() => {
                  handleFilerChange("due");
                }}
              >
                Due
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[1])}
                </span>
              </button>{" "}
              <button
                className={`${complianceList === "overdue" && "active"}`}
                onClick={() => {
                  handleFilerChange("overdue");
                }}
              >
                Overdue
                <span style={{ fontSize: "14px" }}>
                  {addLeadingZero(chartData[2])}
                </span>
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Search setsearch={setsearch} style={{ width: "410px" }} />
              <GenerateReport
                style={{ fontSize: "14px" }}
                setGenerate={setGenerate}
              />
            </div>
          </div>
          <div
            className="table_compliance"
            style={{
              padding: "24px 26px 0px 0px",
              marginLeft: "19px",
            }}
          >
            <TableComponent
              data={tableData}
              columns={columns}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
