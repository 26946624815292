import React, { useEffect, useState } from "react";
import "../popups/PropertyCreate.scss";
// import { Button } from "../Button";
// import {
//   useAdminPost,
//   useAdminResendInvite,
//   useAdminUpdateAdmin,
//   useFetchClientsNoId,
// } from "../../Context/queries";
import { useDropzone } from "react-dropzone";
// import { CustomSelect } from "../CustomSelect";
// import { useFetchLandlordNoId } from "../../Api/ContactsQuery";
import loading from "../../../../Assets/Common/output-onlinegiftools (4).gif";
import closeIcon from "../../../../Assets/Common/closeIcon.svg";
import {
  useFetchLandlordNoId,
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePropertyCreatePost,
} from "../../../../Api/PropertiesQuery";
import { CustomButton } from "../../../GlobalComponents/CustomButton/CustomButton";
import { Padding } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CustomSelect } from "../../../GlobalComponents/CustomSelect";

const PropertyCreate = (props) => {
  const { isOpen, setIsOpen } = props;
  const [formDirty, setFormDirty] = useState(true);
  const [errors, setErrors] = useState({});
  //   const clientFetch = useFetchClientsNoId();
  const clientId = useSelector((state) => state.counter.loginuserdata.id);
  const fetchLandlord = useFetchLandlordNoId(clientId);
  const [uploadStatus, setUploadStatus] = useState("idel");
  const [data, setData] = useState({
    name: "",
    street: "",
    city: "",
    postcode: "",
    image: "",
    client: "",
    landlord: "",
    is_active: false,
    rooms: [{ bed: "", bath: "", living: "", garage: "" }],
  });
  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  useEffect(() => {
    setData((prev) => ({ ...prev, client: clientId }));
  }, [clientId]);

  const handleChange = (e) => {
    setFormDirty(false);
    const { name, value } = e.target;
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validation rules
    if (!data.name.trim()) {
      newErrors.first_name = "Property name is required";
      valid = false;
    }
    if (!data.street.trim()) {
      newErrors.last_name = "Street is required";
      valid = false;
    }
    if (!data.city.trim()) {
      newErrors.email = "City is required";
      valid = false;
    }
    if (!data.postcode.trim()) {
      newErrors.mobile_number = "Postcode is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleClose = () => {
    setData({
      name: "",
      street: "",
      city: "",
      postcode: "",
      client: "",
      landlord: "",
      image: [],
      is_active: false,
    });
    setErrors({});
    setCoImage([]);
    setIsOpen(false);
  };

  const formFields = [
    { name: "name", label: "Name / No", type: "text" },
    { name: "street", label: "Street", type: "text" },
    { name: "city", label: "Town / City", type: "text" },
    { name: "postcode", label: "Postcode", type: "text" },
  ];
  const roomFormFields = [
    { name: "bed", label: "Bedroom", type: "number" },
    { name: "bath", label: "Bathroom", type: "number" },
    { name: "living", label: "Living Room", type: "number" },
    { name: "garage", label: "Garage", type: "number" },
  ];

  const [coImage, setCoImage] = useState([]);
  const [enrollPresignedImage, setEnrollPresignedImage] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((upFile) =>
        Object.assign(upFile, {
          preview: window.URL.createObjectURL(upFile),
        })
      );
      setUploadStatus(`pending${newFiles[0].name}`);
      console.log(newFiles[0].name, "newFiles");
      setCoImage([...coImage, ...newFiles]);
      setEnrollPresignedImage(newFiles);
    },
  });

  const removefunction = (indexValue) => {
    const updatedCoImage = coImage.filter((_, i) => i !== indexValue);
    setCoImage(updatedCoImage);
    setData((prevData) => {
      const updatedImages = prevData.image.filter((_, i) => i !== indexValue);
      return {
        ...prevData,
        image: updatedImages,
      };
    });
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLandlordOption, setSelectedLandlordOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [landlordOptions, setlandlordOptions] = useState([]);

  const handleClientDropChange = (e) => {
    setSelectedOption(e);
    setData({
      ...data,
      client: e.value,
    });
  };
  const handleLandlordDropChange = (e) => {
    setSelectedLandlordOption(e);
    setData({
      ...data,
      landlord: e.value,
    });
  };

  // useEffect(() => {
  //   console.log(clientFetch.data.data.results, 'kansfjisajdfasd');
  // }, [clientFetch.data]);
  //   useEffect(() => {
  //     // console.log(clientResponse?.data?.data?.results, 'lajsldjfiaojisojdf');
  //     const clientDrop = clientFetch?.data?.data?.results?.map((item) => ({
  //       value: item.id,
  //       label:
  //         item.contact_detail?.[0]?.first_name +
  //         " " +
  //         item.contact_detail?.[0]?.last_name,
  //     }));
  //     setOptions(clientDrop);
  //   }, [clientFetch.data]);
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);

  useEffect(() => {
    if (enrollPresignedImage.length > 0) {
      setPresignedUrlPayload({
        multiple_files: [
          {
            filename: `property/${enrollPresignedImage[0].path}`,
            file_type: enrollPresignedImage[0].type,
          },
        ],
      });
      setTimeout(() => {
        createPresignedURL.mutate();
      }, 100);
    }
  }, [enrollPresignedImage]);
  useEffect(() => {
    const clientDrop = fetchLandlord?.data?.data?.results?.map((item) => ({
      value: item.id,
      label:
        item.contact_detail?.[0]?.first_name +
        " " +
        item.contact_detail?.[0]?.last_name,
    }));
    setlandlordOptions(clientDrop);
    console.log(fetchLandlord?.data?.data?.results, "laksldfjkfoajsldfjlsj");
  }, [fetchLandlord.data]);

  // const createPresignedURL = usePresignedUrlPost();
  const createProperty = usePropertyCreatePost({
    data: data,
    handleClose: handleClose,
  });
  const [presignedURL, setPresignedURL] = useState("");
  const [binaryImageData, setBinaryImageData] = useState("");
  const [imageType, setImageType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // handleClose();
    const isValid = validateForm(data, setErrors);
    if (isValid) {
      console.log(data, "ljkiaolfsdjkaslfdj");
      createProperty.mutate();
    }
  };

  const updatePresignedUrlPUT = usePresignedUrlPut({
    url: presignedURL,
    imagePayload: binaryImageData,
    type: imageType,
  });

  useEffect(() => {
    console.log(createPresignedURL.data, "data111");
    if (createPresignedURL.data?.data?.[0] !== undefined) {
      const removeQueryParams = (url) => url.split("?")[0];
      console.log("hitting bro", createPresignedURL.data.data[0]);
      const output = createPresignedURL.data.data.map(removeQueryParams);
      setPresignedURL(createPresignedURL.data.data[0]);
      setBinaryImageData(enrollPresignedImage[0]);
      setImageType(enrollPresignedImage[0].type);
      setData((prevValue) => ({
        ...prevValue,
        image: [...prevValue.image, ...output],
      }));
      setTimeout(() => {
        updatePresignedUrlPUT.mutate();
      }, 200);
    }
  }, [createPresignedURL.data]);

  useEffect(() => {
    var count = 0;
    if (updatePresignedUrlPUT.data != undefined && count == 0) {
      console.log(updatePresignedUrlPUT.data, "dkfujfj");
      count = 1;
      setUploadStatus(
        `success${updatePresignedUrlPUT.data?.config?.data?.name}`
      );
      setTimeout(() => {
        setUploadStatus("idel");
      }, 1000);
    }
  }, [updatePresignedUrlPUT.data]);
  return (
    <div>
      {isOpen && (
        <>
          <div className="overlay" />
          <div className="popup">
            <div className="popup-content">
              <div className="popup_top">
                <div />
                <h2>Add Property</h2>
                <button
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <img src={closeIcon} alt="closeIcon" />
                </button>
              </div>
              <div
                className="popup_center"
                style={{ maxHeight: "800px", overflow: "auto" }}
              >
                <form className="popup_form">
                  <div className="formContainer">
                    <p
                      style={{
                        width: "100%",
                        fontSize: "21px",
                        fontWeight: "500",
                        textAlign: "left",
                        marginTop: "210px",
                      }}
                    >
                      Details
                    </p>
                    <div className="innerWrapper">
                      {formFields.map((field, index) => (
                        <>
                          <div className="innerFormContainer" key={index}>
                            <label
                              htmlFor={field.name}
                              style={{ fontWeight: "500" }}
                            >
                              {field.label}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="customInput"
                              type={field.type}
                              id={field.name}
                              name={field.name}
                              value={data[field.name]}
                              onChange={handleChange}
                              autoComplete="off"
                              style={{ height: "35px", width: "90%" }}
                            />
                          </div>
                          {errors[field.name] && (
                            <div className="error">
                              <p>{errors[field.name]}</p>
                            </div>
                          )}
                        </>
                      ))}
                      <div
                        style={{
                          borderBottom: "1px solid black",
                          width: "100%",
                          height: "2px",
                        }}
                      ></div>
                      <p
                        style={{
                          width: "100%",
                          fontSize: "21px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Rooms
                      </p>
                      {roomFormFields.map((field, index) => (
                        <>
                          <div className="innerFormContainer" key={index}>
                            <label
                              htmlFor={field.name}
                              style={{ fontWeight: "500" }}
                            >
                              {field.label}
                            </label>
                            <input
                              className="customInput"
                              type={field.type}
                              id={field.name}
                              name={field.name}
                              value={data.rooms?.[0]?.[field.name]}
                              onChange={(e) => {
                                if (e.target.value.length < 2) {
                                  setData((prev) => ({
                                    ...prev,
                                    rooms: [
                                      {
                                        ...prev?.rooms?.[0],
                                        [field.name]: e.target.value,
                                      },
                                    ],
                                  }));
                                }
                              }}
                              autoComplete="off"
                              style={{ height: "35px", width: "90%" }}
                              placeholder="0"
                            />
                          </div>
                          {errors[field.name] && (
                            <div className="error">
                              <p>{errors[field.name]}</p>
                            </div>
                          )}
                        </>
                      ))}
                      <div
                        style={{
                          borderBottom: "1px solid black",
                          width: "100%",
                          height: "2px",
                        }}
                      ></div>
                      <div
                        className="innerWrapper"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            gap: "10px",
                            width: "42%",
                            marginTop: "13px",
                          }}
                        >
                          <label htmlFor="client" style={{ fontWeight: "500" }}>
                            Landlord
                          </label>
                          <div style={{ width: "100%" }}>
                            <CustomSelect
                              name={"typeTemplate"}
                              selectedOption={selectedLandlordOption}
                              handleSelectChange={handleLandlordDropChange}
                              options={landlordOptions}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <label htmlFor="client">Client</label>
                      <div style={{ width: '50%' }}>
                        <CustomSelect
                          name={'typeTemplate'}
                          selectedOption={selectedOption}
                          handleSelectChange={handleClientDropChange}
                          options={options}
                        />
                      </div>
                      <label htmlFor="client">Client</label>
                      <div style={{ width: '50%' }}>
                        <CustomSelect
                          name={'typeTemplate'}
                          selectedOption={selectedOption}
                          handleSelectChange={handleClientDropChange}
                          options={options}
                        />
                      </div> */}

                      <div className="Upload_pic">
                        <div
                          className={`imagedropper ${
                            coImage.length > 0 && "actives"
                          }`}
                        >
                          <div
                            {...getRootProps()}
                            className={`Imagedropper_inner`}
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              className="customInput"
                              {...getInputProps()}
                            />
                            {
                              <>
                                {coImage.length < 8 ? (
                                  <>
                                    <p>Drag photos here to upload</p>
                                  </>
                                ) : (
                                  <span> Max upload reached</span>
                                )}
                              </>
                            }
                          </div>
                          <div className="Image_Viewer">
                            {coImage.map((upFile, index) => {
                              console.log("upFile", uploadStatus);
                              return (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    // src={upFile[0].preview}
                                    src={upFile.preview}
                                    className="ImageView"
                                    alt={"preview"}
                                  />
                                  <span
                                    className="cancle_img"
                                    onClick={(e) => removefunction(index)}
                                  >
                                    x
                                  </span>
                                  <div className="status">
                                    {uploadStatus ===
                                      `pending${upFile.name}` && (
                                      <img src={loading}></img>
                                    )}
                                    {/* {uploadStatus ===
                                      `success${upFile.name}` && (
                                      <img src={success}></img>
                                    )} */}
                                    {uploadStatus === "error" && (
                                      <p>Upload failed. Please try again.</p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popup_bottom">
                    <CustomButton
                      title={"Create"}
                      active
                      type="submit"
                      handleClick={handleSubmit}
                      styles={{
                        padding: "13px 60px",
                        fontSize: "18px",
                        borderRadius: "13px",
                      }}
                      //   disabled={clientFetch.isPending || formDirty}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyCreate;
