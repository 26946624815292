import "../../../StyleSheet/Property/popups/propertydetails.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import dummy1 from "../../../../Assets/dummy/dummy1.svg";
import Money from "../../../../Assets/Common/Money.svg";
import download from "../../../../Assets/Common/Download.svg";
import landlordIcon from "../../../../Assets/Common/landlordIcon.svg";
import { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import archive from "../../../../Assets/Common/archive.svg";
import expenditure from "../../../../Assets/Common/expenditure.png";
import grossIncome from "../../../../Assets/Common/grossIncome.png";
import income from "../../../../Assets/Common/income.png";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../../MainComponent/fields/search";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import {
  useFetchDetails,
  useFetchMaintenancePropertySpecific,
  useFetchProperties,
  useFetchPropertiesCompliance,
  useFetchPropertyTransaction,
} from "../../../../Api/PropertiesQuery";
import dayjs from "dayjs";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import {
  useFetchLandLordExpenditure,
  useFetchLandLordTransactions,
} from "../../../../Api/ContactsQuery";
import ArchivePopup from "../../Property/popups/ArchivePopup";

// import PropertyPropertyContacts from "./PropertyPropertyContacts";
// import UploadPropertyDocument from "./UploadPropertyDocument";
export default function LandLordPopup(props) {
  const { rowvalue, fromContact = false } = props;
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [landLordId, setLandLordId] = useState("");
  const fetchMaintenanceProperties = useFetchMaintenancePropertySpecific({
    id: landLordId,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const fetchLandlordTransactions = useFetchLandLordTransactions({
    landlord: landLordId,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const fetchLandLordExpenditure = useFetchLandLordExpenditure(landLordId);
  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  const [archivePopup, setArchivePopup] = useState(false);
  const [selectedArchivedData, setSelectedArchivedData] = useState({});

  const [tableData, setTableData] = useState([]);
  const [tagactive, setTagActive] = useState("1");
  const handlebuttonclick = (e) => {
    setTagActive(e.target.id);
  };
  useEffect(() => {
    console.log(rowvalue, "alsjdfljasldf");
    if (rowvalue.id) {
      setLandLordId(rowvalue.id);
    }
  }, [rowvalue]);

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "address",
      cell: (info) => {
        console.log(info.row.original, "kajsdlfkjlaskjdlfij");
        const data = info.row.original;

        return (
          <p>
            {formatAddress([data.name, data.street, data.postcode, data.city])}
          </p>
        );
      },
      header: () => <span>Address</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "landlordName",
      cell: (info) => {
        const data = info.row.original?.landlord?.contact_detail?.[0];
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data?.title || data?.first_name || data?.last_name
              ? `${data.title} ${data.first_name} ${data.last_name}`
              : " -- "}
          </p>
        );
      },
      header: () => <span>Landlord Name</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "rooms",
      cell: (info) => {
        const data = info.row.original?.rooms?.[0];
        const value =
          parseInt(data?.bed) ||
          0 + parseInt(data?.bath) ||
          0 + parseInt(data?.living) ||
          0;
        return (
          <p
            style={{
              textTransform: "capitalize",
              position: "relative",
              left: "25%",
            }}
          >
            {value || 0}
          </p>
        );
      },
      header: () => <span>Rooms</span>,
    }),
    columnHelper.accessor("status", {
      header: "Occupancy Status",
      cell: (info) => {
        console.log(info.getValue());

        return (
          <p
            className={
              info.getValue() === "Approved" ? "Approved" : "NotApproved"
            }
            style={{
              fontSize: "16px",
              height: "auto",
              padding: "8px 14px",
              width: "205px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              background:
                info.getValue() === "Let in Progress"
                  ? "#CEFFE1"
                  : info.getValue() === "Occupied"
                  ? "#CEFFE1"
                  : info.getValue() === "Available"
                  ? "rgb(255,216,212)"
                  : info.getValue() === "Vacate in Progress"
                  ? "rgb(255,240,204)"
                  : "",
            }}
          >
            {info.getValue() === "Renewal in Progress"
              ? "Occupied"
              : info.getValue() === "Let in Progress"
              ? "Occupied"
              : info.getValue() === "Vacate in Progress"
              ? "Upcoming Vacancy"
              : info.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "rent",
      cell: (info) => {
        const data = info.row.original?.tenant?.rent;
        console.log(info.row.original, "lakjsdlafj");
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data ? `£${formatPounds(data)}` : "--"}
          </p>
        );
      },
      header: () => <span>Property Rent</span>,
    }),
  ];
  const tenancyColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Tenant",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.tenant?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Start Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.start_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "End Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.end_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Status",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.status;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
    ],
    []
  );
  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.amount;
          const type = info.row.original?.charge_item1;
          const credit = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
                color: type === "Landlord Income" ? "black" : "red",
              }}
            >
              {type === "Landlord Income"
                ? `£${formatPounds(data)}`
                : `£${formatPounds(credit)}`}
            </span>
          );
        },
      }),
    ],
    []
  );

  const fetchPropertyPropertyTransactionDetails = useFetchPropertyTransaction({
    property: rowvalue.id,
  });
  const fetchProperties = useFetchProperties({
    params: {
      landlord: landLordId,
      page: pageindex,
      page_size: pagesize,
    },
  });

  useEffect(() => {
    if (
      tagactive == "5" &&
      fetchPropertyPropertyTransactionDetails.data?.data
    ) {
      setTableData(fetchPropertyPropertyTransactionDetails.data?.data?.results);
      setCount(fetchPropertyPropertyTransactionDetails.data?.data?.count);
    }
  }, [fetchPropertyPropertyTransactionDetails.data, tagactive]);
  const clickrow = (row) => {};

  const fetchLandLordExpenditureData = fetchLandLordExpenditure.data?.data;

  const cardData = [
    {
      title: "Net Amount",
      amount: (data) =>
        formatPounds(data?.landlord_income_total - data?.expenses_total),
      imgSrc: grossIncome,
      altText: "grossIncome",
    },
    {
      title: "Total Income",
      amount: (data) => formatPounds(data?.landlord_income_total),
      imgSrc: income,
      altText: "income",
    },
    {
      title: "Total Expenditure",
      amount: (data) => formatPounds(data?.expenses_total),
      imgSrc: expenditure,
      altText: "expenditure",
    },
  ];
  const Documentstatus = (expiry_date, reminder_days) => {
    const todaysdate = new Date();
    const today = todaysdate.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate date comparison

    if (!expiry_date) {
      return "no_expiry";
    }

    const expiryDate = new Date(expiry_date).setHours(0, 0, 0, 0);
    const reminderDate = new Date(expiry_date);
    reminderDate.setDate(reminderDate.getDate() - reminder_days);
    const formattedReminderDate = reminderDate.setHours(0, 0, 0, 0);

    if (today < formattedReminderDate) {
      return "not_due";
    } else if (today >= formattedReminderDate && today <= expiryDate) {
      return "due";
    } else {
      return "overdue";
    }
  };
  useEffect(() => {
    console.log(fetchLandlordTransactions.data, "lakjsdfljasjldaf");
  }, [fetchLandlordTransactions.data]);
  return (
    <div className="popup_main_wrapper">
      <div className="content_wrapper">
        <div className="header">
          <div
            className="header_content-wrapper row p-0 m-0"
            style={{ position: "relative", alignItems: "flex-start" }}
          >
            {/* <div className="col-1 p-0 m-0"> */}

            {/* </div> */}
            <div
              className="title_wrapper col-10 m-0"
              style={{
                position: "relative",
                width: "91%",
                paddingLeft: "10px",
                paddingTop: "0",
              }}
            >
              <h1
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <img
                  src={landlordIcon}
                  style={{
                    width: "54px",
                    height: "47px",
                    borderRadius: "0",
                  }}
                ></img>
                {`${rowvalue.contact_detail?.[0]?.title}. ${rowvalue.contact_detail?.[0]?.first_name} ${rowvalue.contact_detail?.[0]?.last_name}`}
              </h1>

              <div className="button_wrapper">
                <button
                  id={"1"}
                  onClick={handlebuttonclick}
                  className={tagactive == "1" ? "active" : ""}
                >
                  Compliance
                </button>
                <button
                  id={"2"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setTableData([
                      ...fetchLandLordExpenditure.data?.data?.landlord_income,
                      ...fetchLandLordExpenditure.data?.data?.expenses,
                    ]);
                    setCount(0);
                  }}
                  className={tagactive == "2" ? "active" : ""}
                >
                  Income and Expenditure
                </button>
                <button
                  id={"3"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setTableData(fetchProperties.data?.data?.results);
                    setCount(fetchProperties.data?.data?.count);
                  }}
                  className={tagactive == "3" ? "active" : ""}
                >
                  {" "}
                  Properties
                </button>
                <button
                  id={"4"}
                  onClick={(e) => {
                    console.log();
                    handlebuttonclick(e);
                    setTableData(fetchLandlordTransactions.data?.data?.results);
                    setCount(fetchLandlordTransactions.data?.data?.count);
                  }}
                  className={tagactive == "4" ? "active" : ""}
                >
                  Transactions
                </button>
              </div>
            </div>
            <div
              className="col-1 close_button_wrapper"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                paddingBottom: "0px",
              }}
            >
              <img
                className="close"
                onClick={(e) => {
                  props.setpopup(false);
                }}
                src={closebutton}
              ></img>
            </div>
          </div>
        </div>
        {tagactive == "1" ? (
          <>
            <div
              className="body_content_wrapper row m-0"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "50px",
              }}
            >
              <div className="col-2 p-0 m-0 left">
                <h2 style={{ fontSize: "28px" }}>Documents</h2>
              </div>
              <div className="col-10 p-0 m-0 right">
                <div className="row p-0 m0">
                  {/* {complianceData?.document_types?.map((ele) => { */}
                  {rowvalue?.document_types.map((ele) => {
                    console.log(ele, "laksjdfljalsjdflaldf");
                    return (
                      <div className="col-md-4">
                        <div className="download_card">
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="text"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                width: "100%",
                                gap: "20px",
                              }}
                              onClick={() => {
                                if (ele.documents.length > 0) {
                                  window.open(ele.documents?.[0], "_blank");
                                }
                              }}
                            >
                              <div
                                style={{
                                  padding: "10px",
                                  background: "#F7F9FB",
                                  borderRadius: "50%",
                                }}
                              >
                                <img
                                  src={ele.icon}
                                  style={{
                                    width: "26px",
                                    height: "26px",
                                  }}
                                ></img>
                              </div>
                              <p>{ele.name}</p>
                            </div>
                          </div>
                          <div
                            className="dividerLine"
                            style={{
                              // background:
                              //   ele.status === "not_due"
                              //     ? "#4DDE87"
                              //     : ele.status === "due"
                              //     ? "#FFB200"
                              //     : ele.status === "overdue" && "#FF3A29",
                              background:
                                Documentstatus(
                                  ele.documents?.[0]?.expiry_date,
                                  ele.reminder_days
                                ) === "due"
                                  ? "#ffb200"
                                  : Documentstatus(
                                      ele.documents?.[0]?.expiry_date,
                                      ele.reminder_days
                                    ) === "overdue"
                                  ? "#ff3a29"
                                  : Documentstatus(
                                      ele.documents?.[0]?.expiry_date,
                                      ele.reminder_days
                                    ) === "not_due"
                                  ? "#4dde87"
                                  : "black",
                            }}
                          ></div>
                          <div
                            className="bottomSection"
                            style={{
                              justifyContent:
                                ele.documents.length === 0 && "center",
                            }}
                          >
                            {ele.documents.length > 0 ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {console.log("going in laskjflsdjflajdlsj")}
                                    <span
                                      style={{
                                        cursor:
                                          ele.documents.length > 0
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      Expiry
                                    </span>
                                    <span style={{ color: "black" }}>
                                      {dayjs(
                                        ele.documents?.[0]?.expiry_date
                                      ).format("DD/MM/YYYY")}
                                    </span>
                                  </div>
                                </div>
                                <div style={{ display: "flex", gap: "8px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                    onClick={() => {
                                      console.log(
                                        {
                                          d_id: ele.id,
                                          landlord:
                                            ele?.documents?.[0]
                                              ?.property_landlord,
                                        },
                                        "lakjsdljaljdsf"
                                      );
                                      console.log(ele, "lakjsdljaljdsf");
                                      setPayloadForArchiveApi({
                                        d_id: ele.id,
                                        landlord:
                                          ele?.documents?.[0]
                                            ?.property_landlord,
                                      });
                                      setSelectedArchivedData({
                                        custom_name: ele?.name,
                                      });
                                      setArchivePopup(true);
                                    }}
                                  >
                                    {console.log("going in laskjflsdjflajdlsj")}
                                    <span
                                      style={{
                                        cursor:
                                          ele.documents.length > 0
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      Archived
                                    </span>
                                    <img
                                      src={archive}
                                      alt="document image"
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        cursor:
                                          ele.documents.length > 0
                                            ? "pointer"
                                            : "default",
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {console.log("going in laskjflsdjflajdlsj")}
                                    <span
                                      style={{
                                        cursor:
                                          ele.documents.length > 0
                                            ? "pointer"
                                            : "default",
                                      }}
                                    >
                                      Download
                                    </span>
                                    <img
                                      // src={ele.documents?.[0].documents?.[0]}
                                      src={download}
                                      width={"12px"}
                                      height={"12px"}
                                      alt="document image"
                                      style={{
                                        cursor:
                                          ele.documents.length > 0
                                            ? "pointer"
                                            : "default",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  justifyContent: "center",
                                }}
                              >
                                Document not upload yet.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="popuptable">
            {tagactive == "2" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "20px",
                  width: "94.5%",
                  margin: "0 auto",
                }}
              >
                {cardData.map((card, index) => (
                  <div className="landlordPopupCard active" key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{card.title}</p>
                      <img
                        src={card.imgSrc}
                        alt={card.altText}
                        style={{ width: "35px", height: "35px" }}
                      />
                    </div>
                    <h1>£{card.amount(fetchLandLordExpenditureData)}</h1>
                  </div>
                ))}
              </div>
            )}
            {tagactive == "5" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div className="accountBalanceCard">
                  <div className="wrapper">
                    <img
                      src={Money}
                      alt="wallet"
                      width={"26px"}
                      height={"26px"}
                    />
                    <span style={{ fontSize: "19px", fontWeight: "500" }}>
                      Account Balance
                    </span>
                  </div>
                  <span>
                    <span
                      style={{
                        fontSize: "26px",
                        color:
                          tableData?.[0]?.balance > 0
                            ? "green"
                            : tableData?.[0]?.balance < 0
                            ? "red"
                            : "black",
                        fontWeight: "300",
                      }}
                    >
                      £
                      {tableData && tableData?.[0]?.balance !== undefined
                        ? formatPounds(tableData[0].balance)
                        : 0}
                    </span>
                  </span>
                </div>
              </div>
            )}
            <TableComponent
              data={tableData ? tableData : []}
              columns={
                tagactive == ""
                  ? tenancyColumn
                  : tagactive == "4" || tagactive == "2"
                  ? transactionColumn
                  : columns
              }
              onclickrow={clickrow}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          </div>
        )}
      </div>
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
          // editData={fetchArchiveDoc}
          // editData={editData}
          // setEditData={setEditData}
        />
      )}
    </div>
  );
}
