import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

function PrivateRoutes({ children }) {
  const loginpage = useNavigate();
  const token = useSelector((state) => state.counter.token);
  useEffect(() => {
    if (token?.length < 1) {
      loginpage("/login");
    }
  }, [loginpage]);
  return children;
}
export default PrivateRoutes;
