import React, { useState, useEffect } from "react";
import "./style.scss";
import { PasswordInput } from "../../GlobalComponents/othercomponents/PasswordInput";
import logo from "../../../Assets/Login/Logo_main.svg";
import solace from "../../../Assets/Login/Solace.svg";
import solaceCorner from "../../../Assets/Login/Solace_corner.svg";
// import { useLogin } from '../../Context/queries';
import axios from "axios";
import { loginuserdata } from "../../../redux/reducer/counterslice";
import { useDispatch } from "react-redux";
import Arrow from "../../../Assets/Common/Arrow.svg";
import { toast } from "sonner";

export default function ForgetPassword() {
  const [UserDetails, setUserDetails] = useState({ email: "", password: "" });
  const [Confirmpassword, setConfirmPassword] = useState("");
  const [viewpass, setViewpass] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [passworderror, setPassworderror] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (UserDetails.email.length === 0) {
      toast.error("Enter mail for further process.");
    } else {
      console.log("Loading");
    }
  };
  return (
    <>
      <div className="Forget_password">
        <div className="loginLeft">
          <div className="backgroundImage">
            <div className="innerContent">
              <div className="innerTop">
                <img src={logo} alt="logo" />
                <img src={solace} alt="solace" />
              </div>
            </div>
          </div>
        </div>
        <div className="loginRight">
          <div className="loginRightMainImg1">
            <img src={solaceCorner} alt="solaceCorner" />
          </div>
          <form
            className="innerContent"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2>Forgot Password</h2>
            <p>
              Please Enter Your Email Address to Receive a Verification Code
            </p>
            <input
              className="inputStyle"
              type="text"
              placeholder="Email"
              value={UserDetails.email}
              onChange={(e) => {
                setUserDetails((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
            />
            <button
              type="submit"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                handleSubmit();
              }}
            >
              Send
              <img src={Arrow} alt="arrow" style={{ marginLeft: "10px" }}></img>
            </button>
          </form>
        </div>
        <div className="loginMainImg1">
          <img src={solaceCorner} alt="solaceCorner" />
        </div>
      </div>
    </>
  );
}
