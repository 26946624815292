import "../../StyleSheet/Maintenance/maintenanceoverview.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import ReactEcharts from "echarts-for-react";
import one from "../../../Assets/Client/maintenence/maintenance.svg";
import two from "../../../Assets/Client/maintenence/amount.svg";
import three from "../../../Assets/Client/maintenence/funds.svg";
import { useEffect, useMemo, useState } from "react";
import info from "../../../Assets/Common/info.svg";
import MaintenanceOverviewPopup from "./popups/maintenanceoverviewpopup";
import { createColumnHelper } from "@tanstack/react-table";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import {
  useFetchMaintenanceFilterStatus,
  useFetchMaintenanceSpendAmount,
  useFetchUpcomingMaintenance,
} from "../../../Api/MaintenanceQuery";
import { useDispatch, useSelector } from "react-redux";
import { formatAddress, formatPounds } from "../../../Utils/Utils";
import { setMaintenanceFilter } from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";
export default function MaintenanceOverview() {
  const columnHelper = createColumnHelper();
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const [selectedFilter, setSelectedFilter] = useState("0-7days");
  const MaintenanceSpendAmount = useFetchMaintenanceSpendAmount({
    id: loginuserdata.id,
    date_filter: selectedFilter,
  });

  const [popup, setPopup] = useState(false);
  const [Range, setRange] = useState([]);
  const [keydata, setkeydata] = useState();
  const navigate = useNavigate();
  const [Data, setData] = useState([
    { name: "New Requests", count: "12" },
    { name: "Quote Requested", count: "4" },
    { name: "Awaiting Approval", count: "5" },
    { name: "Approved", count: "8" },
    { name: "Booked", count: "2" },
    { name: "Awaiting Payment", count: "2" },
  ]);
  const FilterOptions = [
    { label: "7 Days", value: "0-7days" },
    { label: "Month", value: "month" },
    { label: "Year", value: "weeks" },
  ];
  const fetchStatus = useFetchMaintenanceFilterStatus({
    id: loginuserdata.id,
  });

  useEffect(() => {
    console.log(fetchStatus?.data?.data, "kasjhdfkashdjfkas");
  }, [fetchStatus?.data?.data]);
  const option = {
    // legend: {
    //     top: '0%',
    //     left: 'center'
    // },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["48%", "90%"],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 5,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value:
              fetchStatus?.data?.data?.[0]?.count +
              fetchStatus?.data?.data?.[1]?.count,
            // name: "New Requests",
            itemStyle: { color: "#75E291" },
          },
          {
            value: fetchStatus?.data?.data?.[2]?.count,
            // name: "Quote Requested",
            itemStyle: { color: "#FFC650" },
          },
          {
            value: fetchStatus?.data?.data?.[3]?.count,
            // name: "Awaiting Approval",
            itemStyle: { color: "#FF5B5C" },
          },
          {
            value: fetchStatus?.data?.data?.[4]?.count,
            // name: "Approved",
            itemStyle: { color: "#52C2EF" },
          },
          {
            value: fetchStatus?.data?.data?.[5]?.count,
            // name: "Booked",
            itemStyle: { color: "#6062F0" },
          },
          {
            value: fetchStatus?.data?.data?.[6]?.count,
            // name: "Awaiting Payment",
            itemStyle: { color: "#FF9E6F" },
          },
        ],
      },
    ],
  };
  useEffect(() => {
    console.log(MaintenanceSpendAmount.data, "lakjsldfjljsldjflals");
  }, [MaintenanceSpendAmount.data]);

  const carddata = [
    {
      img: one,
      amount: formatPounds(
        MaintenanceSpendAmount.data?.data?.total_property_charges
      ),
      text: "Total maintenance spend",
    },
    {
      img: two,
      amount: formatPounds(
        MaintenanceSpendAmount.data?.data?.total_reserve_debits
      ),
      text: "Total amount paid by Solace",
    },
    {
      img: three,
      amount: formatPounds(MaintenanceSpendAmount.data?.data?.latest_balance),
      text: "Reserve Funds",
    },
  ];

  const upcomingMaintenance = useFetchUpcomingMaintenance();
  const [upcomingMaintenanceData, setUpcomingMaintenanceData] = useState([]);
  useEffect(() => {
    if (upcomingMaintenance.data?.data) {
      console.log(upcomingMaintenance.data.data, "lkasjdlfjlasjdljafl");
      setUpcomingMaintenanceData(upcomingMaintenance.data.data);
    }
  }, [upcomingMaintenance.data]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          console.log(info.getValue());

          return (
            <p
              className={
                info.getValue() == "Approved" ? "Approved" : "NotApproved"
              }
            >
              {info.getValue()}
            </p>
          );
        },
        header: () => <span>Status</span>,
      }),

      columnHelper.accessor("summary", {
        header: "Summary",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("description", {
        header: "Decription",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("quote", {
        header: "Quote",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>£ {info.getValue()}</span>
        ),
      }),
    ],
    []
  );
  useEffect(() => {
    //  {
    //    fetchStatus?.data?.data?.[0]?.count &&
    //      (index === 0
    //        ? addLeadingZero(
    //            fetchStatus?.data?.data?.[0]?.count +
    //              fetchStatus?.data?.data?.[1]?.count
    //          )
    //        : addLeadingZero(fetchStatus?.data?.data?.[index + 1]?.count));
    //  }
    // console.log(
    //   // fetchStatus?.data?.data?.
    //   fetchStatus?.data?.data?.[0]?.count &&
    //     (index === 0
    //       ? addLeadingZero(
    //           fetchStatus?.data?.data?.[0]?.count +
    //             fetchStatus?.data?.data?.[1]?.count
    //         )
    //       : addLeadingZero(fetchStatus?.data?.data?.[index + 1]?.count)),
    //   "lakjsdfjlasdfjla"
    // );
  }, [fetchStatus?.data]);
  const clickrow = (row) => {};
  const addLeadingZero = (num) => {
    return num < 10 ? `0${num}` : `${num}`;
  };
  const filterStatus = useSelector((state) => state.counter.maintenanceFilter);
  const dispatch = useDispatch();
  const filterOptions = [
    "New Requests",
    "Quote Requested",
    "Awaiting Approval",
    "Approved",
    "Booked",
    "Awaiting payment",
  ];

  return (
    <>
      <div className="maintenace_overview">
        <div className="maintenance_content_wrapper">
          <div className="header">
            <img src={logo}></img>
            <h1>Maintenance</h1>
          </div>
          <div className="main_content_wrapper">
            <div className="row mb-5">
              <div className="Overview col-3">
                <h2>Overview</h2>
                <ReactEcharts
                  style={{ width: "300px", height: "300px", top: "0px" }}
                  option={option}
                />
              </div>
              <div className="Key_analytics col-9">
                <div className="innerKey_wrapper">
                  <span className="heading ">Key analytics</span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: "30px",
                    }}
                  >
                    <DateRange
                      onChange={(e) => {
                        console.log(e, "asjaaksjd");
                        setRange(e);
                      }}
                    />
                    <div className="date">
                      {FilterOptions.map((ele) => {
                        return (
                          <span
                            className={
                              selectedFilter === ele.value ? "active" : ""
                            }
                            onClick={() => setSelectedFilter(ele.value)}
                          >
                            {ele.label}
                          </span>
                        );
                      })}
                      {/* <span>Month</span>
                      <span>Year</span> */}
                    </div>
                  </div>
                </div>

                <div className="row card_wrapper">
                  {carddata.map((ele) => {
                    return (
                      <div
                        className="col-4"
                        onClick={(e) => {
                          setkeydata(ele);
                          setPopup(true);
                        }}
                      >
                        <div className="card">
                          <div className="img_wrapper">
                            <img src={ele.img}></img>
                          </div>
                          <div className="text_erapper">
                            <h1>£{formatPounds(ele.amount)}</h1>
                            <p>{ele.text}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3   maintanence_list">
                <ul>
                  {Data.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          dispatch(setMaintenanceFilter(filterOptions[index]));
                          navigate("/maintenance/log");
                        }}
                      >
                        <span>
                          <div className={`badge${index + 1}`}></div>
                          {item.name}
                        </span>
                        <p style={{}}>
                          {index === 0
                            ? addLeadingZero(
                                fetchStatus?.data?.data?.[0]?.count +
                                  fetchStatus?.data?.data?.[1]?.count
                              )
                            : addLeadingZero(
                                fetchStatus?.data?.data?.[index + 1]?.count
                              )}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="upcoming_maintenance_jobs col-9">
                <h2 style={{ padding: "0px 0px 21px 0px " }}>
                  Upcoming Maintenance Jobs
                </h2>
                <div className="row card_wrapper">
                  {upcomingMaintenanceData.map((ele, index) => {
                    return (
                      <div className="col-3">
                        <div className="card">
                          <img src={info}></img>
                          <div className="text_erapper">
                            <p>{ele?.title}</p>
                            <h6>
                              <p>
                                <p style={{ paddingBottom: "5px" }}>
                                  {ele?.property?.name +
                                    " " +
                                    ele?.property?.street}
                                  ,
                                </p>
                                <p style={{ paddingBottom: "5px" }}>
                                  {ele?.property?.city},
                                </p>{" "}
                                <p>{ele?.property?.postcode}</p>
                              </p>
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <MaintenanceOverviewPopup
          keydata={keydata}
          setPopup={setPopup}
          columns={columns}
          onclickrow={clickrow}
          Range={Range}
        />
      )}
    </>
  );
}
