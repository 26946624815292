import { Outlet, createBrowserRouter } from "react-router-dom";
import ActivateForm from "../Client/GlobalComponents/Signup/Signuppage";
import PrivateRoutes from "../Client/GlobalComponents/Signup/PrivateRoutes";
import Home from "../Client/Components/Dashboard/home";
import App from "../App";
import { element } from "prop-types";
import ForgetPassword from "../Client/GlobalComponents/forgetpassword/ForgetPassword";
import PropertyOverView from "../Client/Components/Property/Overview";
import AllProperties from "../Client/Components/Property/AllProperties";
import MaintenanceOverview from "../Client/Components/Maintenance/maintenanceoverview";
import MaintenanceLog from "../Client/Components/Maintenance/maintenanceLog";
import MaintenanceArchive from "../Client/Components/Maintenance/maintenancearchive";
import MaintenanceApproval from "../Client/Components/Maintenance/maintenanceapproval";
import Compliance from "../Client/Components/compliance/compliance";
import Insepection from "../Client/Components/insepection/periodicinsepection";
import ClientMoney from "../Client/Components/clientmoney/clientmoney";
import { ClientCreate } from "../Client/Components/ClientCreate";
import Contacts from "../Client/Components/Contacts/Contacts";
const Routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoutes>
        <App />
      </PrivateRoutes>
    ),
    children: [
      {
        path: "/",
        element: (
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        ),
        index: true,
      },
      {
        path: "/property",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/property",
            element: (
              <PrivateRoutes>
                <PropertyOverView />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "overview",
            element: (
              <PrivateRoutes>
                <PropertyOverView />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "allproperties",
            element: (
              <PrivateRoutes>
                <AllProperties />
              </PrivateRoutes>
            ),
            index: true,
          },
        ],
      },
      {
        path: "/maintenance",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/maintenance",
            element: (
              <PrivateRoutes>
                <MaintenanceOverview />
              </PrivateRoutes>
            ),
            index: true,
          },
          {
            path: "overview",
            element: (
              <PrivateRoutes>
                <MaintenanceOverview />
              </PrivateRoutes>
            ),
          },
          {
            path: "log",
            element: (
              <PrivateRoutes>
                <MaintenanceLog />
              </PrivateRoutes>
            ),
          },
          {
            path: "approval",
            element: (
              <PrivateRoutes>
                <MaintenanceApproval />
              </PrivateRoutes>
            ),
          },
          {
            path: "archive",
            element: (
              <PrivateRoutes>
                <MaintenanceArchive />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/compliance",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/compliance",
            element: (
              <PrivateRoutes>
                <Compliance />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/insepection",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/insepection",
            element: (
              <PrivateRoutes>
                <Insepection />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/clientmoney",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/clientmoney",
            element: (
              <PrivateRoutes>
                <ClientMoney />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "/contacts",
        element: (
          <PrivateRoutes>
            <Outlet />
          </PrivateRoutes>
        ),
        children: [
          {
            path: "/contacts",
            element: (
              <PrivateRoutes>
                <Contacts />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    element: (
      <PrivateRoutes>
        <ActivateForm />
      </PrivateRoutes>
    ),
  },
  {
    path: "/createAccount/:id",
    element: <ClientCreate />,
  },
  {
    path: "/ForgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/test",
    element: <App />,
  },
]);

export default Routes;
