import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useLogin(payload) {
  const { handleResponse } = useResponseHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload,
      };
      return handleResponse("manager/client_login/", "post", uploadPayload);
    },
    onSuccess: (data) => {
      console.log(data, "kahjsdfkhsd");
      localStorage.setItem("login", "false");
      localStorage.setItem("token", data.session.token);
      dispatch(token(data.session.token));
      dispatch(loginuserdata(data.data));
      toast.success("Login Success");
      navigate("/");
      //   location.reload();
      // window.location.assign(window.location.href);
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error(
        "Your login attempt was unsuccessful. Please double-check your email and password."
      );
    },
  });
  // });
}
function useGenericMutation(
  url,
  payload,
  onSuccessCallback,
  queryKey,
  tostMessage = "Operation successful."
) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  //   const { setLoading } = useStore((state) => ({
  //     setLoading: state.setLoading,
  //   }));

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = { data: payload.data || payload };
      return handleResponse(url, "post", uploadPayload, false);
    },
    onSuccess: () => {
      if (tostMessage !== null) {
        toast.success(tostMessage);
      }
      if (onSuccessCallback) onSuccessCallback();
      if (queryKey) {
        queryClient.invalidateQueries({ queryKey });
      }
      //   setLoading(false);
    },
    onError: (error) => {
      const errorMessage = `Error during mutation at ${url}: ${error.message}`;
      console.error(errorMessage);
      toast.error(errorMessage);
      //   setLoading(false);
    },
  });
}

export function usePresignedUrlPost(payload) {
  return useGenericMutation(
    "property/presignedurl/",
    payload,
    null,
    null,
    null
  );
}

// export function useFetchLandlordNoId(payload) {
//   return useFetchData(["landlordList"], "/manager/landlord/v1/", payload);
// }

export function useFetchLandlordNoId(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landlordList", payload],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: { client: payload },
      };
      const response = handleResponse(
        "manager/landlord/v1/",
        "get",
        uploadPayload
        // false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchLandlordDetails(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landlordListWithId", payload],
    queryFn: async () => {
      if (payload !== "" && payload !== undefined) {
        const response = handleResponse(
          `manager/landlord/v1/${payload}/`,
          "get",
          "",
          false
        );
        return response;
      }
    },
    // placeholderData: keepPreviousData,
  });
}
export function useFetchPropertyUpcoming(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["landlordList"],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: { client: payload },
      };
      const response = handleResponse(
        "property/property_upcoming_document/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchPropertyBasedOnStatus(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "propertyGetBasedOnStatus",
      payload.status,
      payload.page,
      payload.page_size,
    ],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: {
          client: payload.client,
          ostatus: payload.status,
          search: "",
          page: payload.page,
          page_size: payload.page_size,
        },
      };
      const response = handleResponse(
        "property/property/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenancePropertySpecific(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "fetchMaintenancePropertySpecific",
      payload.status,
      payload.search,
      payload.page,
      payload.page_size,
      payload.id,
    ],

    queryFn: async () => {
      if (payload.id !== "" && payload.id.length) {
        const uploadPayload = {
          params: {
            property: payload.id,
            search: payload.search,
            page: payload.page,
            page_size: payload.page_size,
          },
        };
        const response = handleResponse(
          "workorder/getproperty_maintance/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
  });
}
export function usePresignedUrlPut(payload) {
  //   const { setLoading } = useStore((state) => ({
  //     setLoading: state.setLoading,
  //   }));

  return useMutation({
    mutationFn: async () => {
      return axios.put(payload.url, payload.imagePayload, {
        headers: {
          "Content-Type": payload.type,
          "x-Amz-ACL": "public-read",
        },
      });
    },
    onSuccess: () => {
      //   setLoading(false);
    },
    onError: (error) => {
      const errorMessage = `Error during PUT request at ${payload.url}: ${error.message}`;
      console.error(errorMessage);
      toast.error(errorMessage);
      //   setLoading(false);
    },
  });
}

export function useFetchProperties(payload) {
  const { handleResponse } = useResponseHandler();
  const filter = payload.params.type;
  return useQuery({
    queryKey: ["properties", payload],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "property/property/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchPropertiesCompliance(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["propertiesCompliance", payload],
    queryFn: async () => {
      if (payload) {
        const response = handleResponse(
          `property/property/v1/${payload}/`,
          "get",
          ""
          // false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchPropertyTransaction(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["propertyTransaction"],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        `transaction/ptransaction_debit/v1/`,
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchArchive_document(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["archive_document", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        `transaction/archive_document/v1/`,
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
// export function useFetchCompliance(payload) {
//   const { handleResponse } = useResponseHandler();
//   //   const filter = payload.params.type;
//   return useQuery({
//     queryKey: ["compliance", payload.],
//     queryFn: async () => {
//       const response = handleResponse("property/document/v1/", "get");
//       return response;
//     },
//     placeholderData: keepPreviousData,
//   });
// }

export function usePropertyCreatePost(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.data,
      };
      console.log(payload, "lkajsldfjksdaiflj");
      return handleResponse("property/property/v1/", "post", uploadPayload);
    },
    onSuccess: () => {
      toast.success(`Property Created successful.`);
      payload.handleClose();
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error("Error occurred while processing.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["properties"],
        });
      }
    },
  });
}

export function useFetchCompliance(payload) {
  const { handleResponse } = useResponseHandler();
  const filter = payload.params.type;
  return useQuery({
    queryKey: [
      "complianceListNoId",
      payload.params.page,
      payload.params.type,
      payload.params.page_size,
      payload.params.client,
    ],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: {
          type: payload.params.type,
          page: payload.params.page,
          page_size: payload.params.page_size,
          client: payload.params.client,
        },
      };
      console.log(uploadPayload, "laksdlflasdklfasd");
      const response = handleResponse(
        "property/document/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchDetails(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["documentsDetails", payload.clientId, payload.propertyId],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "workorder/on_going_status/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function usePropertyFilePost(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.data,
      };
      console.log(payload, "lkajsldfjksdaiflj");
      return handleResponse(
        "property/propertyfile/v1/",
        "post",
        uploadPayload,
        false
      );
    },
    onSuccess: () => {
      toast.success(`Documents updated successful.`);
      payload.handleClose();
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error("Error occurred while processing.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["propertiesCompliance"],
        });
      }
    },
  });
}
