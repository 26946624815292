import React, { useEffect, useMemo, useState } from "react";
import "../../StyleSheet/Maintenance/maintenanceapproval.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import arrow from "../../../Assets/Common/Frame 427319102.svg";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../MainComponent/fields/search";
import balanceimg from "../../../Assets/Client/maintenence/balance.svg";
import MaintenanceSupplierApprove from "./popups/maintenancesupplierapprove";
import {
  useFetchMaintenanceSpendAmount,
  useFetchMaintenanceTable,
} from "../../../Api/MaintenanceQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  addLeadingZero,
  formatAddress,
  formatPounds,
} from "../../../Utils/Utils";
const MaintenanceApproval = () => {
  const columnHelper = createColumnHelper();
  const [popup, setpopup] = useState(false);
  const [rowvalue, setrowvalue] = useState([]);
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);

  const fetchMaintenanceData = useFetchMaintenanceTable({
    status: "Awaiting Approval",
    id: loginuserdata.id,
    search: search,
    page: pageIndex,
    page_size: pagesize,
  });
  const MaintenanceSpendAmount = useFetchMaintenanceSpendAmount({
    id: loginuserdata.id,
    date_filter: "0-7days",
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "created_date",
        header: "Reported Date",
        cell: (info) => (
          <p style={{ fontSize: "18px" }}>
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        ),
      }),
      columnHelper.accessor("status", {
        id: "status",
        enableSorting: false,
        header: "Status",
        cell: (info) => {
          return (
            <div className="statusWrapper">
              <div className="status_main"></div>
              <p className="status" style={{ wordBreak: "break-all" }}>
                {info.getValue()}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Address",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.row.original, "aksndljfljsaldjfljasljdf");
          const dataFromParent = info.row.original.property;
          return (
            <p>
              {formatAddress([
                dataFromParent.name + " " + dataFromParent.street,
                dataFromParent.city,
                dataFromParent.postcode,
              ])}
            </p>
          );
        },
        header: () => <span>Address</span>,
      }),

      columnHelper.accessor("description", {
        enableSorting: false,
        header: () => <span>Summary</span>,
        cell: (info) => (
          <p style={{ wordBreak: "break-all" }}>{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "noOfQuotes",
        sortingFn: "basic",
        header: "No of Quotes",
        cell: (info) => {
          return (
            <div className="status_main" style={{ background: "inherit" }}>
              <span style={{ color: "black" }}>
                {addLeadingZero(info.row.original.work_order_suppliers.length)}
              </span>
            </div>
          );
        },
      }),
    ],
    []
  );
  const clickrow = (row) => {
    console.log(row);
    setpopup(true);
    setrowvalue(row);
  };
  useEffect(() => {
    if (fetchMaintenanceData?.data?.data?.results) {
      setCount(fetchMaintenanceData?.data?.data?.count);
    }
  }, [fetchMaintenanceData.data]);

  return (
    <>
      <div className="maintenace_approval">
        <div className="maintenance_content_wrapper">
          <div className="header">
            <img src={logo}></img>
            <h1>Maintenance Approval</h1>
          </div>
          <div className="maintenace_log_wrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "40px",
                padding: "0 24px",
              }}
            >
              <div className="balance">
                <div className="img_con">
                  <img src={balanceimg}></img>
                  <p>Reserve Balance</p>
                </div>
                <h1>
                  £
                  {formatPounds(
                    MaintenanceSpendAmount.data?.data?.latest_balance
                  )}
                </h1>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <Search setsearch={setSearch} style={{ width: "415px" }} />
                <button
                  style={{
                    border: "none",
                    padding: "8px 50px",
                    borderRadius: "5px",
                    background: "rgba(129, 126, 255, 1)",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    gap: "5px",
                  }}
                >
                  Generate Report
                  <img src={arrow} alt="arrow" width={"12px"} />
                </button>
              </div>
            </div>
            <div className="main_content">
              <TableComponent
                data={
                  fetchMaintenanceData.data?.data?.results
                    ? fetchMaintenanceData.data?.data?.results
                    : []
                }
                columns={columns}
                onclickrow={clickrow}
                count={count}
                pageindex={pageIndex}
                pagesize={pagesize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <MaintenanceSupplierApprove rowvalue={rowvalue} setpopup={setpopup} />
      )}
    </>
  );
};

export default MaintenanceApproval;
