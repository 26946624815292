import "../../../StyleSheet/clientmoney/accounts/clientaccount.scss";
import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
import Search from "../../../../MainComponent/fields/search";
import DateRange from "../../../../MainComponent/fields/daterangepicker";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useClientAccountDetails } from "../../../../Api/clientmonetQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";

export default function ClientAccount() {
  const columnHelper = createColumnHelper();
  const [tabledata, settabledata] = useState([]);
  const [Generate, setGenerate] = useState("");
  const [search, setsearch] = useState("");

  const [Balance, setbalance] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);

  const getclientaccountdetails = useClientAccountDetails({
    params: { client: clientid, page: pageindex, page_size: pagesize },
  });

  useEffect(() => {
    if (getclientaccountdetails.data != undefined) {
      console.log(getclientaccountdetails.data, "akshksfjhsfkh");
      settabledata(getclientaccountdetails.data.data.results);
      setCount(getclientaccountdetails.data?.data?.count);
      setbalance(getclientaccountdetails?.data?.data?.results[0]?.balance || 0);
    }
  }, [getclientaccountdetails.data, pageindex, pagesize]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "receipt_date",
        header: "Date",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "payer_name",
        header: "Name",
        sortingFn: "basic",
        cell: (info) => <span>{info.row.original.payer_name}</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "recipt_type",
        header: "Type",
        sortingFn: "basic",
        cell: (info) => (
          <span>{info.row.original.debit != 0 ? "Charge" : "Receipt"}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "debit",
        header: "Paid In",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.credit)}`}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "credit",
        header: "Pay Out",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.debit)}`}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "balance",
        header: "Balance",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.balance)}`}</span>
        ),
      }),
    ],
    []
  );
  return (
    <div className="client">
      <div className="card_parent">
        <div className="card">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Client Balance</p>
            <img src={wallet}></img>
          </div>
          <h1>£{formatPounds(Balance)}</h1>
        </div>
      </div>
      <h1>Client Account</h1>
      <div className="table_headers">
        <div>
          <Search setsearch={setsearch} style={{ width: "316px" }} />
          <DateRange style={{ width: "300px" }} />
        </div>
        <GenerateReport
          style={{ padding: "5px 30px", fontSize: "14px" }}
          setGenerate={setGenerate}
        />
      </div>
      <div className="main_content">
        <TableComponent
          columns={columns}
          data={tabledata}
          count={count}
          pageindex={pageindex}
          pagesize={pagesize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          // onclickrow={row => console.log(row)}
        />
      </div>
    </div>
  );
}
