import "./style.scss";
export default function Search(props) {
  const isCharacterAllowed = (char) => {
    // Allow only alphanumeric characters and spaces
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(char);
  };
  return (
    <div className="search_main">
      <i className="bi bi-search" style={{ color: "#A8C5DA" }}></i>
      {/* <input
        className="search_field"
        placeholder="Search"
        value={props?.search}
        style={{
          border: "none",
          // width: "420px",
          backgroundColor: "transparent",
          ...props?.style,
        }}
        onKeyDown={(e) => {
          if (e.target.value.length === 0 && e.which === 32) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          props.setsearch(e.target.value);
        }}
        maxLength={"30"}
      ></input> */}
      <input
        className="search_field"
        placeholder="Search"
        value={props?.search}
        style={{
          border: "none",
          backgroundColor: "transparent",
          ...props?.style,
        }}
        onKeyDown={(e) => {
          // Prevent unwanted characters from being entered
          if (!isCharacterAllowed(e.key)) {
            e.preventDefault();
          }
          // Prevent entering space when the field is empty
          if (e.target.value.length === 0 && e.key === " ") {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          const filteredValue = e.target.value
            .split("")
            .filter((char) => isCharacterAllowed(char))
            .join("");
          props.setsearch(filteredValue);
        }}
        maxLength="50"
      />
    </div>
  );
}
