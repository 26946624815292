// import { useEffect, useMemo, useState } from "react";
// import "./Styles/Contacts.scss";
// import ClientMoneyOverview from "../../Components/clientmoney/clientmoneyoverview";
// // import Accounts from "./accounts";
// // import PaymentDue from "./paymentdue";
// // import Reports from "./reports";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setClientMoneyStatus,
//   setContactDetailsFilter,
//   setContactsFilter,
//   setPropertyDetailsFilter,
// } from "../../../redux/reducer/counterslice";
// import FilterPanel from "../../../MainComponent/FilterPanel";
// import { useFetchTenantNoId } from "../../../Api/ContactsQuery";
// import {
//   addLeadingZero,
//   formatAddress,
//   formatName,
//   formatPounds,
//   formatUKTelephone,
// } from "../../../Utils/Utils";
// import {
//   useFetchLandlordDetails,
//   useFetchLandlordNoId,
//   useFetchProperties,
//   useFetchPropertiesCompliance,
// } from "../../../Api/PropertiesQuery";
// import Propertydetails from "../Property/popups/propertydetails";
// import emailIcon from "../../../Assets/Common/emailIcon.svg";
// import phoneIcon from "../../../Assets/Common/phoneIcon.svg";
// import LandLordPopup from "./Popup/LandlordPopup";
// import Search from "../../../MainComponent/fields/search";
// import TableComponent from "../../GlobalComponents/table-v8/reacttable";
// import { createColumnHelper } from "@tanstack/react-table";

// const Contacts = () => {
//   const dispatch = useDispatch();
//   const columnHelper = createColumnHelper();
//   const loading = useSelector((state) => state.counter.loader);
//   const toggle = useSelector((state) => state.counter.contactsFilter);
//   const clientid = useSelector((state) => state.counter.clientid);
//   const [popup, setpopup] = useState(false);
//   const [landLordPopup, setLandLordPopup] = useState(false);
//   const [cardData, setCardData] = useState([]);
//   const [selectedPropertyId, setSelectedPropertyId] = useState("");
//   const [selectedLandLordId, setSelectedLandLordId] = useState("");
//   const fetchTenant = useFetchTenantNoId(clientid);
//   const fetchLandlord = useFetchLandlordNoId(clientid);
//   const fetchLandlordDetails = useFetchLandlordDetails(selectedLandLordId);
//   const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
//   const [search, setsearch] = useState("");
//   const [tableData, setTableData] = useState([]);
//   const [count, setCount] = useState(0);
//   const [pageindex, setPageIndex] = useState(1);
//   const [pagesize, setPageSize] = useState(10);
//   const [rowValue, setRowValue] = useState([]);

//   const [switcher, setswitcher] = useState(false);
//   const columns = useMemo(
//     () => [
//       columnHelper.accessor((row) => row, {
//         id: "contact_name",
//         sortingFn: "basic",
//         cell: (info) => {
//           const data = info.row.original?.contact_detail?.[0];
//           return (
//             <p style={{ textTransform: "capitalize" }}>
//               {data.title}. {data.first_name} {data.last_name}
//             </p>
//           );
//         },
//         header: () => <span>Contact Name</span>,
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "email",
//         sortingFn: "basic",
//         cell: (info) => {
//           const data = info.row.original.email;
//           return <p style={{ textTransform: "capitalize" }}>{data}</p>;
//         },
//         header: () => <span>Email</span>,
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "rooms",
//         sortingFn: "basic",
//         cell: (info) => {
//           const data = info.row.original?.phone;
//           return (
//             <p style={{ textTransform: "capitalize" }}>
//               {data ? formatUKTelephone(data) : "--"}
//             </p>
//           );
//         },
//         header: () => <span>Telephone</span>,
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "rooms",
//         sortingFn: "basic",
//         cell: (info) => {
//           const data = info.row.original?.phone;
//           return <p style={{ textTransform: "capitalize" }}>{"--"}</p>;
//         },
//         header: () => (
//           <span style={{ textTransform: "none" }}>No of Properties</span>
//         ),
//       }),
//     ],
//     []
//   );
//   useEffect(() => {
//     if (fetchTenant.data?.data?.results && toggle === "Tenants") {
//       setCardData(fetchTenant.data?.data?.results);
//       setTableData(fetchTenant.data?.data?.results);
//       setCount(fetchTenant.data?.data?.count);
//       setpopup(true);
//     }
//   }, [fetchTenant.data, toggle, selectedPropertyId]);
//   useEffect(() => {
//     console.log(fetchLandlord.data, "laksldflasldfjsd");
//     if (fetchLandlord.data?.data?.results && toggle === "Landlords") {
//       setCardData(fetchLandlord.data?.data?.results);
//       setTableData(fetchLandlord.data?.data?.results);
//       setCount(fetchLandlord.data?.data?.count);
//     }
//   }, [fetchLandlord.data, toggle]);

//   const updateFilter = (item) => {
//     setSelectedPropertyId(undefined);
//     setSelectedLandLordId(undefined);
//     dispatch(setContactsFilter(item));
//   };
//   const clickrow = (card) => {
//     console.log(card.id, "laksjdfljaskdfjasjofj");
//     if (toggle === "Landlords") {
//       setSelectedLandLordId(card?.id);
//       setLandLordPopup(true);
//     } else {
//       setSelectedPropertyId(card?.tenancies?.[0]?.tenancies?.[0]?.property?.id);
//       dispatch(setPropertyDetailsFilter("1"));
//       dispatch(setContactDetailsFilter(2));
//     }
//   };

//   useEffect(() => {
//     console.log(selectedLandLordId, "laskjdflkjajsdfojas");
//   }, [selectedLandLordId]);
//   useEffect(() => {
//     console.log(fetchLandlordDetails.data?.data, "laskjdflkjajsdfojas");
//     if (fetchLandlordDetails.data?.data !== undefined) {
//       setRowValue(fetchLandlordDetails.data?.data);
//     }
//   }, [selectedLandLordId, fetchLandlordDetails.data]);
//   if (loading) {
//     return null; // Or some loading component
//   }

//   return (
//     <>
//       <div className="contactsWrapper">
//         <div className="contacts_main">
//           <div className="toggle_header" style={{ height: "auto" }}>
//             <div style={{ margin: "0 0 0 0 ", width: "100%" }}>
//               <FilterPanel
//                 tabs={["Tenants", "Landlords"]}
//                 setActiveTab={updateFilter}
//                 activeTab={toggle}
//               />
//             </div>
//           </div>
//           <div
//             className="search_wrapper"
//             style={{ justifyContent: "space-between" }}
//           >
//             <div className="totalArrears" style={{ marginBottom: "0" }}>
//               <span>Total {toggle}</span>
//               <span>{addLeadingZero(count)}</span>
//             </div>
//             <div
//               className=""
//               style={{
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "center",
//                 gap: "15px",
//               }}
//             >
//               <div
//                 className=""
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   gap: "10px",
//                 }}
//               >
//                 <Search setsearch={setsearch} style={{ width: "421px" }} />
//               </div>
//               <div className="views_button_wrapper">
//                 <button
//                   className={!switcher ? "active" : ""}
//                   onClick={(e) => {
//                     setswitcher(false);
//                   }}
//                 >
//                   <i className="bi bi-grid"></i>
//                 </button>
//                 <button
//                   className={switcher ? "active" : ""}
//                   onClick={(e) => {
//                     setswitcher(true);
//                   }}
//                 >
//                   <i className="bi bi-list"></i>
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="overview_main">
//             {!switcher ? (
//               <div
//                 className=""
//                 style={{
//                   display: "flex",
//                   gap: "20px",
//                   flexWrap: "wrap",
//                   width: "100%",
//                 }}
//               >
//                 {cardData.map((card, index) => {
//                   const contact = card?.contact_detail?.[0];
//                   const address =
//                     toggle === "Tenants"
//                       ? card?.property_tenant_address?.[0]
//                       : card.property_landlord_address?.[0];
//                   return (
//                     <div
//                       className="cardWrapper"
//                       key={index}
//                       onClick={() => {
//                         if (toggle === "Landlords") {
//                           setSelectedLandLordId(card?.id);
//                           setLandLordPopup(true);
//                         } else {
//                           console.log(card.id, ";ajsdjflasldfaksf");

//                           setSelectedPropertyId(
//                             card?.tenancies?.[0]?.tenancies?.[0]?.property?.id
//                           );
//                           dispatch(setPropertyDetailsFilter("1"));
//                           dispatch(setContactDetailsFilter(2));
//                           setpopup(true);
//                         }
//                       }}
//                     >
//                       <div className="cardHeader">
//                         <div className="imgDiv">
//                           {contact?.first_name.slice(0, 2)}
//                         </div>
//                       </div>
//                       <div className="cardBody">
//                         <div className="section">
//                           <span>
//                             {formatName(
//                               contact?.title,
//                               contact?.first_name,
//                               contact?.last_name
//                             )}
//                           </span>
//                         </div>
//                         <div className="section">
//                           <span style={{ fontWeight: "300", fontSize: "16px" }}>
//                             {address?.Name
//                               ? formatAddress([
//                                   address?.Name + " " + address?.street,
//                                   address?.town,
//                                   address?.postcode,
//                                 ])
//                               : "--"}
//                             {}
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             ) : (
//               <div
//                 style={{
//                   padding: "10px",
//                   background: "white",
//                   width: "100%",
//                   height: "769px",
//                   borderRadius: "13px",
//                 }}
//               >
//                 <TableComponent
//                   data={tableData ? tableData : []}
//                   columns={columns}
//                   onclickrow={clickrow}
//                   count={count}
//                   pageindex={pageindex}
//                   pagesize={pagesize}
//                   setPageIndex={setPageIndex}
//                   setPageSize={setPageSize}
//                 />
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       {!loading && (
//         <>
//           {fetchProperties.data?.data &&
//             (popup ? (
//               <Propertydetails
//                 rowvalue={fetchProperties.data?.data}
//                 setpopup={setpopup}
//                 selectedData={"selectedData"}
//                 fromContact={true}
//                 setSelectedLandLordId={setSelectedLandLordId}
//               />
//             ) : (
//               ""
//             ))}
//           {fetchLandlordDetails.data?.data &&
//             (landLordPopup ? (
//               <LandLordPopup
//                 rowvalue={fetchLandlordDetails.data?.data}
//                 setpopup={setLandLordPopup}
//                 selectedData={"selectedData"}
//                 fromContact={true}
//               />
//             ) : (
//               ""
//             ))}
//         </>
//       )}
//     </>
//   );
// };

// export default Contacts;

import { useEffect, useMemo, useState } from "react";
import "./Styles/Contacts.scss";
import ClientMoneyOverview from "../../Components/clientmoney/clientmoneyoverview";
// import Accounts from "./accounts";
// import PaymentDue from "./paymentdue";
// import Reports from "./reports";
import { useDispatch, useSelector } from "react-redux";
import {
  setClientMoneyStatus,
  setContactDetailsFilter,
  setContactsFilter,
  setPropertyDetailsFilter,
} from "../../../redux/reducer/counterslice";
import FilterPanel from "../../../MainComponent/FilterPanel";
import { useFetchTenantNoId } from "../../../Api/ContactsQuery";
import {
  addLeadingZero,
  formatAddress,
  formatName,
  formatPounds,
  formatUKTelephone,
} from "../../../Utils/Utils";
import {
  useFetchLandlordDetails,
  useFetchLandlordNoId,
  useFetchProperties,
  useFetchPropertiesCompliance,
} from "../../../Api/PropertiesQuery";
import Propertydetails from "../Property/popups/propertydetails";
import emailIcon from "../../../Assets/Common/emailIcon.svg";
import phoneIcon from "../../../Assets/Common/phoneIcon.svg";
import LandLordPopup from "./Popup/LandlordPopup";
import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import { useFetchTenantsContacts } from "../../../Api/PaginationQuery";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "antd";

const Contacts = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const loading = useSelector((state) => state.counter.loader);
  const toggle = useSelector((state) => state.counter.contactsFilter);
  const clientid = useSelector((state) => state.counter.clientid);
  const [popup, setpopup] = useState(false);
  const [landLordPopup, setLandLordPopup] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedLandLordId, setSelectedLandLordId] = useState("");
  const fetchTenant = useFetchTenantNoId(clientid);
  const fetchLandlord = useFetchLandlordNoId(clientid);
  const fetchLandlordDetails = useFetchLandlordDetails(selectedLandLordId);
  // const fetchLandlordDetails= []
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const [search, setsearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [rowValue, setRowValue] = useState([]);
  const [dataLoader, seDataLoader] = useState([]);

  const { data, fetchNextPage, hasNextPage } = useFetchTenantsContacts({
    params: {
      client: clientid,
      page: pageindex,
      page_size: 10,
      status: "dummy",
    },
  });
  useEffect(() => {
    if (data != undefined) {
      // dataLoader;
      const allResults = data?.pages?.flatMap((page) => page?.results);
      console.log(allResults, "lajkslfjasjdlaslfdj");
      seDataLoader(allResults);
      setCount(
        data?.pages?.[0]?.count ? data?.pages?.[0]?.count : data?.[0]?.count
      );
    }
  }, [data]);
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const [switcher, setswitcher] = useState(false);
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "contact_name",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.contact_detail?.[0];
          return (
            <p style={{ textTransform: "capitalize" }}>
              {data.title}. {data.first_name} {data.last_name}
            </p>
          );
        },
        header: () => <span>Contact Name</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "email",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.email;
          return <p style={{ textTransform: "capitalize" }}>{data}</p>;
        },
        header: () => <span>Email</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "rooms",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.phone;
          return (
            <p style={{ textTransform: "capitalize" }}>
              {data ? formatUKTelephone(data) : "--"}
            </p>
          );
        },
        header: () => <span>Telephone</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "rooms",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.phone;
          return <p style={{ textTransform: "capitalize" }}>{"--"}</p>;
        },
        header: () => (
          <span style={{ textTransform: "none" }}>No of Properties</span>
        ),
      }),
    ],
    []
  );
  useEffect(() => {
    console.log(fetchTenant.data?.data?.results, "kajskdfjjasdldlf");
    if (fetchTenant.data?.data?.results && toggle === "Tenants") {
      setCardData(fetchTenant.data?.data?.results);
      setTableData(fetchTenant.data?.data?.results);
      setCount(fetchTenant.data?.data?.count);
      setpopup(true);
    }
  }, [fetchTenant.data, toggle, selectedPropertyId]);
  useEffect(() => {
    console.log(fetchLandlord.data, "laksldflasldfjsd");
    if (fetchLandlord.data?.data?.results && toggle === "Landlords") {
      setCardData(fetchLandlord.data?.data?.results);
      setTableData(fetchLandlord.data?.data?.results);
      setCount(fetchLandlord.data?.data?.count);
    }
  }, [fetchLandlord.data, toggle]);

  const updateFilter = (item) => {
    setSelectedPropertyId(undefined);
    setSelectedLandLordId(undefined);
    dispatch(setContactsFilter(item));
  };
  const clickrow = (card) => {
    console.log(card.id, "laksjdfljaskdfjasjofj");
    if (toggle === "Landlords") {
      setSelectedLandLordId(card?.id);
      setLandLordPopup(true);
    } else {
      setSelectedPropertyId(card?.tenancies?.[0]?.tenancies?.[0]?.property?.id);
      dispatch(setPropertyDetailsFilter("1"));
      dispatch(setContactDetailsFilter(2));
    }
  };

  // useEffect(() => {
  //   console.log(selectedLandLordId, "laskjdflkjajsdfojas");
  // }, [selectedLandLordId]);
  useEffect(() => {
    console.log(data, "laskjdflkjajsdfojas");
  }, [data]);
  useEffect(() => {
    if (toggle === "Landlords") {
      console.log(fetchLandlordDetails.data?.data, "laskjdflkjajsdfojas");
      if (fetchLandlordDetails.data?.data !== undefined) {
        setRowValue(fetchLandlordDetails.data?.data);
      }
    }
  }, [selectedLandLordId, fetchLandlordDetails.data]);
  if (loading) {
    return null; // Or some loading component
  }

  return (
    <>
      <div className="contactsWrapper">
        <div className="contacts_main">
          <div className="toggle_header" style={{ height: "auto" }}>
            <div style={{ margin: "0 0 0 0 ", width: "100%" }}>
              <FilterPanel
                tabs={["Tenants", "Landlords"]}
                setActiveTab={updateFilter}
                activeTab={toggle}
              />
            </div>
          </div>
          <div
            className="search_wrapper"
            style={{ justifyContent: "space-between" }}
          >
            <div className="totalArrears" style={{ marginBottom: "0" }}>
              <span>Total {toggle}</span>
              <span>{addLeadingZero(count)}</span>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Search setsearch={setsearch} style={{ width: "421px" }} />
              </div>
              <div className="views_button_wrapper">
                <button
                  className={!switcher ? "active" : ""}
                  onClick={(e) => {
                    setswitcher(false);
                  }}
                >
                  <i className="bi bi-grid"></i>
                </button>
                <button
                  className={switcher ? "active" : ""}
                  onClick={(e) => {
                    setswitcher(true);
                  }}
                >
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <div className="skelitonDiv" style={{ width: "32.5%" }}>
                <Skeleton.Avatar
                  active={true}
                  size={"large"}
                  shape={"circle"}
                  style={{ height: "100px", width: "100px" }}
                />

                <Skeleton
                  active
                  style={{ height: "20px" }}
                  paragraph={{ rows: 1 }}
                />
              </div>
            ))}
          </div> */}

          <div className="overview_main">
            {!switcher ? (
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {dataLoader.map((card, index) => {
                  const contact = card?.contact_detail?.[0];
                  const address =
                    toggle === "Tenants"
                      ? card?.property_tenant_address?.[0]
                      : card.property_landlord_address?.[0];
                  return (
                    <div
                      className="cardWrapper"
                      key={index}
                      onClick={() => {
                        if (toggle === "Landlords") {
                          setSelectedLandLordId(card?.id);
                          setLandLordPopup(true);
                        } else {
                          console.log(card.id, ";ajsdjflasldfaksf");

                          setSelectedPropertyId(
                            card?.tenancies?.[0]?.tenancies?.[0]?.property?.id
                          );
                          dispatch(setPropertyDetailsFilter("1"));
                          dispatch(setContactDetailsFilter(2));
                          setpopup(true);
                        }
                      }}
                    >
                      <div className="cardHeader">
                        <div className="imgDiv">
                          {contact?.first_name.slice(0, 2)}
                        </div>
                      </div>
                      <div className="cardBody">
                        <div className="section">
                          <span>
                            {formatName(
                              contact?.title,
                              contact?.first_name,
                              contact?.last_name
                            )}
                          </span>
                        </div>
                        <div className="section">
                          <span style={{ fontWeight: "300", fontSize: "16px" }}>
                            {address?.Name
                              ? formatAddress([
                                  address?.Name + " " + address?.street,
                                  address?.town,
                                  address?.postcode,
                                ])
                              : "--"}
                            {}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div ref={ref} style={{ height: "1px" }} />
              </div>
            ) : (
              <div
                style={{
                  padding: "10px",
                  background: "white",
                  width: "100%",
                  height: "769px",
                  borderRadius: "13px",
                }}
              >
                <TableComponent
                  data={tableData ? tableData : []}
                  columns={columns}
                  onclickrow={clickrow}
                  count={count}
                  pageindex={pageindex}
                  pagesize={pagesize}
                  setPageIndex={setPageIndex}
                  setPageSize={setPageSize}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <>
          {fetchProperties.data?.data &&
            (popup ? (
              <Propertydetails
                rowvalue={fetchProperties.data?.data}
                setpopup={setpopup}
                selectedData={"selectedData"}
                fromContact={true}
                setSelectedLandLordId={setSelectedLandLordId}
              />
            ) : (
              ""
            ))}
          {fetchLandlordDetails.data?.data &&
            (landLordPopup ? (
              <LandLordPopup
                rowvalue={fetchLandlordDetails.data?.data}
                setpopup={setLandLordPopup}
                selectedData={"selectedData"}
                fromContact={true}
              />
            ) : (
              ""
            ))}
        </>
      )}
    </>
  );
};

export default Contacts;
