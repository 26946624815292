import "../../../StyleSheet/Maintenance/popups/maintenanceoverviewpopup.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFetchMaintenanceOverviewAmount } from "../../../../Api/MaintenanceQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default function MaintenanceOverviewPopup(props) {
  const columnHelper = createColumnHelper();
  const [tabledata, settabledata] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const clientid = useSelector((state) => state.counter.clientid);

  const totalamountdata = useFetchMaintenanceOverviewAmount({
    client: clientid,
    paid_by:
      props?.keydata?.text === "Total maintenance spend" ? "total" : "solace",
    page: pageindex,
    page_size: pagesize,
    start_date: props?.Range[0] ? props?.Range[0] : null,
    end_date: props?.Range[1] ? props?.Range[1] : null,
  });
  console.log(props.Range, "Range");
  useEffect(() => {
    if (totalamountdata.data != undefined) {
      console.log(totalamountdata.data, "totalamountdata");
      settabledata(totalamountdata.data.data.results);
      setCount(totalamountdata.data.data.count);
    }
  }, [totalamountdata.data, pageindex, pagesize]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "StartDate - EndDate",

        cell: (info) => (
          <p>
            {dayjs(info.row.original?.approved_startdate).format("DD/MM/YYYY") +
              " -  " +
              dayjs(info.row.original?.approved_enddate).format("DD/MM/YYYY")}
          </p>
        ),
      }),
      columnHelper.accessor((row) => row.Address, {
        id: "Address",
        cell: (info) => (
          <p>
            {[
              info.row.original?.property?.name,
              info.row.original?.property?.street,
              info.row.original?.property?.city,
              info.row.original?.property?.postcode,
            ]
              .filter(Boolean)
              .join(", ")}
          </p>
        ),
        header: () => <span>Address</span>,
      }),
      columnHelper.accessor("title", {
        header: "Summary",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor((row) => row.issue_list, {
        header: "Description",
        // cell: info => info?.issue_list?.description,
        cell: (info) => <span>{info.row.original.issue_list.description}</span>,
      }),
      columnHelper.accessor("invoice_amount", {
        header: "Amount",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>£{info.getValue()}</span>
        ),
      }),
    ],
    []
  );

  return (
    <div className="popup_main_wrapper">
      <div className="main_popup">
        <div className="header">
          <div className="content">
            <h1>
              {props?.keydata?.text}
              <span>£{props?.keydata?.amount}</span>
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => props.setPopup(false)}
                src={closebutton}
              />
            </div>
          </div>
        </div>
        <div className="main_content">
          <TableComponent
            columns={columns}
            data={tabledata}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}

            // onclickrow={row => console.log(row)}
          />
        </div>
      </div>
    </div>
  );
}
