import "../../StyleSheet/dashboard/home.scss";
import ComplianceHome from "./compliance";
import ArrearsHome from "./arrears";
import PaymentsHome from "./payments";
import PeriodicInsepction from "./periodicinsepction";
import CurrentOccpancy from "./currentoccupancy";
import MaintanenceDashboard from "./Maintanence";
import { useSelector } from "react-redux";

export default function Home() {
  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="home_wrapper">
      <div className="home_inner_wrapper row" style={{ height: "731px" }}>
        <div
          className="col-12 col-md-12 col-lg-7"
          style={{
            width: "61.5%",
            maxHeight: "725px",
            height: "725px",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <ComplianceHome />
        </div>
        <div
          className="col-12 col-md-12 col-lg-4 h-100"
          style={{ padding: "0", width: "37%", marginLeft: "21px" }}
        >
          <div className="row h-100">
            <div className="col-md-12">
              <ArrearsHome />
            </div>
            <div className="col-md-12">
              <PaymentsHome />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-md-6 col-lg-4 h-100"
          style={{ marginTop: "15px", width: "31%", paddingLeft: "0" }}
        >
          <PeriodicInsepction />
        </div>
        <div
          className="col-12 col-md-6 col-lg-4 h-100"
          style={{ marginTop: "15px", width: "31%" }}
        >
          <CurrentOccpancy />
        </div>
        <div
          className="col-12 col-md-6 col-lg-4 h-100"
          style={{ marginTop: "15px", width: "38%", paddingRight: "0" }}
        >
          <MaintanenceDashboard />
        </div>
      </div>
    </div>
  );
}
