import { useState } from "react";
import "../../StyleSheet/clientmoney/accounts.scss";
import wallet from "../../../Assets/Client/clientmoney/Wallet.svg";
import ReserveAccount from "./accounts/reservedaccount";
import ClientAccount from "./accounts/clientaccount";
import DepositAccount from "./accounts/depositeaccount";
import { useDispatch, useSelector } from "react-redux";
import { setClientMoneyAccountStatus } from "../../../redux/reducer/counterslice";
export default function Accounts() {
  const toggle = useSelector((state) => state.counter.clientMoneyAccountStatus);
  const dispatch = useDispatch();

  return (
    <div className="accounts_main">
      <div className="header">
        <button
          className={toggle === "Reserve Account" ? "active" : ""}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Reserve Account
        </button>
        <button
          className={toggle === "Client Account" ? "active" : ""}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Client Account
        </button>
        <button
          className={toggle === "Deposit Account" ? "active" : ""}
          onClick={(e) =>
            dispatch(setClientMoneyAccountStatus(e.target.innerText))
          }
        >
          Deposit Account
        </button>
      </div>
      <div className="content_wrapper">
        {toggle === "Reserve Account" ? (
          <ReserveAccount />
        ) : toggle === "Client Account" ? (
          <ClientAccount />
        ) : (
          <DepositAccount />
        )}
      </div>
    </div>
  );
}
