import React, { useEffect } from "react";
import dayjs from "dayjs";
import archive from "../../../../Assets/Common/archive.svg";
import download from "../../../../Assets/Common/Download.svg";

const Documents = ({
  title,
  documents,
  setSelectedArchivedData,
  setArchivePopup,
  setPayloadForArchiveApi,
  type,
  rowvalue,
}) => {
  const openFile = (file) => {
    if (file?.length > 0) {
      window.open(file[0], "_blank");
    }
  };
  console.log(documents, "documents");
  const getStatusColor = (status) => {
    switch (status) {
      case "not_due":
        return "#4DDE87";
      case "due":
        return "#FFB200";
      case "overdue":
        return "#FF3A29";
      default:
        return "#000";
    }
  };
  useEffect(() => {
    console.log(rowvalue, "lakjsfdljaljfsdl");
  }, [rowvalue]);

  return (
    <>
      <div className="body_content_wrapper row m-0">
        <div className="col-2 p-0 m-0 left">
          <h2>{title}</h2>
        </div>
        <div className="col-10 p-0 m-0 right">
          <div className="row p-0 m-0">
            {documents.map((ele, index) => (
              <div className="col-md-4" key={index}>
                <div className="download_card">
                  <div
                    className="text"
                    onClick={() => openFile(ele.file)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        background: "#F7F9FB",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src={ele.document_type__icon}
                        style={{ width: "26px", height: "26px" }}
                        alt="icon"
                      />
                    </div>
                    <p>{ele.custom_name}</p>
                  </div>
                  <div
                    className="dividerLine"
                    style={{ background: getStatusColor(ele?.status) }}
                  ></div>
                  {ele?.file?.length > 0 ? (
                    <div
                      className="bottomSection"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span style={{ cursor: "pointer" }}>Expiry</span>
                        <span style={{ color: "black" }}>
                          {dayjs(ele.new_expiry_date).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <DocumentAction
                          icon={archive}
                          label="Archived"
                          data={ele}
                          setSelectedArchivedData={setSelectedArchivedData}
                          setArchivePopup={setArchivePopup}
                          ele={ele}
                          setPayloadForArchiveApi={setPayloadForArchiveApi}
                          type={type}
                          rowvalue={rowvalue}
                          title={title}
                        />
                        <DocumentAction icon={download} label="Download" />
                      </div>
                    </div>
                  ) : (
                    "No document uploaded."
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  );
};

const DocumentAction = ({
  icon,
  label,
  setSelectedArchivedData,
  setArchivePopup,
  ele,
  setPayloadForArchiveApi,
  type,
  rowvalue,
  title,
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
    }}
    onClick={() => {
      if (label === "Archived") {
      }
      setArchivePopup(true);
      setSelectedArchivedData(ele);
      setPayloadForArchiveApi({
        d_id: ele.document_type,
        [type]:
          title === "Property Documents"
            ? rowvalue.id
            : title === "Tenancy Documents"
            ? rowvalue?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
            : "",
      });
      console.log(
        {
          d_id: ele.document_type,
          [type]:
            title === "Property Documents"
              ? rowvalue.id
              : title === "Tenancy Documents"
              ? rowvalue?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
              : "",
        },
        "laksjflkjaskdfjloiwaejr"
      );
    }}
  >
    <span style={{ cursor: "pointer" }}>{label}</span>
    <img
      src={icon}
      width="12px"
      height="12px"
      alt={label}
      style={{ cursor: "pointer" }}
    />
  </div>
);

export default Documents;
