import { useState, useEffect } from "react";
import "../../StyleSheet/dashboard/currentoccupancy.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFetchCurrentOccupancyStatus } from "../../../Api/homeQuery";
import { addLeadingZero } from "../../../Utils/Utils";
import {
  setPropertyOccupancyPopup,
  setPropertyOccupancyPopupFilter,
} from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";

export default function CurrentOccpancy() {
  const [chartData, setChartData] = useState([0, 0, 0]);
  const [totalProperties, setTotalProperties] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const text = ["Occupied", "Upcoming Vacancies", "Vacant"];
  const userId = useSelector((state) => state.counter.loginuserdata.id);
  const clientid = useSelector((state) => state.counter.clientid);
  const fetchComplianceStats = useFetchCurrentOccupancyStatus({
    params: { client: clientid },
  });
  useEffect(() => {
    if (fetchComplianceStats.data?.data) {
      console.log(fetchComplianceStats.data.data, "dsd");
      setChartData([
        fetchComplianceStats.data.data.occupied,
        fetchComplianceStats.data.data.vacant_in_progress,
        fetchComplianceStats.data.data.vacant,
      ]);
      setTotalProperties(
        fetchComplianceStats.data.data.occupied +
          fetchComplianceStats.data.data.vacant_in_progress +
          fetchComplianceStats.data.data.vacant
      );
    }
  }, [fetchComplianceStats.data]);
  const filters = ["Occupied", "Upcoming Vacancies", "Vacant"];

  return (
    <div className="currentoccupancy_wrapper_main">
      <h1 className="header">Current Occupancy</h1>
      <div className="currentoccupancy_inner_wrapper">
        {chartData.length > 0 &&
          chartData.map((item, index) => {
            // Calculate the percentage width based on the total number of properties
            const percentage = (item / totalProperties) * 100;

            return (
              <div
                className="progress_main"
                key={index}
                onClick={() => {
                  navigate("/property/overview");
                  dispatch(setPropertyOccupancyPopup(true));
                  dispatch(setPropertyOccupancyPopupFilter(filters[index]));
                }}
              >
                <div className="main_text">
                  <span className="text">{text[index % text.length]}</span>
                </div>

                <div className="progress_bar" style={{ height: "18px" }}>
                  <span
                    style={{
                      color: colors[index % colors.length],
                      width: "25px",
                      marginRight: "10px",
                    }}
                  >
                    {addLeadingZero(item)}
                  </span>

                  <div
                    className="progress progress-sm w-100"
                    style={{ background: "white", height: "18px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${percentage}%`, // Use the calculated percentage
                        borderRadius: "1.8rem",
                        backgroundColor: colors[index % colors.length],
                      }}
                      aria-valuenow={percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
