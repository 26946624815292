import React, { useEffect, useRef, useState } from "react";
import { SideBarData } from "../../Content/data";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../Assets/Sidebar/DropdownArrow.svg";
import logout from "../../../Assets/Common/logoutIcon.svg";
import "./style.scss";
import { notificationbellactive } from "../../Content/image";
import { sidebar } from "../../../redux/reducer/counterslice";
import { useDispatch, useSelector } from "react-redux";
export default function SideBarComponent() {
  const [dropSelect, setDropSelect] = useState(null);
  const [sidebaractive, setsidebaractive] = useState(true);
  const [logoutPop, setLogoutPop] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [subActiveElement, setSubActiveElement] = useState("");
  const userData = useSelector((state) => state.counter.loginuserdata);
  const dispatch = useDispatch();
  const [childShow, setChildShow] = useState(true);
  const [prequelIndex, setPrequelIndex] = useState(0);

  const handleDropDownClick = (index) => {
    setsidebaractive(true);
    setDropSelect((prevIndex) => (prevIndex === index ? null : index));
    if (dropSelect != index) {
      dispatch(sidebar(index));
    }
  };
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const firstPart = pathParts[1];
    const foundItem = SideBarData.find(
      (item) => firstPart === item.href.split("/")[1]
    );
    const selectedIndex = foundItem ? SideBarData.indexOf(foundItem) : null;
    setSelected(selectedIndex);

    if (pathParts[2]) {
      const subPath = pathParts[2];
      setSubActiveElement(subPath);
      if (foundItem) {
        setDropSelect(selectedIndex);
      }
    } else {
      //   setSubActiveElement("");
    }
  }, [location]);

  //   useEffect(() => {
  //     if (!sidebaractive) {
  //       setDropSelect(null);
  //     }
  //   }, [sidebaractive]);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setLogoutPop(false);
    }
  };

  useEffect(() => {
    if (logoutPop) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // setLogoutPop(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [logoutPop]);

  return (
    <div className={`sidebar_main_wrapper  ${sidebaractive ? "active" : ""}`}>
      <div className="sidebar_header">
        <div
          className={`title_logo ${sidebaractive ? "" : "active"}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        ></div>
      </div>
      <div className="side_navdata_wrapper">
        {SideBarData.map((ele, index) => {
          return (
            <React.Fragment key={index}>
              <NavLink
                className={`navLink ${selected === index ? "activelink" : ""}`}
                onClick={() => {
                  if (selected != index) {
                    console.log(selected, "lkajsdlfajs");
                    setChildShow(true);
                    handleDropDownClick(index);
                  } else {
                    setChildShow(!childShow);
                  }
                }}
                to={ele.href}
              >
                <div className="innerLeft">
                  <div
                    className="menu"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      width={32}
                      height={32}
                      src={selected == index ? ele.logoactive : ele.logo}
                      alt={ele.data}
                    />
                    <span className={sidebaractive ? "" : "dnone"}>
                      {ele.data}
                    </span>
                  </div>
                  {ele?.children &&
                    (childShow && selected == index ? (
                      <div className={sidebaractive ? "" : "dnone"}>
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          width={19}
                          height={19}
                          src={arrow}
                          alt="dropdown"
                          onClick={() => setChildShow(false)}
                        />
                      </div>
                    ) : (
                      <div className={sidebaractive ? "" : "dnone"}>
                        <img
                          width={19}
                          height={19}
                          src={arrow}
                          alt="dropdown"
                          onClick={() => setChildShow(true)}
                        />
                      </div>
                    ))}
                </div>
              </NavLink>
              {ele?.children && dropSelect === index && childShow && (
                <div className="dropdown_content">
                  <ul>
                    {ele.children.map((child, childIndex) => (
                      <NavLink
                        to={child.href}
                        className={`${
                          subActiveElement === child.match
                            ? "subactivelink"
                            : ""
                        }`}
                        key={childIndex}
                      >
                        <li
                          className={`${
                            subActiveElement === child.match
                              ? "subactivelink"
                              : ""
                          }`}
                        >
                          <span className={subActiveElement ? "" : "dnone"}>
                            {child.data}
                          </span>
                        </li>
                      </NavLink>
                    ))}
                  </ul>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>

      <div
        className="sidebar_footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {/* <div className="contactus" style={{ width: "fit-content" }}>
          <button
            style={{
              padding: sidebaractive ? "13px 79px" : "8px",
              textWrap: "nowrap",
              width: "fit-content",
            }}
          >
            {sidebaractive ? "Contact us" : <i class="bi bi-envelope-at"></i>}
          </button>
        </div> */}
        <div
          className="inner_wrapper"
          style={{ position: "relative", width: "100%" }}
        >
          <div className="user_text_area">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                className="profile"
                ref={popupRef}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setLogoutPop(true);
                }}
              >
                {userData.first_name?.slice(0, 1)}
              </div>
              <div
                className={`text_area ${sidebaractive ? "" : "dnone"}`}
                style={{ padding: "0", marginLeft: "0px" }}
              >
                <p>Hello,</p>
                <p>{userData.first_name}</p>
              </div>
            </div>
            <img
              className={`${sidebaractive ? "" : "dnone"}`}
              src={notificationbellactive}
              width={32}
              height={32}
            ></img>
            {logoutPop && (
              <div
                ref={popupRef}
                className="popup-container"
                style={{
                  background: "white",
                  position: "absolute",
                  top: "-50px",
                  width: "250px",
                  // height: "46px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: "10px 0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <div
                  className={`text_area ${sidebaractive ? "" : "dnone"}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px",
                    //   marginLeft: "20px",
                  }}
                >
                  <div
                    className="profile"
                    style={{
                      width: "29px",
                      height: "29px",
                      padding: "5px 11px 0px 8px",
                      fontSize: "16px",
                    }}
                  >
                    {userData.first_name?.slice(0, 1)}
                  </div>
                  <p
                    style={{ color: "black", fontWeight: "500", font: "14px" }}
                  >
                    {/* {userData.first_name} */}
                    Logout
                  </p>
                </div>
                <img
                  src={logout}
                  alt="logout"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
