import { useEffect, useState } from "react";
import "../../StyleSheet/compliance/compliancelist.scss";
import { useFetchCompliance } from "../../../Api/PropertiesQuery";
import dummy1 from "../../../Assets/dummy/dummy1.svg";
import { useSelector } from "react-redux";
export default function ComplianceList(props) {
  const {
    setFirstLoad,
    firstLoad,
    setComplianceList,
    complianceList,
    setsearch,
  } = props;
  const [listActive, setListActive] = useState("0");
  const [payload, setPayload] = useState([]);
  const fetchCompliance = useFetchCompliance({
    params: {
      page: 1,
      page_size: 1000,
      type:
        complianceList.complianceType === "property" ? "Property" : "Tenancy",
    },
  });
  useEffect(() => {
    if (fetchCompliance.data?.data?.results) {
      setPayload(fetchCompliance.data.data.results);
      if (firstLoad) {
        setListActive(fetchCompliance.data.data.results?.[0]?.id);
        setComplianceList((prev) => ({
          ...prev,
          complianceSelected: fetchCompliance.data.data.results?.[0]?.id,
          selectedPayload: fetchCompliance.data.data.results?.[0],
        }));
        setFirstLoad(false);
      }
    }
  }, [fetchCompliance.data, complianceList.complianceType]);

  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="compliance_List">
      <div className="toggle_header">
        <p
          onClick={(e) => {
            setComplianceList((prev) => ({
              ...prev,
              complianceType: "property",
            }));
            setsearch("");
          }}
          className={
            complianceList.complianceType === "property" ? "active" : ""
          }
        >
          Property
        </p>
        <p
          onClick={(e) => {
            setComplianceList((prev) => ({
              ...prev,
              complianceType: "tenancy",
            }));
            setsearch("");
          }}
          className={
            complianceList.complianceType === "tenancy" ? "active" : ""
          }
        >
          Tenancy
        </p>
      </div>
      <div className="content_wrapper">
        <ul>
          {payload.map((ele, index) => {
            return (
              <li
                className={listActive == ele.id ? "active" : "inactive"}
                onClick={(e) => {
                  setComplianceList((prev) => ({
                    ...prev,
                    complianceSelected: ele.id,
                    selectedPayload: ele,
                  }));
                  setListActive(ele.id);
                  setsearch("");
                }}
                key={index}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {" "}
                  {ele?.icon ? (
                    <div
                      style={{
                        background: "#F3F3F7",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "40px",
                        padding: "25px",
                      }}
                    >
                      <img
                        src={ele?.icon}
                        style={{ width: "25px", height: "25px" }}
                      ></img>
                    </div>
                  ) : (
                    <img src={dummy1} />
                  )}
                  {ele.document_type}
                </span>
                {/* <img
                  src={ele?.icon ? ele.icon : dummy1}
                  alt="img"
                  width={"25px"}
                  height={"25px"}
                ></img> */}
                <p>{ele.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
