import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchArrearsSelected } from "../../../../Api/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useFetchPropertyBasedOnStatus } from "../../../../Api/PropertiesQuery";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import {
  setPropertyOccupancyPopup,
  setPropertyOccupancyPopupFilter,
} from "../../../../redux/reducer/counterslice";

export default function OccupancyPopup(props) {
  const { activeTab, setActiveTab } = props;
  const columnHelper = createColumnHelper();
  const [paramsPayload, setParamsPayload] = useState("occupied");
  const [TableData, setTableData] = useState([]);
  const clientid = useSelector((state) => state.counter.loginuserdata.id);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const fetchArrears = useFetchPropertyBasedOnStatus({
    status: paramsPayload,
    client: clientid,
    page: pageindex,
    page_size: pagesize,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "photo",
        header: "Photo",
        sortingFn: "basic",

        enableSorting: false,
        cell: (info) => {
          return (
            <img
              src={info.row.original?.image?.[0]}
              alt="image"
              width={"102px"}
              height={"73px"}
              style={{ borderRadius: "10px" }}
            />
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "propertyAddress",
        header: "Property Address",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {formatAddress([
                data.name,
                data.street,
                data.city,
                data.postcode,
              ])}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "landlord_Name",
        header: "Landlord Name",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original.landlord?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "tenant",
        header: "Tenant Name",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.row.original, "lkasjdlfjasdf");
          const data = info.row.original?.tenant?.tenant__contact_detail?.[0];

          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data?.title ? (
                <p>
                  {data.title} {data.first_name} {data.last_name}
                </p>
              ) : (
                "--"
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "rent",
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.row.original, "lkasjdlfjasdf");
          const data = info.row.original.landlord?.contact_detail?.[0];

          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {info.row.original?.tenant?.rent ? (
                <p>£{formatPounds(info.row.original?.tenant?.rent)}</p>
              ) : (
                "--"
              )}
            </span>
          );
        },
      }),
    ],
    []
  );
  const setParams = () => {
    if (activeTab === "Occupied") {
      setParamsPayload("occupied");
    } else if (activeTab === "Upcoming Vacancies") {
      setParamsPayload("vacant_in_progress");
    } else if (activeTab === "Vacant") {
      setParamsPayload("vacant");
    }
  };
  useEffect(() => {
    setParams();
  }, [activeTab]);
  useEffect(() => {
    console.log(fetchArrears.data?.data?.results, "ajsfjalsjkdfasfdasf");
    if (fetchArrears.data?.data && pageindex && pagesize) {
      setTableData(fetchArrears.data?.data?.results);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data, pageindex, pagesize, activeTab]);

  const updateFilter = (item) => {
    dispatch(setPropertyOccupancyPopupFilter(item));
  };
  return (
    <div className="popup_main_wrapper">
      <div className="main_popup">
        <div className="header">
          <div className="content">
            <h1>
              Occupancy:
              <span
                style={{
                  color:
                    props?.activeTab === "Occupied"
                      ? "#4dde87"
                      : props?.activeTab === "Upcoming Vacancies"
                      ? "#FFB200"
                      : props?.activeTab === "Vacant"
                      ? "#FF3A29"
                      : "#000000",
                }}
              >
                {props?.activeTab}
              </span>
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => dispatch(setPropertyOccupancyPopup(false))}
                src={closebutton}
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "40px",
              background: "rgb(247,249,251)",
              borderRadius: "15px",
            }}
          >
            <FilterPanel
              tabs={["Occupied", "Upcoming Vacancies", "Vacant"]}
              setActiveTab={updateFilter}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div
          className=" occupancyConfig"
          style={{ position: "relative", top: "-36px" }}
        >
          <TableComponent
            columns={columns}
            data={
              fetchArrears.data?.data?.results
                ? fetchArrears.data?.data?.results
                : []
            }
            // data={[{ id: "1" }, { id: "2" }]}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}

            //   handleRowClick={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
}
