import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const {
    selectedOption,
    handleSelectChange,
    options,
    placeholder = "Select an option",
    name = "",
  } = props;
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white", // Background color of the control
      borderColor: state.isFocused ? "rgba(129, 126, 255, 0.8)" : "#ccc", // Border color when focused
      borderRadius: "8px", // Border radius
      textTransform: "capitalize",
      boxShadow: state.isFocused
        ? "0 0 0 1px rgba(129, 126, 255, 0.8)"
        : "none", // Box shadow when focused
      "&:hover": {
        borderColor: "#aaa", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "rgba(129, 126, 255, 0.8)" : "white",
      textTransform: "capitalize", // Background color of the options
      color: state.isFocused ? "white" : "black", // Text color of the options
      "&:hover": {
        backgroundColor: "rgba(129, 126, 255, 0.8)", // Background color on hover
        color: "white",
      },
      borderBottom: "1px solid rgba(224, 224, 224, 0.5)", // Add lighter bottom border to each option
      ":last-child": {
        borderBottom: "none", // Remove bottom border from the last option
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#817EFF",
      "&:hover": {
        color: "#817EFF",
      },
      transform: "scale(1.5)",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      color: "black", // Change the text color of the input
      textTransform: "capitalize", // Change text transform to capitalize
      padding: "12px", // Add padding
      margin: "0", // Remove margin
      border: "none",
    }),
  };

  return (
    <Select
      name={name}
      value={selectedOption}
      onChange={(e) => {
        handleSelectChange(e);
      }}
      options={options}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={true}
      styles={customStyles}
    />
  );
};

export { CustomSelect };
