// import "../../StyleSheet/clientmoney/reports.scss";
// import GenerateReport from "../../../MainComponent/fields/generatereport";
// import Search from "../../../MainComponent/fields/search";
// import { useEffect, useState } from "react";

// export default function Reports() {
//   const [search, setsearch] = useState("");
//   const [toggle, settogle] = useState(true);
//   const [tabledata, settabledata] = useState({});
//   const [Generate, setGenerate] = useState("");

//   useEffect(() => {
//     if (toggle) {
//       settabledata({
//         "12.4.2020": [
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "Mr Ashokkumar Bhimani",
//             StatementNumber: "Agency Payment",
//             Amount: "340",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "faef",
//             StatementNumber: "0293",
//             Amount: "dfja",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "dfa",
//             StatementNumber: "10398019",
//             Amount: "dfja",
//           },
//         ],
//         "15.5.2021": [
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "xyzabc",
//             StatementNumber: "567890",
//             Amount: "1000",
//           },
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "abcdxyz",
//             StatementNumber: "123456",
//             Amount: "1500",
//           },
//         ],
//         "10.6.2022": [
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "lmnopqr",
//             StatementNumber: "987654",
//             Amount: "2000",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "qrstuv",
//             StatementNumber: "654321",
//             Amount: "2500",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "uvwxy",
//             StatementNumber: "321098",
//             Amount: "3000",
//           },
//         ],
//       });
//     } else {
//       settabledata({
//         "14.4.2020": [
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "Mr Sanjay Dharshan",
//             StatementNumber: "Agency Payment",
//             Amount: "340",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "faef",
//             StatementNumber: "0293",
//             Amount: "dfja",
//           },
//           {
//             DateProcessed: "12.4.2020",
//             LandlordName: "dfa",
//             StatementNumber: "10398019",
//             Amount: "dfja",
//           },
//         ],
//         "15.5.2021": [
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "xyzabc",
//             StatementNumber: "567890",
//             Amount: "1000",
//           },
//           {
//             DateProcessed: "15.5.2021",
//             LandlordName: "abcdxyz",
//             StatementNumber: "123456",
//             Amount: "1500",
//           },
//         ],
//         "10.6.2022": [
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "lmnopqr",
//             StatementNumber: "987654",
//             Amount: "2000",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "qrstuv",
//             StatementNumber: "654321",
//             Amount: "2500",
//           },
//           {
//             DateProcessed: "10.6.2022",
//             LandlordName: "uvwxy",
//             StatementNumber: "321098",
//             Amount: "3000",
//           },
//         ],
//       });
//     }
//   }, [toggle]);

//   return (
//     <div className="reports">
//       <div className="header">
//         <div className="table_filter">
//           <div className="button_container">
//             <button
//               className={toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(true);
//               }}
//             >
//               Landlord Statements
//             </button>
//             <button
//               className={!toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(false);
//               }}
//             >
//               Agency Fees
//             </button>{" "}
//             <button
//               className={!toggle ? "active" : ""}
//               onClick={(e) => {
//                 settogle(false);
//               }}
//             >
//               Suppliers
//             </button>{" "}
//           </div>
//           <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//             <Search setsearch={setsearch} style={{ width: "410px" }} />
//             <GenerateReport
//               style={{ padding: "5px, 30px" }}
//               setGenerate={setGenerate}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="report_table">
//         {Object.keys(tabledata).map((key, index) => {
//           console.log(tabledata[key][0], "ajkahkj");
//           return (
//             <>
//               {index == 0 && (
//                 <table className="custom-table">
//                   <thead>
//                     <tr>
//                       {Object.keys(tabledata[key][0]).map((value, index1) => {
//                         return (
//                           <>
//                             <th>{value}</th>
//                             {index1 + 1 ==
//                             Object.keys(tabledata[key][0]).length ? (
//                               <th>
//                                 <p></p>Download
//                               </th>
//                             ) : (
//                               ""
//                             )}
//                           </>
//                         );
//                       })}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr style={{ border: "none" }}>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                       <td
//                         style={{ width: "286px", backgroundColor: "#f7f9fb" }}
//                       ></td>
//                     </tr>
//                   </tbody>
//                 </table>
//               )}
//               <div className="contenttable">
//                 <table className={`subtable ${index != 0 ? "mt-5" : ""}`}>
//                   <thead>
//                     <tr>
//                       <th>{key}</th>
//                       <th></th>
//                       <th></th>
//                       <th></th>
//                       <th></th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {tabledata[key].map((val, index) => {
//                       console.log(val, "val");
//                       return (
//                         <tr>
//                           <td>{val.DateProcessed}</td>
//                           <td>{val.LandlordName}</td>
//                           <td>{val.StatementNumber}</td>
//                           <td>£ {val.Amount}</td>
//                           <td>
//                             <p style={{ color: "#817EFF" }}>Download</p>
//                           </td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//               </div>
//             </>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
import "../../StyleSheet/clientmoney/reports.scss";
import "../../StyleSheet/clientmoney/paymentdue.scss";
import GenerateReport from "../../../MainComponent/fields/generatereport";
import Search from "../../../MainComponent/fields/search";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "rsuite";
import dayjs from "dayjs";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { formatPounds } from "../../../Utils/Utils";
import download from "../../../Assets/Common/Download.svg";

export default function Reports() {
  const [search, setsearch] = useState("");
  const [toggle, settogle] = useState(true);
  const [tabledata, settabledata] = useState([]);
  const [Generate, setGenerate] = useState("");
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const columnHelper = createColumnHelper();
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Date",
        sortingFn: "basic",
        id: "data",

        cell: (info) => {
          const data = info.row.original?.recpit_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Landlord Name",
        sortingFn: "basic",
        id: "name",

        cell: (info) => {
          const data = info.row.original?.payer_name;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Statement Number",
        sortingFn: "basic",
        id: "description",

        cell: (info) => {
          const data = info.row.original?.type;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",
        id: "amount",

        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Document",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <p
              style={{
                color: "#817EFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(data.file?.[0], "_blank");
              }}
            >
              Download&nbsp;&nbsp;
              <img
                src={download}
                width={"15px"}
                height={"15px"}
                alt="document image"
              />
            </p>
          );
        },
        header: () => <span>Document</span>,
      }),
    ],
    []
  );

  const today = new Date();

  const disableFutureDates = (date) => {
    return date > today;
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(dayjs(start).format("YYYY-MM-DD"));
      setEndDate(dayjs(end).format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().format("YYYY-MM-DD"));
      setEndDate(dayjs().format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="reports ">
      <div className="header">
        <div className="table_filter">
          <div className="button_container">
            <button
              className={toggle ? "active" : ""}
              onClick={(e) => {
                settogle(true);
              }}
            >
              Landlord Statements
            </button>
            <button
              className={!toggle ? "active" : ""}
              onClick={(e) => {
                settogle(false);
              }}
            >
              Agency Fees
            </button>{" "}
            <button
              className={!toggle ? "active" : ""}
              onClick={(e) => {
                settogle(false);
              }}
            >
              Suppliers
            </button>{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          background: "white",
          marginTop: "20px",
          borderRadius: "13px",
          height: "78vh",
          overflow: "auto",
        }}
      >
        <div className="header">
          <div
            className="table_filter"
            style={{
              padding: "20px 27px",
              margin: "0",
            }}
          >
            <div>
              <DateRangePicker
                placeholder="Select Date Range"
                format="dd MMM yyyy"
                character="    –    "
                size="lg"
                showHeader={false}
                className="custom-date-range-picker"
                disabledDate={disableFutureDates} // Disable future dates
                value={[new Date(startDate), new Date(endDate)]} // Controlled value
                onChange={handleDateChange} // Handle date changes
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                // padding: "20px",
              }}
            >
              <Search setsearch={setsearch} style={{ width: "410px" }} />
              <GenerateReport
                style={{ padding: "5px, 30px" }}
                setGenerate={setGenerate}
              />
            </div>
          </div>
        </div>
        <div className="tableComp">
          <TableComponent
            data={tabledata}
            columns={columns}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
}
