import { useEffect, useState } from "react";
import "../../StyleSheet/clientmoney/clientmoneyoverview.scss";
import ReactEcharts from "echarts-for-react";
import arrowraise from "../../../Assets/Client/clientmoney/ArrowRise-s.svg";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import ClientMoneyOverviewPopup from "./popups/clientmoneyoverviewpopup";
import { useDispatch, useSelector } from "react-redux";
import { useArrears } from "../../../Api/homeQuery";
import {
  useTodaysRentAgency,
  useBussinessStatus,
} from "../../../Api/clientmonetQuery";
import { formatPounds } from "../../../Utils/Utils";
import {
  setClientMoneyAgencyType,
  setClientMoneyPeriod,
  setClientRentPopup,
  setClientRentPopupData,
} from "../../../redux/reducer/counterslice";
import ArrearsPopup from "../Dashboard/popups/arrearspopup";

export default function ClientMoneyOverview() {
  const [toggle, setToggle] = useState(true);
  // const [popup, setPopup] = useState(false);
  const popup = useSelector((state) => state.counter.clientRentPopup);
  const keydata = useSelector((state) => state.counter.clientRentPopupData);
  const period = useSelector((state) => state.counter.clientMoneyPeriod);
  const type = useSelector((state) => state.counter.clientMoneyAgencyType);
  const [activeTab, setActiveTab] = useState("0 - 7 Days");
  const [popupstate, setPopupstate] = useState(false);

  const dispatch = useDispatch();
  const [todaysrent, setTodaysrent] = useState(0);
  const [todaysagencyincome, settodaysagencyincome] = useState(0);
  const [Data, setData] = useState([]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [linegraphdata, setlinegraphdata] = useState([]);
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  var today = new Date();
  var priorDate = new Date();
  var it = convert(today);
  var gt = convert(priorDate);
  const [range, setRange] = useState([]);
  const getAgencyrent = useTodaysRentAgency({
    params: { id: clientid, type: "Rent" },
  });
  const getAgencyManagementFee = useTodaysRentAgency({
    params: { id: clientid, type: "Management Fee" },
  });
  const getarrears = useArrears({
    params: { id: clientid },
    loading: false,
  });

  const getbussinessstatus = useBussinessStatus({
    params: {
      id: clientid,
      period: range.length > 0 ? "" : period,
      // from_date: range?.[0]||"",
      // to_date: range?.[1]||"",
      type: type,
    },
  });

  useEffect(() => {
    if (getAgencyrent.data) {
      console.log(getAgencyrent.data, "getAgencyrent");
      var totaltodaysrent = 0;
      getAgencyrent.data.data.map((ele) => {
        totaltodaysrent += ele.amount;
      });
      setTodaysrent(totaltodaysrent);
    }
  }, [getAgencyrent.data]);

  useEffect(() => {
    if (getAgencyManagementFee.data) {
      console.log(getAgencyManagementFee.data, "getAgencyManagementFee");
      var totaltodaysagi = 0;
      getAgencyManagementFee.data.data.map((ele) => {
        totaltodaysagi += ele.amount;
      });
      settodaysagencyincome(totaltodaysagi);
    }
  }, [getAgencyManagementFee.data]);

  useEffect(() => {
    console.log(getarrears.data, "ajdahkjadhkja");
    if (getarrears.data != undefined) {
      setData(getarrears.data.data[0]);
    }
  }, [getarrears.data]);

  const option = {
    series: [
      {
        name: "Arrears",
        type: "pie",
        radius: ["48%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 8,
          borderColor: "#fff",
          borderWidth: 3,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: Data?.charges_0_7_days || "",
            name: "0-7 Days",
            itemStyle: { color: "#000047" },
            type: "0_7_days",
          },
          {
            value: Data?.charges_8_14_days || "",
            name: "8-14 Days",
            itemStyle: { color: "#4DDE87" },
            type: "8_14_days",
          },
          {
            value: Data?.charges_15_30_days || "",
            name: "15-30 Days",
            itemStyle: { color: "#4FC3F4" },
            type: "15_30_days",
          },
          {
            value: Data?.charges_31_plus_days || "",
            name: "31Days+",
            itemStyle: { color: "#817EFF" },
            type: "31_plus_days",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    console.log(getbussinessstatus.data, "adkhkjadhk");
    if (getbussinessstatus.data != undefined) {
      setlinegraphdata(getbussinessstatus.data.data);
    }
  }, [clientid, period, range, type, getbussinessstatus.data]);
  const optionline = {
    xAxis: {
      type: "category",
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      data: Object.keys(linegraphdata).map((key) => {
        return key;
      }),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
          },
        },
        // data: [1000, 2000, 1000, 5000, 3000, 4000, 1000],
        data: Object.keys(linegraphdata).map((key) => {
          return linegraphdata[key];
        }),

        type: "line",
      },
    ],
  };

  console.log(optionline, linegraphdata, "optionline");

  const onChartClick = (params) => {
    console.log("Chart clicked", params);
    dispatch(setClientRentPopup(true));
    dispatch(
      setClientRentPopupData({
        ...keydata,
        text: "Tenancy Arrears",
        subtext: params.data.type,
      })
    );
  };
  const onEvents = {
    click: onChartClick,
  };

  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <>
      <div className="ClientMoneyOverview">
        <div className="row h-100">
          <div className="col-5">
            <div className="arrears_card">
              <h1>Arrears</h1>
              <div className="graph_wrapper">
                <div className="graph">
                  <ReactEcharts
                    style={{ width: "280px", height: "280px", top: "0px" }}
                    option={option}
                    onEvents={onEvents}
                  />
                </div>
              </div>
              <div className="content_wrapper">
                <ul>
                  <li
                    onClick={(e) => {
                      setPopupstate(true);
                      setActiveTab("0 - 7 Days");
                    }}
                  >
                    <span>
                      <div className="badge07"></div>
                      {"0 - 7 Days"}
                    </span>
                    <p>{`£${formatPounds(Data?.charges_0_7_days || 0)}`}</p>
                  </li>
                  <li
                    onClick={(e) => {
                      setPopupstate(true);
                      setActiveTab("8 - 14 Days");
                    }}
                  >
                    <span>
                      <div className="badge814"></div>
                      {"8 - 14 Days"}
                    </span>
                    <p>{`£${formatPounds(Data?.charges_8_14_days || 0)}`}</p>
                  </li>
                  <li
                    onClick={(e) => {
                      setPopupstate(true);
                      setActiveTab("15 - 30 Days");
                    }}
                  >
                    <span>
                      <div className="badge1530"></div>
                      {"15 - 30 Days"}
                    </span>
                    <p>{`£${formatPounds(Data?.charges_15_30_days || 0)}`}</p>
                  </li>
                  <li
                    onClick={(e) => {
                      setPopupstate(true);
                      setActiveTab("31+ Days");
                    }}
                  >
                    <span>
                      <div className="badge31"></div>
                      {"31+ Days"}
                    </span>
                    <p>{`£${formatPounds(Data?.charges_31_plus_days || 0)}`}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="overview_left">
              <h1>Business Stats</h1>
              <div className="row card_parent">
                <div className="col-6">
                  <div
                    className="card"
                    onClick={(e) => {
                      dispatch(
                        setClientRentPopupData({
                          ...keydata,
                          text: "Today’s Rents",
                          subtext: "",
                        })
                      );
                      dispatch(setClientRentPopup(true));
                    }}
                  >
                    <p>Today’s Rents</p>
                    <div className="amount">
                      <h1>£{formatPounds(todaysrent)}</h1>
                      {/* <span>
                        +11.01%<img src={arrowraise}></img>
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="card"
                    onClick={(e) => {
                      dispatch(
                        setClientRentPopupData({
                          ...keydata,
                          text: "Today's Agency Income",
                          subtext: "",
                        })
                      );
                      dispatch(setClientRentPopup(true));
                    }}
                  >
                    <p>Today's Agency Income</p>
                    <div className="amount">
                      <h1>£{formatPounds(todaysagencyincome)}</h1>
                      {/* <span>
                        +09.01%<img src={arrowraise}></img>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "30px",
                  marginTop: "50px",
                  justifyContent: "space-between",
                }}
              >
                <DateRange
                  onChange={(e) => {
                    console.log(e, "asjaaksjd");
                    setRange(e);
                  }}
                />
                <div className="date">
                  <span
                    className={period == "7days" ? "active" : ""}
                    onClick={(e) => dispatch(setClientMoneyPeriod("7days"))}
                  >
                    7 Days
                  </span>
                  <span
                    className={period == "month" ? "active" : ""}
                    onClick={(e) => dispatch(setClientMoneyPeriod("month"))}
                  >
                    Month
                  </span>
                  <span
                    className={period == "year" ? "active" : ""}
                    onClick={(e) => dispatch(setClientMoneyPeriod("year"))}
                  >
                    Year
                  </span>
                </div>
              </div>
              <div className="toggle_button">
                <button
                  className={toggle ? "active" : ""}
                  onClick={(e) => {
                    setToggle(true);
                    dispatch(setClientMoneyAgencyType("Rent"));
                  }}
                >
                  Rent
                </button>
                <button
                  className={!toggle ? "active" : ""}
                  onClick={(e) => {
                    setToggle(false);
                    dispatch(setClientMoneyAgencyType("Management Fee"));
                  }}
                >
                  Agency Income
                </button>
              </div>
              <div className="chart" style={{ position: "relative" }}>
                <ReactEcharts
                  style={{
                    width: "790px",
                    height: "400px",
                    top: "0px",
                    position: "absolute",
                  }}
                  option={optionline}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <ClientMoneyOverviewPopup
          keydata={keydata}
          todayRent={todaysrent}
          todaysAgencyIncome={todaysagencyincome}
        />
      )}
      {popupstate && (
        <>
          <ArrearsPopup
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setPopupstate={setPopupstate}
            popuptext={""}
            dataFromData={Data}
          />
        </>
      )}
    </>
  );
}
