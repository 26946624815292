import { useEffect, useState } from "react";
import "../../StyleSheet/dashboard/maintanencedashboard.scss";
import { useFetchWorkOrderNumber } from "../../../Api/homeQuery";
import { useSelector } from "react-redux";
import MaintenancePopup from "./popups/maintenancepopup";
import { addLeadingZero } from "../../../Utils/Utils";
import Chart from "react-apexcharts";

export default function MaintanenceDashboard() {
  const [Data, setData] = useState([
    { name: "New Request", count: "0" },
    { name: "Quote Requested", count: "0" },
    { name: "Awaiting Approval", count: "0" },
    { name: "Approved", count: "0" },
    { name: "Booked", count: "0" },
    { name: "Awaiting Payment", count: "0" },
  ]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [titleColor, setTitleColor] = useState("");
  const [maintenanceCount, setMaintenanceCount] = useState(0);
  const [maintenanceStage, setMaintenanceStage] = useState("");
  const [type, setType] = useState("Maintenance");
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track the hovered index

  const fetchMaintenanceCount = useFetchWorkOrderNumber({
    params: { client: clientid },
    loading: false,
  });

  useEffect(() => {
    setMaintenanceCount(
      fetchMaintenanceCount?.data?.data?.[0]?.count +
        fetchMaintenanceCount?.data?.data?.[1]?.count +
        fetchMaintenanceCount?.data?.data?.[2]?.count +
        fetchMaintenanceCount?.data?.data?.[3]?.count +
        fetchMaintenanceCount?.data?.data?.[4]?.count +
        fetchMaintenanceCount?.data?.data?.[5]?.count +
        fetchMaintenanceCount?.data?.data?.[6]?.count
    );
    setData([
      {
        name: "New Requests",
        count: fetchMaintenanceCount?.data?.data?.[0]?.count,
        color: "#7fff00",
        hoverColor: "#b3ff66",
      },
      {
        name: "Quote Requested",
        count: fetchMaintenanceCount?.data?.data?.[2]?.count,
        color: "#ffc650",
        hoverColor: "#ffd966",
      },
      {
        name: "Awaiting Approval",
        count: fetchMaintenanceCount?.data?.data?.[3]?.count,
        color: "#ff5b5c",
        hoverColor: "#ff7879",
      },
      {
        name: "Approved",
        count: fetchMaintenanceCount?.data?.data?.[4]?.count,
        color: "#54C3EF",
        hoverColor: "#7fd8f5",
      },
      {
        name: "Booked",
        count: fetchMaintenanceCount?.data?.data?.[5]?.count,
        color: "#6062F0",
        hoverColor: "#8c8df5",
      },
      {
        name: "Awaiting payment",
        count: fetchMaintenanceCount?.data?.data?.[6]?.count,
        color: "#FFD8D4",
        hoverColor: "#ffe5e2",
      },
    ]);
  }, [fetchMaintenanceCount?.data]);

  const series = [
    Math.round(
      ((fetchMaintenanceCount?.data?.data?.[0]?.count +
        fetchMaintenanceCount?.data?.data?.[1]?.count) /
        maintenanceCount) *
        100
    ),
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[2]?.count / maintenanceCount) * 100
    ),
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[3]?.count / maintenanceCount) * 100
    ),
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[4]?.count / maintenanceCount) * 100
    ),
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[5]?.count / maintenanceCount) * 100
    ),
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[6]?.count / maintenanceCount) * 100
    ),
  ];
  const options = {
    chart: {
      type: "radialBar",
    },
    colors: Data.map((item, index) =>
      index === hoveredIndex ? item.hoverColor : item.color
    ),
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: "25%",
        },
        track: {
          show: true,
          background: "#D8D8D8",
          strokeWidth: "100%",
          opacity: 1,
          margin: 6, // Gap between bars
        },
        dataLabels: {
          show: false,
          total: {
            show: hoveredIndex !== null,
            label: "",
            formatter: () => {
              if (hoveredIndex !== null) {
                console.log(Data[hoveredIndex]?.count, "klakjsldjkflajsdlfkj");
                return <p>{Data[hoveredIndex]?.count}</p>;
                // const value =
                //   (Data[hoveredIndex]?.count / maintenanceCount) * 100;
                // return `${Math.trunc(value)}%`;
              }
              return "";
            },
            color: hoveredIndex !== null ? Data[hoveredIndex].color : "#000",
          },
        },
      },
    },
    labels: [
      "New Requests",
      "Quote Requested",
      "Awaiting Approval",
      "Approved",
      "Booked",
      "Awaiting Payment",
    ],
  };

  // const options = {
  //   chart: {
  //     type: "radialBar",
  //   },
  //   colors: Data.map((item, index) =>
  //     index === hoveredIndex ? item.hoverColor : item.color
  //   ),
  //   plotOptions: {
  //     radialBar: {
  //       hollow: {
  //         margin: 5,
  //         size: "25%",
  //       },
  //       track: {
  //         show: true,
  //         background: "#D8D8D8",
  //         strokeWidth: "100%",
  //         opacity: 1,
  //         margin: 1,
  //       },
  //       dataLabels: {
  //         show: true,
  //         name: {
  //           fontSize: "16px",
  //           color: hoveredIndex !== null ? Data[hoveredIndex].color : "#000",
  //         },
  //         value: {
  //           fontSize: "16px",
  //           show: hoveredIndex !== null,
  //           color: hoveredIndex !== null ? Data[hoveredIndex].color : "#000",
  //         },
  //         total: {
  //           show: hoveredIndex !== null,
  //           label: hoveredIndex !== null ? Data[hoveredIndex]?.name : "",
  //           formatter: () => {
  //             return hoveredIndex !== null
  //               ? `${Data[hoveredIndex]?.count}`
  //               : "";
  //           },
  //           color: hoveredIndex !== null ? Data[hoveredIndex].color : "#000",
  //         },
  //       },
  //     },
  //   },
  //   labels: [
  //     "New Requests",
  //     "Quote Requested",
  //     "Awaiting Approval",
  //     "Approved",
  //     "Booked",
  //     "Awaiting Payment",
  //   ],
  // };

  return (
    <>
      <div
        className="MaintanenceDash_wrapper_main"
        style={{ position: "relative" }}
      >
        <div className="arrear_header" style={{ padding: "23px 0 0 26px" }}>
          <h1
            className={type === "Maintenance" && "active"}
            onClick={() => {
              setType("Maintenance");
            }}
            style={{
              justifyContent: "flex-start",
              fontSize: "32px",
            }}
          >
            Maintenance
          </h1>
          {/* <h1
            className={type === "Compliance" && "active"}
            // onClick={() => {
            //   setType("Compliance");
            // }}
            style={{ cursor: "default" }}
          >
            Compliance
          </h1> */}
        </div>
        <div
          className="MaintanenceDash_graph_wrapper"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className="graph"
            style={{ position: "absolute", top: "25%", left: "-18px" }}
          >
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height={"400px"}
              width={"300px"}
            />
          </div>
          <ul style={{ width: "54%" }}>
            {Data.map((item, index) => {
              return (
                <li
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)} // Set the hovered index on mouse enter
                  onMouseLeave={() => setHoveredIndex(null)} // Reset the hovered index on mouse leave
                  onClick={() => {
                    if (item.name !== "") {
                      setMaintenancePopup(true);
                      setMaintenanceStage(item.name);
                      setTitleColor(item.color);
                    }
                  }}
                >
                  <span>
                    <div className={`badge${index + 1}`}></div>
                    {item.name}
                  </span>
                  <p>{addLeadingZero(item.count)}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {maintenancePopup && (
        <MaintenancePopup
          setPopup={setMaintenancePopup}
          maintenanceStage={maintenanceStage}
          titleColor={titleColor}
        />
      )}
    </>
  );
}
