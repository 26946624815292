import React, { useEffect, useState } from "react";
import "./ClientCreate.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import blTopLeft from "../../Assets/Common/bl_TopLeft.svg";
import invalidLink from "../../Assets/Common/invalidLink.svg";
import bl_BottomRight from "../../Assets/Common/bl_BottomRight.svg";
import solace_Logo from "../../Assets/Common/solace_Logo.svg";
import success_check from "../../Assets/Common/success_check.svg";
import { PasswordInput } from "../GlobalComponents/othercomponents/PasswordInput";
import axios from "axios";
import { useSelector } from "react-redux";
import { useCreateInvite, useLinkValidation } from "../../Api/homeQuery";

// Component for creating a new SuperAdmin account
function ClientCreate() {
  // Get the id from the URL parameters
  const { id } = useParams();
  const token = useSelector((state) => state.counter.token);
  // Set up state for the password and confirm password inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateLink = useLinkValidation({
    params: { type: "client", id: id },
  });

  // Set up state for tracking whether the account creation was successful
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [validateUrl, setValidateUrl] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  // Set up navigation for redirecting to the login page
  const navigate = useNavigate();

  const handleCreateInvite = useCreateInvite({
    data: {
      password: password,
      is_active: true,
    },
    setAccountSuccess: setAccountSuccess,
    id: id,
  });

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 16;
    const numberRegex = /\d/;
    const capitalLetterRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    // Check length
    if (password.length < minLength || password.length > maxLength) {
      return "Password must be between 8 and 16 characters long.";
    }

    // Check for at least one number
    if (!numberRegex.test(password)) {
      return "Password must contain at least one number.";
    }

    // Check for at least one capital letter
    if (!capitalLetterRegex.test(password)) {
      return "Password must contain at least one capital letter.";
    }

    // Check for at least one special character
    if (!specialCharRegex.test(password)) {
      return "Password must contain at least one special character.";
    }

    // Password meets all conditions
    return "Password is valid.";
  };

  // Validate the password inputs and create the new SuperAdmin account
  const validate = () => {
    if (password === "" || confirmPassword === "") {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const response = validatePassword(confirmPassword);
    if (response === "Password is valid.") {
      console.log("Response:", response);
      handleCreateInvite.mutate();
    } else {
      toast.error(response);
    }
  };

  useEffect(() => {
    console.log(validateLink?.error, "lakmslfdja");
    if (
      validateLink?.error &&
      validateLink.error.includes("400") &&
      firstTime
    ) {
      setValidateUrl(true);
      setFirstTime(false);
    }
  }, [validateLink]);

  return (
    <>
      {!validateUrl ? (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate">
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>
            {!accountSuccess && (
              <div className="textSection">
                <p>
                  Register now for a personalized experience and stay connected
                  with our latest updates.
                </p>
              </div>
            )}
            {accountSuccess && (
              <div className="successSection">
                <img src={success_check} alt="success_check" />
                <p style={{ textAlign: "center" }}>
                  <strong style={{ textAlign: "center" }}>
                    Your Account has been Created Successfully
                  </strong>
                </p>
              </div>
            )}

            <div className="mainPasswordSection">
              {accountSuccess ? (
                <p
                  style={{
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "35px",
                  }}
                >
                  Your account has been created successfully. Welcome aboard!
                  You now have access to all our features and updates. Enjoy
                  your experience!
                </p>
              ) : (
                <>
                  {/* <PasswordInput
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  name="password"
                /> */}
                  <PasswordInput
                    name="password"
                    type="text"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <PasswordInput
                    name="confPassword"
                    type="text"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  {/* <PasswordInput
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Confirm Password"
                  name="confPassword"
                /> */}
                </>
              )}
            </div>
            <div className="footerSection">
              {accountSuccess ? (
                <button
                  className="upload_button"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <span>Login</span>&nbsp;
                </button>
              ) : (
                <button
                  className="upload_button"
                  onClick={() => {
                    validate();
                  }}
                >
                  <span>Submit</span>&nbsp;
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate" style={{ minHeight: "200px" }}>
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>

            <div className="successSection">
              <img src={invalidLink} alt="success_check" />
              <p style={{ textAlign: "center" }}>
                <strong style={{ textAlign: "center" }}>Link Expired</strong>
              </p>
            </div>

            <div className="mainPasswordSection">
              <p
                style={{
                  color: "black",
                  fontSize: "16px",
                  lineHeight: "35px",
                }}
              >
                Your link has expired. If you encounter any issues, please
                contact the respective property manager for further assistance.{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { ClientCreate };

// Import necessary libraries, modules, and components
// Get the id from the URL parameters using react-router's useParams hook
// Set up state for the password and confirm password inputs
// Set up state for tracking whether the account creation was successful
// Set up navigation for redirecting to the login page
// Handle changes to the password input
// Handle changes to the confirm password input
// Set up the mutation for creating a new SuperAdmin account
// Validate the password inputs and create the new SuperAdmin account
// Display the password creation form and success message
// Display error messages using the Sonner toast library
