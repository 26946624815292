// import "./style.scss";
// import { useEffect, useState } from "react";
// import {
//   createColumnHelper,
//   flexRender,
//   getCoreRowModel,
//   useReactTable,
//   getSortedRowModel,
// } from "@tanstack/react-table";
// import { Pagination } from "antd";
// import { ConfigProvider } from "antd";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import tablesorting from "../../../Assets/Common/tablesorting.svg";
// import up from "../../../Assets/Common/up.svg";
// import down from "../../../Assets/Common/down.svg";
// export default function TableComponent(props) {
//   const {
//     addons = [],
//     filterList,
//     selectedFilter,
//     setSelectedFilter,
//     count,
//     pageindex,
//     pagesize,
//     setPageSize,
//     setPageIndex,
//   } = props;

//   const [sorting, setSorting] = useState([]);

//   const [data, setData] = useState(() => [...props.data]);
//   const columns = props.columns;
//   console.log(props.data, "jhfdkjhfkjkhd");
//   useEffect(() => {
//     setData(props.data);
//   }, [props.data, columns]);

//   const table = useReactTable({
//     data,
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     state: {
//       sorting,
//       pageIndex: pageindex,
//       pageSize: pagesize,
//     },
//     onSortingChange: setSorting,
//     debugTable: true,
//   });

//   console.log(table.getState().sorting, "akjfhdjhakjhkjhkdsa");

//   return (
//     <>
//       {addons.includes("Filters") && (
//         <div className="filterDiv">
//           {filterList.map((filter, index) => (
//             <span
//               key={index}
//               className={`mainSpan ${
//                 selectedFilter === filter.value && "active"
//               }`}
//               onClick={() => setSelectedFilter(filter.value)}
//             >
//               {filter.label}
//               <span
//                 className="subSpan"
//                 style={{ background: filter.background }}
//               >
//                 {filter.count}
//               </span>
//             </span>
//           ))}
//         </div>
//       )}
//       <div className="main_table" style={{ padding: "0px 26px" }}>
//         <table className="custom-table">
//           <thead>
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id}>
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     key={header.id}
//                     colSpan={header.colSpan}
//                     style={{ fontWeight: "500" }}
//                   >
//                     {header.isPlaceholder ? null : (
//                       <div
//                         className={
//                           header.column.getCanSort()
//                             ? "cursor-pointer select-none"
//                             : ""
//                         }
//                         style={{
//                           display: "flex",
//                           gap: "10px",
//                           cursor: header.column.getCanSort()
//                             ? "pointer"
//                             : "auto",
//                           fontSize: "16px",
//                         }}
//                         onClick={header.column.getToggleSortingHandler()}
//                         title={
//                           header.column.getCanSort()
//                             ? header.column.getNextSortingOrder() === "asc"
//                               ? "Sort ascending"
//                               : header.column.getNextSortingOrder() === "desc"
//                               ? "Sort descending"
//                               : "Clear sort"
//                             : undefined
//                         }
//                       >
//                         {flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                         {{
//                           asc: (
//                             <img
//                               src={up}
//                               style={{ width: "11px" }}
//                               alt="up arrow"
//                             />
//                           ),
//                           desc: (
//                             <img
//                               src={down}
//                               style={{ width: "11px" }}
//                               alt="down arrow"
//                             />
//                           ),
//                         }[header.column.getIsSorted()] ??
//                           (header.column.getCanSort() && (
//                             <img src={tablesorting} alt="sort icon" />
//                           ))}
//                       </div>
//                     )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody className="custom-table-body">
//             {table.getRowModel().rows.map((row) => (
//               <tr
//                 key={row.id}
//                 onClick={() =>
//                   props?.onclickrow && props.onclickrow(row.original)
//                 }
//                 style={{ cursor: props?.onclickrow ? "pointer" : "auto" }}
//               >
//                 {row.getVisibleCells().map((cell) => (
//                   <td key={cell.id}>
//                     {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             {table.getFooterGroups().map((footerGroup) => (
//               <tr key={footerGroup.id}>
//                 {footerGroup.headers.map((header) => (
//                   <th key={header.id}>
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.footer,
//                           header.getContext()
//                         )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </tfoot>
//         </table>
//       </div>
//       {count > 10 && (
//         <div className="pagination">
//           <FormControl
//             sx={{ m: 1, minWidth: 150 }}
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "15px",
//             }}
//           >
//             <span>Show entries</span>
//             <Select
//               value={pagesize}
//               onChange={(e) => setPageSize(e.target.value)}
//               displayEmpty
//               inputProps={{ "aria-label": "Without label" }}
//               sx={{
//                 width: "70px",
//                 height: "40px",
//                 borderRadius: "5px",
//               }}
//             >
//               <MenuItem value={10}>10</MenuItem>
//               <MenuItem value={20}>20</MenuItem>
//               <MenuItem value={30}>30</MenuItem>
//             </Select>
//           </FormControl>
//           <ConfigProvider
//             theme={{
//               components: {
//                 Pagination: {
//                   itemSize: 50,
//                 },
//               },
//             }}
//           >
//             <Pagination
//               defaultCurrent={1}
//               total={count}
//               showSizeChanger={false}
//               onChange={(e) => setPageIndex(e)}
//             />
//           </ConfigProvider>
//         </div>
//       )}
//     </>
//   );
// }

import "./style.scss";
import { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Pagination } from "antd";
import { ConfigProvider } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import tablesorting from "../../../Assets/Common/tablesorting.svg";
import up from "../../../Assets/Common/up.svg";
import down from "../../../Assets/Common/down.svg";
import CustomCheckButtons from "../CustomCheckButtons";

export default function TableComponent(props) {
  const {
    addons = [],
    filterList,
    selectedFilter,
    setSelectedFilter,
    count,
    pageindex,
    pagesize,
    setPageSize,
    setPageIndex,
    checkall,
    checked = false,
  } = props;

  const [sorting, setSorting] = useState([]);

  const [data, setData] = useState(() => [...props.data]);
  const columns = props.columns;
  console.log(props.data, "jhfdkjhfkjkhd");

  useEffect(() => {
    setData(props.data);
  }, [props.data, columns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: pageindex,
        pageSize: pagesize,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: ({ pageIndex, pageSize }) => {
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    },
    debugTable: true,
  });

  console.log(table.getState().sorting, "akjfhdjhakjhkjhkdsa");
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      {addons.includes("Filters") && (
        <div className="filterDiv">
          {filterList.map((filter, index) => (
            <span
              key={index}
              className={`mainSpan ${
                selectedFilter === filter.value && "active"
              }`}
              onClick={() => setSelectedFilter(filter.value)}
            >
              {filter.label}
              <span
                className="subSpan"
                style={{ background: filter.background }}
              >
                {filter.count}
              </span>
            </span>
          ))}
        </div>
      )}
      <div className="main_table" style={{ padding: "0px 26px" }}>
        <table className="custom-table" style={{ position: "relative" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ fontWeight: "500" }}
                  >
                    {header.id === "checkedRows" && (
                      <div
                        style={{
                          display: "flex",
                          gap: "31px",
                          flexDirection: "row-reverse",
                          width: "36%",
                          marginLeft: "25px",
                        }}
                      >
                        <CustomCheckButtons
                          setIsChecked={setIsChecked}
                          isChecked={isChecked}
                          onChange={(e) => {
                            checkall(table.getRowModel().rows, e);
                          }}
                        />
                      </div>
                    )}
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }
                        style={{
                          display: "flex",
                          gap: "10px",
                          cursor: header.column.getCanSort()
                            ? "pointer"
                            : "auto",
                          fontSize: "16px",
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <img
                              src={up}
                              style={{ width: "11px", height: "18px" }}
                              alt="up arrow"
                            />
                          ),
                          desc: (
                            <img
                              src={down}
                              style={{ width: "11px", height: "18px" }}
                              alt="down arrow"
                            />
                          ),
                        }[header.column.getIsSorted()] ??
                          (header.column.getCanSort() && (
                            <img
                              src={tablesorting}
                              alt="sort icon"
                              style={{ width: "11px", height: "18px" }}
                            />
                          ))}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getRowModel().rows.length > 0 ? (
            <tbody className="custom-table-body">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() =>
                    props?.onclickrow && props.onclickrow(row.original)
                  }
                  style={{ cursor: props?.onclickrow ? "pointer" : "auto" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "0%",
                top: "138px",
                width: "100%",
              }}
            >
              <p style={{ fontSize: "24px" }}>No Data Available</p>
            </div>
          )}
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {count > 10 && (
        <div className="pagination">
          <FormControl
            sx={{ m: 1, minWidth: 150 }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <span>Show entries</span>
            <Select
              value={pagesize}
              onChange={(e) => setPageSize(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "70px",
                height: "40px",
                borderRadius: "5px",
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  itemSize: 50,
                },
              },
            }}
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              showSizeChanger={false}
              onChange={(e) => setPageIndex(e)}
            />
          </ConfigProvider>
        </div>
      )}
    </>
  );
}
