import React, { useMemo, useState, useEffect } from "react";
import "../../StyleSheet/Maintenance/maintenancearchive.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import arrow from "../../../Assets/Common/Frame 427319102.svg";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import eximg from "../../../Assets/Common/property_eximg.png";
import eximg1 from "../../../Assets/Common/property_eximg1.png";
import Search from "../../../MainComponent/fields/search";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import { useFetchMaintenanceArchive } from "../../../Api/MaintenanceQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatAddress, formatPounds } from "../../../Utils/Utils";
// import ""
const MaintenanceArchive = () => {
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [switcher, setswitcher] = useState(false);
  const [popup, setpopup] = useState(false);
  const [rowvalue, setrowvalue] = useState([]);
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const fetchMaintenanceData = useFetchMaintenanceArchive({
    id: loginuserdata.id,
    status: "Resolved",
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "date",
        sortingFn: "basic",
        cell: (info) => (
          <p style={{ fontSize: "18px" }}>
            {dayjs(info.row.original.created_date).format("DD/MM/YYYY")}
          </p>
        ),
        header: () => <span>Date</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "address",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.property;
          return (
            <p style={{ fontSize: "18px" }}>
              {formatAddress([
                data.name,
                data.street,
                data.city,
                data.postcode,
              ])}
            </p>
          );
        },
        header: () => <span>Address</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "summary",
        enableSorting: false,
        cell: (info) => {
          return (
            <p style={{ fontSize: "18px" }}>{info.row.original.description}</p>
          );
        },
        header: () => <span>Summary</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "amount",
        sortingFn: "basic",
        cell: (info) => {
          return (
            <p style={{ fontSize: "18px" }}>
              £{formatPounds(info.row.original.invoice_amount)}
            </p>
          );
        },
        header: () => <span>Amount</span>,
      }),
    ],
    []
  );
  const clickrow = (row) => {
    console.log(row);
    setpopup(true);
    setrowvalue(row);
  };
  useEffect(() => {
    // Ensure fetchTableData.data exists and has status_count
    const statusCount = fetchMaintenanceData?.data?.data?.status_count;

    if (statusCount) {
      setTableData(fetchMaintenanceData?.data?.data?.results);
      setCount(fetchMaintenanceData?.data?.data?.count);
    }
  }, [fetchMaintenanceData.data]);
  return (
    <div className="maintenace_archive">
      <div className="maintenance_content_wrapper">
        <div className="header">
          <img src={logo}></img>
          <h1>Maintenance History</h1>
        </div>
        <div className="maintenace_log_wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "40px",
              padding: "0 24px",
            }}
          >
            <div style={{ display: "flex", gap: "15px" }}>
              <Search setsearch={setsearch} style={{ width: "315px" }} />
              <DateRange />
            </div>
            <button
              style={{
                border: "none",
                padding: "10px 50px",
                borderRadius: "5px",
                background: "rgba(129, 126, 255, 1)",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                gap: "5px",
              }}
            >
              Generate Report
              <img src={arrow} alt="arrow" width={"12px"} />
            </button>
          </div>
          <div className="main_content">
            <TableComponent
              data={tableData}
              columns={columns}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceArchive;
