import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useTodaysRentAgency(payload, enabled) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["TodaysRentAgency", payload.params.id, payload.params.type],
    queryFn: async () => {
      const uploadpayload = {
        params: { client: payload.params.id, type: payload.params.type },
      };

      const response = handleResponse(
        "accounts/today_agency_rent/v1/",
        "get",
        uploadpayload,
        false
      );
      return response;
    },
    enabled,
    placeholderData: keepPreviousData,
  });
}

export function useArrearsSummary(payload, enabled) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["ArrearsSummary", payload.params.id, payload.params.date_range],
    queryFn: async () => {
      const uploadpayload = {
        params: {
          client: payload.params.id,
          date_range: payload.params.date_range,
        },
      };

      const response = handleResponse(
        "accounts/arrears_summary/v1/",
        "get",
        uploadpayload
      );
      return response;
    },
    enabled,
    placeholderData: keepPreviousData,
  });
}

export function useBussinessStatus(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "bussinessstatus",
      payload.params.id,
      payload.params.period,
      payload.params.from_date,
      payload.params.to_date,
      payload.params.type,
    ],
    queryFn: async () => {
      const uploadpayload = {
        params: {
          client: payload.params.id,
          period: payload.params.period,
          from_date: payload.params.from_date,
          to_date: payload.params.to_date,
          type: payload.params.type,
        },
      };

      const response = handleResponse(
        "accounts/bussiness_status/v1/",
        "get",
        uploadpayload,
        true
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useClientAccountDetails(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "clientaccountdetails",
      payload.params.client,
      payload.params.page,
      payload.params.page_size,
    ],
    queryFn: async () => {
      const uploadpayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "accounts/client_account/v1/",
        "get",
        uploadpayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useReserveAccountDetails(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "reserveAccountDetails",
      payload.params.client,
      payload.params.page,
      payload.params.page_size,
    ],
    queryFn: async () => {
      const uploadpayload = {
        params: {
          client: payload.params.client,
          page: payload.params.page,
          page_size: payload.params.page_size,
        },
      };
      const response = handleResponse(
        "accounts/client_account/v1/",
        "get",
        uploadpayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchAccounts(payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey: [
      "AccountReconcileData",
      payload.params.client,
      payload.params.type,
    ],
    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: payload.params?.client,
          type: payload.params.type,
        },
      };
      const response = await handleResponse(
        "accounts/accounts/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    onSuccess: () => {
      // payload.handleSuccess();
    },
    placeholderData: true, // This keeps the previous data while fetching new data
  });
}
export function useFetchReserveAccount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "reserveAccount",
      payload.params.client,
      payload.params.page,
      payload.params.page_size,
    ],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "transaction/restransaction_recipt/v1",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useDepositeAccountDetails(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "depositeAccountDetails",
      payload.params.client,
      payload.params.account,
      payload.params.page,
      payload.params.page_size,
    ],
    queryFn: async () => {
      if (payload.params.account) {
        const uploadpayload = {
          params: payload.params,
        };
        const response = handleResponse(
          "accounts/deposit_account/v1/",
          "get",
          uploadpayload,
          false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}
export function usePaymentsDue(payload) {
  const { handleResponse } = useResponseHandler();
  const data = payload.params;

  return useQuery({
    queryKey: [
      "paymentsDue",
      payload,
      // payload.params.page,
      // payload.params.page_size,
    ],
    queryFn: async () => {
      const uploadpayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "accounts/current_month_accounts",
        "get",
        uploadpayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
