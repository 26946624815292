const formatPounds = (amount) => {
  const value = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
  if (!value) {
    return 0;
  } else {
    return value;
  }
};
// function formatAddress(addressParts) {
//   return addressParts.filter((part) => part).join(", ");
// }

function formatAddress(addressParts) {
  const value = addressParts.filter((part) => part).join(", ");
  if (value == NaN || value == undefined) {
    return 0;
  } else {
    return value;
  }
}

const addLeadingZero = (num) => {
  return num === 0 ? 0 : num < 10 ? `0${num}` : `${num}`;
};
const formatPoundsNoDec = (amount) => {
  const value = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
  if (value == NaN || value == undefined) {
    return 0;
  } else {
    return value;
  }
};
function findIndexByType(array, type) {
  const index = array.findIndex((item) => item.document_type == type);
  return index !== -1 ? index : "not available";
}

function formatUKTelephone(number) {
  const cleaned = number.replace(/\D/g, "");
  const countryCode = "+44 ";
  if (cleaned.length === 11 && cleaned.startsWith("07")) {
    return (
      countryCode + cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "7$1 $2 $3")
    );
  } else if (cleaned.length === 11 && cleaned.startsWith("02")) {
    return countryCode + cleaned.replace(/(\d{2})(\d{4})(\d{4})/, "20 $1 $2");
  } else if (cleaned.length === 10 && cleaned.startsWith("01")) {
    return countryCode + cleaned.replace(/(\d{2})(\d{4})(\d{6})/, "1$1 $2");
  } else if (cleaned.length === 10 && cleaned.startsWith("03")) {
    return (
      countryCode + cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "3$1 $2 $3")
    );
  } else {
    return number;
  }
}
const formatName = (title, first_name, last_name) => {
  const formattedTitle = title ? `${title} ` : "";
  const formattedFirstName = first_name ? `${first_name} ` : "";
  const formattedLastName = last_name || "";

  return `${formattedTitle}${formattedFirstName}${formattedLastName}`.trim();
};

export {
  formatPounds,
  formatAddress,
  addLeadingZero,
  formatPoundsNoDec,
  findIndexByType,
  formatUKTelephone,
  formatName,
};
