// import password_eye from "../../../Assets/Common/password_eye.svg";
// import React, { useState } from "react";

// const PasswordInput = ({ value, onChange, placeholder, name }) => {
//   const [isPasswordVisible, setPasswordVisible] = useState(false);

//   const togglePasswordVisibility = () => {
//     setPasswordVisible((prev) => !prev);
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//         width: "100%",
//         background: "rgba(217, 217, 217, 0.4)",
//         borderRadius: "10px",
//         padding: "19px 18px",
//         height: "60px",
//       }}
//     >
//       <input
//         type={isPasswordVisible ? "text" : "password"}
//         value={value}
//         onChange={onChange}
//         placeholder={placeholder}
//         name={name}
//         style={{
//           background: "transparent",
//           border: "none",
//           width: "100%",
//         }}
//         className="password_field"
//       />
//       <img
//         src={password_eye}
//         alt="password_eye"
//         onClick={togglePasswordVisibility}
//         style={{ cursor: "pointer" }}
//       />
//     </div>
//   );
// };

// export { PasswordInput };

import password_eye from "../../../Assets/Common/password_eye.svg";
import hide_password_eye from "../../../Assets/Common/eye-password-hide-svgrepo-com.svg";
import React, { useState } from "react";

const PasswordInput = ({ value, onChange, placeholder, name }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        background: "rgba(217, 217, 217, 0.4)",
        borderRadius: "7px",
        padding: "4px 8px",
        height: "50px",
        border: "1px solid lightgrey",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        background: "rgba(217, 217, 217, 0.4)",
        borderRadius: "10px",
        padding: "19px 18px",
        height: "60px",
        border: "none",
      }}
    >
      <input
        className="customPasswordButton"
        type={isPasswordVisible ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        style={{
          // borderTop: "1px solid lightgrey",
          // borderBottom: "1px solid lightgrey",
          // borderLeft: "none",
          // borderRight: "none",
          color: "black",
          border: "none",
          padding: "5px 0 5px 0",
          background: "rgba(217, 217, 217, 0.4)",
          background: "transparent",
          border: "none",
          width: "100%",
        }}
        autoComplete="off"
      />
      <img
        src={isPasswordVisible ? password_eye : hide_password_eye}
        alt="password_eye"
        onClick={togglePasswordVisibility}
        style={{ cursor: "pointer" }}
        width={"25px"}
        height={"25px"}
      />
    </div>
  );
};

export { PasswordInput };
