import "../../../StyleSheet/Maintenance/popups/maintenanceoverviewpopup.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper, sortingFns } from "@tanstack/react-table";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { useEffect, useMemo, useState } from "react";
import {
  useArrearsSummary,
  useReserveAccountDetails,
} from "../../../../Api/clientmonetQuery";
import { useTodaysRentAgency } from "../../../../Api/clientmonetQuery";

import { useDispatch, useSelector } from "react-redux";
// import { useQuery } from '@tanstack/react-query';
import download from "../../../../Assets/Common/Download.svg";
import { setClientRentPopup } from "../../../../redux/reducer/counterslice";
import { formatPounds } from "../../../../Utils/Utils";

export default function ClientMoneyOverviewPopup(props) {
  const { todayRent } = props;
  const columnHelper = createColumnHelper();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [tabledata, settabledata] = useState([]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [fetchType, setFetchType] = useState(null);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.keydata?.text === "Today’s Rents") {
      setFetchType("Today’s Rents");
    } else if (props?.keydata?.text === "Today's Agency Income") {
      setFetchType("Today's Agency Income");
    } else if (props?.keydata?.subtext !== "") {
      setFetchType("Arrears");
    }
  }, [props?.keydata]);
  useEffect(() => {
    console.log(todayRent, "laksjlfjalsjdfljalsjdf");
  }, [todayRent]);

  const getAgencyRent = useTodaysRentAgency(
    { params: { id: clientid, type: "Rent" } },
    fetchType === "Today’s Rents"
  );

  const getAgencyManagementFee = useTodaysRentAgency(
    { params: { id: clientid, type: "Management Fee" } },
    fetchType === "Today's Agency Income"
  );

  const getArrears = useArrearsSummary(
    { params: { id: clientid, date_range: props?.keydata?.subtext } },
    fetchType === "Arrears"
  );

  useEffect(() => {
    if (fetchType === "Today’s Rents" && getAgencyRent.data) {
      console.log(getAgencyRent.data, "getAgencyRent");
      settabledata(getAgencyRent.data.data);
    } else if (
      fetchType === "Today's Agency Income" &&
      getAgencyManagementFee.data
    ) {
      settabledata(getAgencyManagementFee.data.data);
    } else if (fetchType === "Arrears" && getArrears.data) {
      settabledata(getArrears.data.data);
    }
  }, [
    fetchType,
    getAgencyRent.data,
    getAgencyManagementFee.data,
    getArrears.data,
  ]);
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "Tenant_name",
        header: () => <span>Tenant Name</span>,
        cell: (info) => <span>Test Tenant</span>,
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "description",
        header: "Description",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>
            {info.row.original.description}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "amount",
        header: "Amount",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>
            £{formatPounds(info.row.original.amount)}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "download",
        enableSorting: false,
        header: "Download",
        cell: (info) => (
          <div style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
            <p style={{ color: "#817EFF" }}>Receipts</p>
            <img src={download}></img>
          </div>
        ),
      }),
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      columnHelper.accessor("description", {
        header: () => <span>Property Address</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      // columnHelper.accessor("pre_desc", {
      //   header: "Tenant",
      //   cell: (info) => info.renderValue(),
      // }),
      columnHelper.accessor("tenant_name", {
        header: "Tenant Name",
        cell: (info) => {
          console.log(info.row.original, "LKJlasjldfjklkjaslf");
          return (
            <span style={{ wordBreak: "break-all" }}>{info.getValue()}</span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "amount",
        header: "Amount",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>
            £{formatPounds(info.row.original.amount)}
          </span>
        ),
      }),
    ],
    []
  );

  const columns3 = useMemo(
    () => [
      columnHelper.accessor("type", {
        header: () => <span>Property Address</span>,
        cell: (info) => {
          console.log(info.row.original, "afjdhfkjhfks");
          var address = [];
          if (info.row.original.type == "property") {
            address = [
              info.row.original.id.name,
              info.row.original.id.street,
              info.row.original.id.city,
              info.row.original.id.postcode,
            ]
              .filter(Boolean)
              .join(", ");
          } else {
            address = [
              info.row.original.id.property.name,
              info.row.original.id.property.street,
              info.row.original.id.property.city,
              info.row.original.id.property.postcode,
            ]
              .filter(Boolean)
              .join(", ");
          }
          return <span>{address}</span>;
        },
      }),
      columnHelper.accessor("type", {
        header: "Name",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("total_arrears", {
        header: "Amount",
        cell: (info) => (
          <span style={{ wordBreak: "break-all" }}>£ {info.getValue()}</span>
        ),
      }),
    ],
    []
  );

  return (
    <div className="popup_main_wrapper">
      <div className="main_popup">
        <div className="header">
          <div className="content">
            <h1>
              {props?.keydata?.text}{" "}
              {props?.keydata?.text === "Today’s Rents"
                ? props?.todayRent && (
                    <span style={{ color: "#817EFF" }}>
                      £{formatPounds(props?.todayRent)}{" "}
                    </span>
                  )
                : props?.todaysAgencyIncome && (
                    <span style={{ color: "#817EFF" }}>
                      £{formatPounds(props?.todaysAgencyIncome)}{" "}
                    </span>
                  )}
              {props?.keydata?.subtext && (
                <span>
                  {props?.keydata?.subtext}
                  <span style={{ color: "#817EFF" }}>{props?.todayRent}</span>
                </span>
              )}
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => dispatch(setClientRentPopup(false))}
                src={closebutton}
              />
            </div>
          </div>
        </div>
        <div className="main_content">
          <TableComponent
            columns={
              props?.keydata?.text == "Today’s Rents"
                ? columns
                : props?.keydata?.text == "Today's Agency Income"
                ? columns2
                : columns3
            }
            data={tabledata}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
}
