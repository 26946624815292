import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchComplienceSelected } from "../../../../Api/homeQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import download from "../../../../Assets/Common/Download.svg";

export default function CompliancePopup(props) {
  const { activeTab, setActiveTab, selecteddata } = props;
  const columnHelper = createColumnHelper();
  const [TableData, setTableData] = useState([]);
  const [paramsPayload, setParamsPayload] = useState("all");
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);

  const getcomplience = useFetchComplienceSelected({
    client: clientid,
    id: selecteddata.document_id,
    type: selecteddata.type,
    status: paramsPayload,
    page: pageindex,
    page_size: pagesize,
  });

  const setParams = () => {
    if (activeTab === "Not Due") {
      setParamsPayload("not_due");
    } else if (activeTab === "Due") {
      setParamsPayload("due");
    } else if (activeTab === "Overdue") {
      setParamsPayload("overdue");
    } else {
      setParamsPayload("all");
    }
  };

  useEffect(() => {
    console.log("Active Tab changed:", activeTab);
    setParams();
  }, [activeTab]);

  useEffect(() => {
    console.log("Fetching data with paramsPayload:", paramsPayload);
    if (getcomplience.data) {
      setTableData(getcomplience.data.data.results);
      setCount(getcomplience.data?.data?.count);
    }
  }, [getcomplience.data, paramsPayload, pageindex, pagesize]);

  const columns = useMemo(
    () => [
      // columnHelper.accessor("uploaded_at", {
      //   header: "Uploaded At",
      //   cell: (info) => dayjs(info.renderValue()).format("DD/MM/YYYY"),
      // }),
      columnHelper.accessor("expiry_date", {
        header: "Expiry Date",
        cell: (info) => dayjs(info.renderValue()).format("DD/MM/YYYY"),
      }),

      columnHelper.accessor("property_address", {
        header: "Property Address",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <span
            style={{
              textTransform: "capitalize",
              background:
                info.getValue() === "not_due"
                  ? "#DBF8E7"
                  : info.getValue() === "due"
                  ? "#FFF0CC"
                  : info.getValue() === "overdue"
                  ? "#FFD8D4"
                  : "",
              padding: "8px 10px",
              borderRadius: "8px",
              width: "135px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {info.getValue() === "not_due" ? "Not Due" : info.getValue()}
          </span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "Document",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <p
              className="download"
              style={{
                color: "#817EFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(data.file?.[0], "_blank");
              }}
            >
              Download&nbsp;&nbsp;
              <img
                src={download}
                width={"15px"}
                height={"15px"}
                alt="document image"
                style={{ position: "relative", top: "-1.2px" }}
              />
            </p>
          );
        },
        header: () => <span>Document</span>,
      }),
    ],
    []
  );

  return (
    <div className="popup_main_wrapper">
      <div className="main_popup">
        <div className="header">
          <div className="content">
            <h1>
              {props?.popuptext}:
              <span
                style={{
                  color:
                    props?.activeTab === "Overdue"
                      ? "rgb(255,58,41)"
                      : props?.activeTab === "Due"
                      ? "rgb(255,178,0)"
                      : props?.activeTab === "Not Due" && "rgb(77,222,135)",
                }}
              >
                {props?.activeTab}
              </span>
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => {
                  props.setPopupstate(false);
                  setTableData([]);
                  setActiveTab("All");
                  setParamsPayload("all");
                }}
                src={closebutton}
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "40px",
              background: "rgb(247,249,251)",
              borderRadius: "15px",
            }}
          >
            <FilterPanel
              tabs={["All", "Not Due", "Due", "Overdue"]}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
        </div>
        <div
          className="complianceConfig"
          style={{ position: "relative", top: "-36px" }}
        >
          <TableComponent
            columns={columns}
            data={TableData}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
}
