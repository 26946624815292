import "../../../StyleSheet/Property/popups/propertydetails.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import dummy1 from "../../../../Assets/dummy/dummy1.svg";
import Money from "../../../../Assets/Common/Money.svg";

import { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../../MainComponent/fields/search";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import {
  useFetchArchive_document,
  useFetchDetails,
  useFetchMaintenancePropertySpecific,
  useFetchPropertiesCompliance,
  useFetchPropertyTransaction,
} from "../../../../Api/PropertiesQuery";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";
import PropertyPropertyContacts from "./PropertyPropertyContacts";
import UploadPropertyDocument from "./UploadPropertyDocument";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyDetailsFilter } from "../../../../redux/reducer/counterslice";
import PropertyCreate from "./PropertyCreate";
import Documents from "./ComplianceDocument";
import ArchivePopup from "./ArchivePopup";
import { useFetchPropertyExpenditure } from "../../../../Api/ContactsQuery";
import expenditure from "../../../../Assets/Common/expenditure.png";
import grossIncome from "../../../../Assets/Common/grossIncome.png";
import income from "../../../../Assets/Common/income.png";
export default function Propertydetails(props) {
  const { rowvalue, fromContact = false } = props;
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [Generate, setGenerate] = useState("");
  const [propertyApprovalFolder, setPropertyApprovalFolder] = useState("");
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [archivePopup, setArchivePopup] = useState(false);
  const [selectedArchivedData, setSelectedArchivedData] = useState({});
  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  // const fetchArchiveDoc = useFetchArchive_document(payloadForArchiveApi);
  // useEffect(() => {
  //   console.log(fetchArchiveDoc.data, "lasjkdgljasldfk;alsk;kas");
  // }, [fetchArchiveDoc]);

  const fetchMaintenanceProperties = useFetchMaintenancePropertySpecific({
    // params: {
    id: rowvalue.id,
    page: pageindex,
    page_size: pagesize,
    search: search,
    // },
  });

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    console.log(fetchMaintenanceProperties.data, "Lkjlajsdlklfajsl");
    if (fetchMaintenanceProperties.data?.data?.results && tagactive == "3") {
      setTableData(fetchMaintenanceProperties.data?.data?.results);

      setCount(fetchMaintenanceProperties.data?.data?.count);
    }
  }, [fetchMaintenanceProperties.data]);
  const tagactive = useSelector((state) => state.counter.propertyDetailsFilter);
  const dispatch = useDispatch();

  const handlebuttonclick = (e) => {
    dispatch(setPropertyDetailsFilter(e.target.id));
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Reported Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.created_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "status",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.getValue());
          const data = info.row.original.status;

          return (
            <div
              style={{
                width: "195px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "8px 0px",
                borderRadius: "10px",
                fontWeight: "500",
                fontSize: "16px",
                gap: "6px",
                marginLeft: "6px",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  background:
                    data === "Quote Requested"
                      ? "#FFD47C"
                      : data === "New Requests"
                      ? "#97E9AC"
                      : data === "Awaiting payment"
                      ? "#FFB693"
                      : data === "Awaiting Approval"
                      ? "#FF8485"
                      : data === "Approved"
                      ? "#7DD1F3"
                      : data === "Booked"
                      ? "#8889F4"
                      : data === "Rejected"
                      ? "#EFA6A6"
                      : data === "Resolved"
                      ? "#A6EFC3"
                      : data === "More Info"
                      ? "#AC7AFF"
                      : "",
                }}
              ></span>
              <span style={{ fontSize: "18px" }}>{data}</span>
            </div>
          );
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor("summary", {
        header: "Summary",
        cell: (info) => {
          const data = info.row.original?.title;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor("description", {
        header: "Description",
        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
                maxWidth: "350px",
                display: "flex",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor("quote", {
        header: "Quote",
        cell: (info) => {
          const data = info.row.original?.invoice_amount;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      }),
    ],
    []
  );
  const tenancyColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Tenant",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.tenant?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Start Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.start_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "End Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.end_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Status",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.status;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
                background: data === "Periodic" ? "#5EFF9F" : "",
              }}
            >
              {data}
            </span>
          );
        },
      }),
    ],
    []
  );
  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Pay In",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Pay Out",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Balance",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.balance;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Type",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
    ],
    []
  );
  const incomeAndExpenditure = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.amount;
          const type = info.row.original?.charge_item1;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
                color: type === "Landlord Income" ? "black" : "red",
              }}
            >
              {type === "Landlord Income"
                ? `£${formatPounds(data)}`
                : `-£${formatPounds(data)}`}
            </span>
          );
        },
      }),
    ],
    []
  );

  const fetchComplianceMaster = useFetchDetails({
    property: rowvalue?.id,
    client: rowvalue?.client?.id,
    tenant: fromContact
      ? rowvalue?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
      : rowvalue?.tenant?.tenant__id,
  });
  const fetchPropertyDetails = useFetchPropertiesCompliance(rowvalue.id);
  const fetchPropertyPropertyTransactionDetails = useFetchPropertyTransaction({
    property: rowvalue.id,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });

  const [propertyData, setPropertyData] = useState([]);
  useEffect(() => {
    console.log(rowvalue, "askjdflkjsaldj");
    if (fetchPropertyDetails.data?.data) {
      setPropertyData(fetchPropertyDetails.data.data);
      setPropertyApprovalFolder(fetchPropertyDetails.data.data?.document_types);
      console.log(fetchPropertyDetails.data.data, "jalsdjflajsldjflkajsljfls");
    }
  }, [fetchPropertyDetails.data]);

  const [propertyCompliance, setPropertyCompliance] = useState([]);
  const [tenancyCompliance, setTenancyCompliance] = useState([]);

  useEffect(() => {
    console.log(rowvalue, "askjdflkjsaldj");
    if (
      tagactive == "2" &&
      fetchComplianceMaster.data?.data?.property_documents_data
    ) {
      console.log(fetchComplianceMaster.data?.data, "jalsdjflajsldjf");
      setPropertyCompliance(
        fetchComplianceMaster.data?.data?.property_documents_data
      );
      setTenancyCompliance(
        fetchComplianceMaster.data?.data?.tenant_tenancy_files_data
      );
    }
  }, [fetchComplianceMaster.data?.data]);

  useEffect(() => {
    if (
      tagactive == "5" &&
      fetchPropertyPropertyTransactionDetails.data?.data
    ) {
      setTableData(fetchPropertyPropertyTransactionDetails.data?.data?.results);
      setCount(fetchPropertyPropertyTransactionDetails.data?.data?.count);
    }
  }, [fetchPropertyPropertyTransactionDetails.data, tagactive]);
  const clickrow = (row) => {};
  const fetchLandLordExpenditureData = useFetchPropertyExpenditure({
    params: {
      property: rowvalue.id,
      page: pageindex,
      page_size: pagesize,
      search: search,
    },
  });

  const cardData = [
    {
      title: "Net Amount",
      amount: (data) =>
        formatPounds(data?.property_income_total - data?.expenses_total),
      imgSrc: grossIncome,
      altText: "grossIncome",
    },
    {
      title: "Total Income",
      amount: (data) => formatPounds(data?.property_income_total),
      imgSrc: income,
      altText: "income",
    },
    {
      title: "Total Expenditure",
      amount: (data) => formatPounds(data?.expenses_total),
      imgSrc: expenditure,
      altText: "expenditure",
    },
  ];
  return (
    <div className="popup_main_wrapper">
      <div className="content_wrapper">
        <div className="header">
          <div
            className="header_content-wrapper row p-0 m-0"
            style={{ position: "relative", alignItems: "flex-start" }}
          >
            <div className="col-1 p-0 m-0">
              <img src={rowvalue.image[0]} width={125} height={125}></img>
            </div>
            <div
              className="title_wrapper col-10 m-0"
              style={{ position: "relative", width: "91%" }}
            >
              <h1>
                {`${rowvalue.name} ${rowvalue.street}, ${rowvalue.city}, ${rowvalue.postcode}`}
              </h1>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="button_wrapper">
                  <button
                    id={"1"}
                    onClick={handlebuttonclick}
                    className={tagactive == "1" ? "active" : ""}
                  >
                    Contact Details
                  </button>
                  <button
                    id={"2"}
                    onClick={(e) => {
                      handlebuttonclick(e);
                      setPropertyCompliance(
                        fetchComplianceMaster.data?.data
                          ?.property_documents_data
                      );
                      setTenancyCompliance(
                        fetchComplianceMaster.data?.data
                          ?.tenant_tenancy_files_data
                      );
                    }}
                    className={tagactive == "2" ? "active" : ""}
                  >
                    {" "}
                    Compliance
                  </button>
                  <button
                    id={"7"}
                    onClick={(e) => {
                      handlebuttonclick(e);
                      console.log(
                        fetchLandLordExpenditureData.data?.data,
                        "lkajksdfljklajsdfl"
                      );
                      setTableData([
                        ...fetchLandLordExpenditureData.data?.data
                          ?.landlord_income,
                        ...fetchLandLordExpenditureData.data?.data?.expenses,
                      ]);
                      setCount(0);
                    }}
                    className={tagactive == "7" ? "active" : ""}
                  >
                    Income and Expenditure
                  </button>
                  <button
                    id={"3"}
                    onClick={(e) => {
                      handlebuttonclick(e);
                      setTableData(
                        fetchMaintenanceProperties.data?.data?.results
                      );
                      setCount(fetchMaintenanceProperties.data?.data?.count);
                    }}
                    className={tagactive == "3" ? "active" : ""}
                  >
                    Maintenance
                  </button>

                  <button
                    id={"4"}
                    onClick={(e) => {
                      handlebuttonclick(e);
                      setTableData(propertyData.tenancies?.[0]?.tenancies);
                    }}
                    className={tagactive == "4" ? "active" : ""}
                  >
                    Tenancies
                  </button>
                  <button
                    id={"5"}
                    className={tagactive == "5" ? "active" : ""}
                    onClick={(e) => {
                      console.log(
                        fetchPropertyPropertyTransactionDetails.data?.data
                          ?.results,
                        "aojsdjflasfdaljflajs"
                      );
                      handlebuttonclick(e);
                    }}
                  >
                    Transactions
                  </button>
                  <button
                    id={"6"}
                    onClick={handlebuttonclick}
                    className={tagactive == "6" ? "active" : ""}
                  >
                    Upload Documents
                  </button>
                </div>
              </div>
              {tagactive == "3" && (
                <div className="table_props">
                  <Search setsearch={setsearch} style={{ width: "200px" }} />
                  <GenerateReport setGenerate={setGenerate} />
                </div>
              )}
            </div>
            <div
              className="col-1 close_button_wrapper"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                paddingBottom: "0px",
              }}
            >
              <img
                className="close"
                onClick={(e) => {
                  props.setpopup(false);
                  // props.setSelectedLandLordId("");
                }}
                src={closebutton}
              ></img>
            </div>
          </div>
        </div>
        {tagactive == "2" ? (
          <>
            <Documents
              title="Property Documents"
              documents={propertyCompliance}
              setSelectedArchivedData={setSelectedArchivedData}
              setArchivePopup={setArchivePopup}
              setPayloadForArchiveApi={setPayloadForArchiveApi}
              type={"property"}
              rowvalue={rowvalue}
            />
            <Documents
              title="Tenancy Documents"
              documents={tenancyCompliance}
              setSelectedArchivedData={setSelectedArchivedData}
              setArchivePopup={setArchivePopup}
              setPayloadForArchiveApi={setPayloadForArchiveApi}
              type={"tenancy"}
              rowvalue={rowvalue}
            />
          </>
        ) : tagactive == "1" ? (
          <PropertyPropertyContacts
            dataFromParent={rowvalue}
            fromContact={fromContact}
          />
        ) : tagactive == "6" ? (
          // <PropertyPropertyContacts dataFromParent={rowvalue} />
          <UploadPropertyDocument
            dataFromParent={rowvalue}
            propertyApprovalFolderId={propertyApprovalFolder}
          />
        ) : (
          <div className="popuptable">
            {tagactive == "7" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "20px",
                  width: "94.5%",
                  margin: "0 auto",
                }}
              >
                {cardData.map((card, index) => (
                  <div className="landlordPopupCard active" key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{card.title}</p>
                      <img
                        src={card.imgSrc}
                        alt={card.altText}
                        style={{ width: "35px", height: "35px" }}
                      />
                    </div>
                    <h1>
                      £{card.amount(fetchLandLordExpenditureData.data?.data)}
                    </h1>
                  </div>
                ))}
              </div>
            )}
            {tagactive == "5" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  className="accountBalanceCard"
                  style={{
                    background: "#EEEEFF",
                    width: "425px",
                    marginRight: "35px",
                  }}
                >
                  <div className="wrapper">
                    <img
                      src={Money}
                      alt="wallet"
                      width={"26px"}
                      height={"26px"}
                    />
                    <span style={{ fontSize: "19px", fontWeight: "500" }}>
                      Account Balance
                    </span>
                  </div>
                  <span>
                    <span
                      style={{
                        fontSize: "26px",
                        color:
                          tableData?.[0]?.balance > 0
                            ? "green"
                            : tableData?.[0]?.balance > 0
                            ? "red"
                            : "black",
                        fontWeight: "300",
                      }}
                    >
                      £{formatPounds(tableData ? tableData?.[0]?.balance : 0)}
                    </span>
                  </span>
                </div>
              </div>
            )}
            <TableComponent
              data={tableData ? tableData : []}
              columns={
                tagactive == "4"
                  ? tenancyColumn
                  : tagactive == "5"
                  ? transactionColumn
                  : tagactive == "7"
                  ? incomeAndExpenditure
                  : columns
              }
              onclickrow={clickrow}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          </div>
        )}
      </div>
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
          // editData={fetchArchiveDoc}
          // editData={editData}
          // setEditData={setEditData}
        />
      )}
    </div>
  );
}
