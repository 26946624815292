import "../../StyleSheet/dashboard/payments.scss";
import { payments } from "../../Content/image";
import { useDispatch, useSelector } from "react-redux";
import {
  useArrears,
  useFetchWorkOrderNumber,
  usePayments,
} from "../../../Api/homeQuery";
import { useEffect, useState } from "react";
import { formatPounds, formatPoundsNoDec } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  setClientMoneyAccountStatus,
  setClientMoneyStatus,
  setClientPaymentDueFilter,
  setClientRentPopup,
  setClientRentPopupData,
} from "../../../redux/reducer/counterslice";

export default function PaymentsHome() {
  const clientid = useSelector((state) => state.counter.clientid);
  const [Data, setData] = useState([]);
  const navigate = useNavigate();
  const [amountData, setamountData] = useState([]);
  const [awaitingapprovalcount, setawaitingapprovalcount] = useState(0);
  const fetchMaintenanceCount = useFetchWorkOrderNumber({
    params: { client: clientid },
    loading: true,
  });
  const toggle = useSelector((state) => state.counter.clientMoneyStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(fetchMaintenanceCount?.data?.data, ",sdflakjsdljflkajsljd");
    setawaitingapprovalcount(fetchMaintenanceCount?.data?.data?.[3]?.count);
  }, [fetchMaintenanceCount?.data]);

  const getpayments = usePayments({
    params: { client: clientid },
  });
  const getarrears = useArrears({
    params: { id: clientid },
    loading: false,
  });

  useEffect(() => {
    console.log(getarrears.data, "ajdahkjadhkja");
    if (getarrears.data != undefined) {
      setData(getarrears.data.data[0]);
    }
  }, [getarrears.data]);

  useEffect(() => {
    console.log(getpayments.data, "ajdahkjaafalkj");

    if (getpayments.data != undefined) {
      setamountData(getpayments.data.data);
    }
  }, [getpayments.data]);
  const formatPoundse = (amount) => {
    return new Intl.NumberFormat("en-GB", {
      style: "unit",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className="payents_main_wrapper">
      <div className="listdata">
        <ul>
          <li
            style={{ paddingTop: "0" }}
            onClick={() => {
              navigate("/clientmoney");
              dispatch(setClientMoneyStatus("Overview"));
              dispatch(
                setClientRentPopupData({
                  text: "Today’s Rents",
                  subtext: "",
                })
              );
              setTimeout(() => {
                dispatch(setClientRentPopup(true));
              }, 200);
            }}
          >
            <div>
              <img src={payments[0]}></img>
              <h2>Rent Received</h2>
            </div>
            <p>£{formatPoundsNoDec(amountData?.payments_received)}</p>
          </li>
          <li
            onClick={() => {
              dispatch(setClientMoneyStatus("Payments"));
              dispatch(setClientPaymentDueFilter("p_due"));
              navigate("/clientmoney");
            }}
          >
            <div>
              <img src={payments[1]}></img>
              <h2>Payments Due</h2>
            </div>
            {/* <p>
              £ 
              {formatPounds(
                Data?.charges_0_7_days +
                  Data?.charges_8_14_days +
                  Data?.charges_15_30_days +
                  Data?.charges_31_plus_days || 0
              )}
            </p> */}
            <p>£{formatPoundsNoDec(amountData?.payment_due)}</p>
          </li>
          <li
            onClick={() => {
              dispatch(setClientMoneyStatus("Accounts"));
              dispatch(setClientMoneyAccountStatus("Reserve Account"));
              navigate("/clientmoney");
            }}
          >
            <div>
              <img src={payments[2]}></img>
              <h2>Reserve Funds</h2>
            </div>
            <p>£{formatPoundsNoDec(amountData?.reserve_funds)}</p>
          </li>
          <li
            style={{ border: "none", paddingBottom: "0" }}
            onClick={() => {
              navigate("/maintenance/approval");
            }}
          >
            <div>
              <img src={payments[3]}></img>
              <h2>Awaiting Approval</h2>
            </div>
            <p>{awaitingapprovalcount || 0}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
