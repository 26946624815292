import "../../StyleSheet/dashboard/periodicinsepction.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useFetchComplianceStats } from "../../../Api/ComplianceQuery";
import { addLeadingZero, findIndexByType } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { setPeriodicInspectionStatus } from "../../../redux/reducer/counterslice";
export default function PeriodicInsepction() {
  const userId = useSelector((state) => state.counter.loginuserdata.id);
  const clientid = useSelector((state) => state.counter.clientid);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([0, 0, 0]);
  const [totalCount, setTotalCount] = useState();
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const subColor = ["#dbf8e7", "#fff0cc", "#ffd8d4"];
  const text = ["Not Due", "Due", "Overdue"];
  const fetchComplianceStats = useFetchComplianceStats(userId);
  // useEffect(() => {
  //   if (fetchComplianceStats.data?.data) {
  //     console.log(
  //       fetchComplianceStats.data.data,
  //       "laskdjflkasdfasfasfjsdjflaj"
  //     );
  //     //   fetchComplianceStats.data?.data?.document_count?.[6].map((ele) => {
  //     //     if (ele.document_type == "periodic inspection") {

  //     //     }
  //     //   });
  //     setChartData([
  //       fetchComplianceStats.data?.data?.document_count?.[6]?.not_due_count,
  //       fetchComplianceStats.data?.data?.document_count?.[6]?.due_count,
  //       fetchComplianceStats.data?.data?.document_count?.[6]?.overdue_count,
  //     ]);

  //     setTotalCount([
  //       fetchComplianceStats.data?.data?.document_count?.[6]?.not_due_count +
  //         fetchComplianceStats.data?.data?.document_count?.[6]?.due_count +
  //         fetchComplianceStats.data?.data?.document_count?.[6]?.overdue_count,
  //     ]);
  //   }
  // }, [fetchComplianceStats.data]);
  useEffect(() => {
    if (fetchComplianceStats.data?.data) {
      console.log(
        fetchComplianceStats.data.data,
        "laskdjflkasdfasfasfjsdjflaj"
      );

      const index = findIndexByType(
        fetchComplianceStats.data?.data?.document_count,
        "Periodic inspection"
      );

      if (index !== "not available") {
        setChartData([
          fetchComplianceStats.data?.data?.document_count?.[index]
            ?.not_due_count,
          fetchComplianceStats.data?.data?.document_count?.[index]?.due_count,
          fetchComplianceStats.data?.data?.document_count?.[index]
            ?.overdue_count,
        ]);
        setTotalCount([
          fetchComplianceStats.data?.data?.document_count?.[index]
            ?.not_due_count +
            fetchComplianceStats.data?.data?.document_count?.[index]
              ?.due_count +
            fetchComplianceStats.data?.data?.document_count?.[index]
              ?.overdue_count,
        ]);
      }
    }
  }, [fetchComplianceStats.data]);
  useEffect(() => {
    console.log(chartData, "lajsdlfjalsjkljfd");
  }, [chartData]);
  const statusData = ["not_due", "due", "overdue"];

  return (
    <div className="periodic_insepction">
      <h1 className="header">Periodic Inspection</h1>
      <div className="periodic_insepction_inner_wrapper">
        {chartData.map((item, index) => {
          // Calculate the percentage width based on the total number of properties
          const percentage = (item / totalCount) * 100;

          return (
            <div
              className="progress_main"
              key={index}
              onClick={() => {
                dispatch(setPeriodicInspectionStatus(statusData[index]));
                navigate("/insepection");
              }}
            >
              <div className="main_text">
                <span className="text">{text[index % text.length]}</span>
                <span style={{ color: colors[index % colors.length] }}>
                  {addLeadingZero(item)}
                </span>
              </div>

              <div className="progress_bar">
                <div
                  className="progress progress-sm"
                  style={{
                    height: "10.7px",
                    backgroundColor: subColor[index % subColor.length],
                  }}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: `${percentage}%`, // Use the calculated percentage
                      borderRadius: "1.25rem",
                      backgroundColor: colors[index % colors.length],
                    }}
                    aria-valuenow={percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
