import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceTable(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "maintenanceTableList",
      payload.status,
      payload.search,
      payload.page,
      payload.page_size,
    ],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          status: payload.status,
          client: payload.id,
          search: payload.search,
          page: payload.page,
          page_size: payload.page_size,
        },
      };
      const response = handleResponse(
        "workorder/workorder/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceFilterStatus(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["MaintenanceFilterStatus"],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: payload.id,
        },
      };
      const response = handleResponse(
        "workorder/client_mainatnce_count",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchMaintenanceSpendAmount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["maintenanceSpedAmount", payload.date_filter],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          date_filter: payload.date_filter,
          client: payload.id,
        },
      };
      const response = handleResponse(
        "workorder/client_mainatnce_spendamount/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchUpcomingMaintenance(payload) {
  const { handleResponse } = useResponseHandler();
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);

  return useQuery({
    queryKey: ["upcomingMaintenance"],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          client: loginuserdata.id,
        },
      };
      const response = handleResponse(
        "workorder/client_upcoming_mainatnce/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchMaintenanceArchive(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "maintenanceArchive",
      payload.status,
      payload.search,
      payload.page,
      payload.page_size,
    ],

    queryFn: async () => {
      const uploadPayload = {
        params: {
          status: payload.status,
          client: payload.id,
          search: payload.search,
          page: payload.page,
          page_size: payload.page_size,
        },
      };
      const response = handleResponse(
        "workorder/workorder/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useApproveSupplier(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.payload,
      };
      console.log(payload, "lkajsldfjksdaiflj");
      return handleResponse(
        `workorder/workorder_capprove/v1/${payload.id}/`,
        "put",
        uploadPayload
      );
    },
    onSuccess: () => {
      payload.handleSuccess();
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error("Error occurred while raising issues.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["maintenanceTableList"],
        });
      }
    },
  });
}


export function useFetchMaintenanceOverviewAmount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: [
      "maintenanceOverViewAmount",
      payload.client,
      payload.paid_by,
      payload.page,
      payload.page_size,
      payload.start_date,
      payload.end_date,
    ],

    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "workorder/client_mainatnce_spendamount_summary/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}