import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useArrears, useFetchArrearsSelected } from "../../../../Api/homeQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";
import Search from "../../../../MainComponent/fields/search";

export default function ArrearsPopup(props) {
  const { activeTab, setActiveTab, dataFromData } = props;
  const columnHelper = createColumnHelper();
  const [paramsPayload, setParamsPayload] = useState();
  const [TableData, setTableData] = useState([]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [search, setsearch] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchArrears = useFetchArrearsSelected({
    date_range: paramsPayload,
    client: clientid,
    page: pageindex,
    page_size: pagesize,
  });
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "Address",
        sortingFn: "basic",
        cell: ({ row }) => {
          const data = row?.original?.id;
          var address = [];
          if (data?.property != undefined) {
            address = [
              data?.property?.name,
              data?.property?.street,
              data?.property?.city,
              data?.property?.postcode,
            ]
              .filter(Boolean)
              .join(", ");
          } else {
            address = [data?.name, data?.street, data?.city, data?.postcode]
              .filter(Boolean)
              .join(", ");
          }
          return <span style={{ textTransform: "capitalize" }}>{address}</span>;
        },
        header: () => <span>Property Address</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "tenantName",
        header: "Tenant Name",
        sortingFn: "basic",
        cell: ({ row }) => {
          const data = row?.original?.id;
          var contact = [];
          if (data?.tenant != undefined) {
            contact = [data?.tenant?.first_name, " ", data?.tenant?.last_name]
              .filter(Boolean)
              .join(" ");
          } else {
            contact = [
              data?.tenancies[0]?.tenancies[0]?.tenant?.first_name,
              " ",
              data?.tenancies[0]?.tenancies[0]?.tenant?.last_name,
            ]
              .filter(Boolean)
              .join(" ");
          }
          return <span>{contact ? <span>{contact}</span> : "--"}</span>;
        },
      }),

      columnHelper.accessor((row) => row, {
        id: "amount",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            <span>£{formatPounds(info.row.original.total_arrears)}</span>
          </span>
        ),
        header: "Amount",
      }),
    ],
    []
  );
  const setParams = () => {
    if (activeTab === "0 - 7 Days") {
      setParamsPayload("0_7_days");
    } else if (activeTab === "8 - 14 Days") {
      setParamsPayload("8_14_days");
    } else if (activeTab === "15 - 30 Days") {
      setParamsPayload("15_30_days");
    } else if (activeTab === "31+ Days") {
      setParamsPayload("31_plus_days");
    } else if (activeTab === "All") {
      setParamsPayload("all");
    }
  };
  useEffect(() => {
    setParams();
  }, [activeTab]);
  useEffect(() => {
    console.log(fetchArrears.data?.data, "ajsfjalsjkdfasfdasf");
    if (fetchArrears.data?.data) {
      setTableData(fetchArrears.data?.data);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data, paramsPayload, pageindex, pagesize]);

  const getarrears = useArrears({
    params: { id: clientid },
    loading: false,
  });
  useEffect(() => {
    console.log(getarrears.data, "ajdahkjadhkja");
    if (getarrears.data != undefined) {
      if (activeTab === "0 - 7 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_0_7_days);
      } else if (activeTab === "8 - 14 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_8_14_days);

        setParamsPayload("8_14_days");
      } else if (activeTab === "15 - 30 Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_15_30_days);

        setParamsPayload("15_30_days");
      } else if (activeTab === "31+ Days") {
        setTotalAmount(getarrears.data.data[0]?.charges_31_plus_days);

        setParamsPayload("31_plus_days");
      } else if (activeTab === "All") {
        setTotalAmount(
          getarrears.data.data[0]?.charges_31_plus_days +
            getarrears.data.data[0]?.charges_15_30_days +
            getarrears.data.data[0]?.charges_8_14_days +
            getarrears.data.data[0]?.charges_0_7_days
        );
      }
    }
  }, [getarrears.data, activeTab]);

  return (
    <div className="popup_main_wrapper">
      <div className="main_popup">
        <div className="header">
          <div className="content">
            <h1>
              Tenancy Arrears:{" "}
              <span
                style={{
                  color:
                    props?.activeTab === "0 - 7 Days"
                      ? "rgb(0, 0, 71)"
                      : props?.activeTab === "8 - 14 Days"
                      ? "rgb(77,222,135)"
                      : props?.activeTab === "15 - 30 Days"
                      ? "rgb(79,195,244)"
                      : props?.activeTab === "31+ Days" && "rgb(129,126,255)",
                }}
              >
                {props?.activeTab}
              </span>
            </h1>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => props.setPopupstate(false)}
                src={closebutton}
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "40px",
              background: "rgb(247,249,251)",
              borderRadius: "15px",
            }}
          >
            <FilterPanel
              tabs={[
                "All",
                "0 - 7 Days",
                "8 - 14 Days",
                "15 - 30 Days",
                "31+ Days",
              ]}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Search setsearch={setsearch} style={{ width: "410px" }} />

            <div className="totalArrears">
              <span>Total Arrears</span>
              <span>£{formatPounds(totalAmount)}</span>
            </div>
          </div>
        </div>
        <div className="arrearsConfig">
          <TableComponent
            columns={columns}
            data={TableData}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}

            //   handleRowClick={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
}
