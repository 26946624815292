import GenerateReport from "../../../MainComponent/fields/generatereport";
import Search from "../../../MainComponent/fields/search";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import "../../StyleSheet/compliance/compliancetable.scss";
import { createColumnHelper } from "@tanstack/react-table";
import dummy1 from "../../../Assets/dummy/dummy1.svg";
import { useState, useEffect, useMemo } from "react";
import download from "../../../Assets/Common/Download.svg";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useFetchComplianceStatscount } from "../../../Api/ComplianceQuery";
import { addLeadingZero } from "../../../Utils/Utils";
export default function ComplianceTable(props) {
  const {
    count,
    setCount,
    pageindex,
    setPageIndex,
    setPageSize,
    pagesize,
    setsearch,
    search,
  } = props;

  const { complianceList, setComplianceList, tableData } = props;
  console.log(complianceList, "complianceList");
  const [chartData, setChartData] = useState([0, 0, 0]);
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const text = ["Not Due", "Due", "Overdue"];
  const columnHelper = createColumnHelper();
  const [Generate, setGenerate] = useState("");
  const [totalCompliance, setTotalCompliance] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);

  const countdata = useFetchComplianceStatscount({
    params: { client: clientid, doc_id: complianceList.complianceSelected },
  });
  useEffect(() => {
    if (countdata?.data?.data != undefined) {
      setChartData([
        countdata?.data?.data?.document_count[0]?.not_due_count,
        countdata?.data?.data?.document_count[0]?.due_count,
        countdata?.data?.data?.document_count[0]?.overdue_count,
      ]);
      setTotalCompliance([
        countdata?.data?.data?.document_count[0]?.not_due_count +
          countdata?.data?.data?.document_count[0]?.due_count +
          countdata?.data?.data?.document_count[0]?.overdue_count,
      ]);
    }
  }, [
    countdata.data,
    complianceList.complianceSelected,
    complianceList.complianceFilter,
  ]);

  const getFileName = (url) => {
    const parts = url.split("/");
    const fileNameWithExtension = parts[parts.length - 1];
    const fileName = fileNameWithExtension.split(".")[0]; // Remove file extension
    console.log(fileName, "ljalsdjkfjasldjfl");
    return fileName;
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.download = getFileName(url);
    link.download = "Hello";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // useEffect(() => {
  //   console.log(selectedPayload, "lajseflfjasdfljjdsf");
  // }, [selectedPayload]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("expiry_date", {
        header: "Next Due Date",
        cell: (info) => (
          <p style={{ wordBreak: "break-all" }}>
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        ),
      }),

      columnHelper.accessor("status", {
        cell: (info) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <div
              className="status_button"
              style={{
                textTransform: "capitalize",
                height: "15px",
                width: "10px",
                // padding: "8px 10px",
                borderRadius: "2px",
                backgroundColor:
                  info.getValue() === "due"
                    ? "#FFE9B5"
                    : info.getValue() === "not_due"
                    ? "#CEFFE1"
                    : "#FFD3CF",
              }}
            ></div>
            <p style={{ textTransform: "capitalize", fontSize: "16px" }}>
              {info.getValue() === "not_due" ? "Not Due" : info.getValue()}
            </p>
          </div>
        ),
      }),

      columnHelper.accessor("property_address", {
        header: "Address",
        cell: (info) => {
          const data = info.row.original?.property_address;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Document",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <p
              style={{
                color: "#817EFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(data.file?.[0], "_blank");
              }}
            >
              Download&nbsp;&nbsp;
              <img
                src={download}
                width={"15px"}
                height={"15px"}
                alt="document image"
              />
            </p>
          );
        },
        header: () => <span>Document</span>,
      }),
    ],
    []
  );
  const handleFilerChange = (value) => {
    setComplianceList((prev) => ({
      ...prev,
      complianceFilter: value,
    }));
  };
  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }
  return (
    <div className="compliance_table">
      <div className="header">
        <div
          className="text_container"
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={tableData?.[0]?.icon}
              alt="img"
              style={{
                width: "48px",
                height: "48px",
                position: "relative",
                bottom: "10px",
              }}
            ></img>
            <h1
              style={{
                textTransform: "capitalize",
                fontSize: "32px",
                fontWeight: "400",
              }}
            >
              {complianceList.selectedPayload?.name}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <GenerateReport
              style={{ fontSize: "14px" }}
              setGenerate={setGenerate}
            />
          </div>
        </div>
        <div className="progress_wrapper row">
          {chartData.map((item, index) => {
            // Calculate the percentage width based on the total number of properties
            const percentage = (item / totalCompliance) * 100;

            return (
              <div className="col-4" key={index}>
                <div className="progress_main">
                  <div className="main_text">
                    <span className="text" style={{ fontSize: "18px" }}>
                      {text[index % text.length]}
                    </span>
                    <span
                      style={{
                        color: colors[index % colors.length],
                        fontSize: "18px",
                      }}
                    >
                      {addLeadingZero(item)}
                    </span>
                  </div>

                  <div className="progress_bar">
                    <div
                      className="progress progress-sm"
                      style={{
                        height: "11px",
                        background:
                          index === 0
                            ? "rgb(219,248,231)"
                            : index === 1
                            ? "rgb(255,240,204)"
                            : index === 2
                            ? "rgb(255,216,212)"
                            : "",
                      }}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${percentage}%`, // Use the calculated percentage
                          borderRadius: "1.25rem",
                          backgroundColor: colors[index % colors.length],
                        }}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="divider"></div>
      <div className="main_tablecontent">
        <div className="table_filter">
          <div className="button_container" style={{ display: "flex" }}>
            <button
              className={`${
                complianceList.complianceFilter === "all" && "active"
              }`}
              onClick={() => {
                handleFilerChange("all");
              }}
            >
              All
              <span>
                {addLeadingZero(chartData[0] + chartData[1] + chartData[2])}
              </span>
            </button>
            <button
              className={`${
                complianceList.complianceFilter === "not_due" && "active"
              }`}
              onClick={() => {
                handleFilerChange("not_due");
              }}
            >
              Not Due
              <span style={{ fontSize: "16px" }}>
                {addLeadingZero(chartData[0])}
              </span>
            </button>{" "}
            <button
              className={`${
                complianceList.complianceFilter === "due" && "active"
              }`}
              onClick={() => {
                handleFilerChange("due");
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              Due
              <span style={{ fontSize: "16px" }}>
                {addLeadingZero(chartData[1])}
              </span>
            </button>{" "}
            <button
              className={`${
                complianceList.complianceFilter === "overdue" && "active"
              }`}
              onClick={() => {
                handleFilerChange("overdue");
              }}
            >
              Overdue
              <span>{addLeadingZero(chartData[2])}</span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search
              search={search}
              setsearch={setsearch}
              style={{ width: "166px" }}
            />
          </div>
        </div>
        <div className="table_compliance" style={{ paddingTop: "10px" }}>
          <TableComponent
            data={tableData}
            columns={columns}
            count={count}
            setCount={setCount}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
}
