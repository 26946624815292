import "./style.scss";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function DateRange(props) {
    const handleChange = (dates, dateStrings) => {
        if (props.onChange) {
            props.onChange(dateStrings);
        }
    };

    return (
        <div className="DateRange" style={{ ...props.style }}>
            <RangePicker
                size={"large"}
                format={dateFormat}
                onChange={handleChange}
            />
        </div>
    );
}
