import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchWorkOrderBasedStatus } from "../../../../Api/homeQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { addLeadingZero } from "../../../../Utils/Utils";

const MaintenancePopup = (props) => {
  const columnHelper = createColumnHelper();

  const { maintenanceStage, setPopup, titleColor } = props;
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);

  const fetchArrears = useFetchWorkOrderBasedStatus({
    params: {
      page: pageindex,
      page_size: pagesize,
      status: maintenanceStage,
      client: clientid,
    },
  });
  const [TableData, setTableData] = useState([]);
  function formatAddress(addressParts) {
    return addressParts.filter((part) => part).join(", ");
  }
  const calculateDaysElapsed = (isoDateString) => {
    const givenDate = dayjs(isoDateString).startOf("day");
    const currentDate = dayjs().startOf("day");
    return currentDate.diff(givenDate, "day");
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "date_reported",
        header: "Date Reported",
        sortingFn: "basic",

        cell: (info) => (
          <span style={{ textTransform: "capitalize" }}>
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "name",
        header: "Days Elapsed",
        sortingFn: "basic",
        cell: (info) => {
          const contact = "Persist : 0 ";
          return (
            <span>
              {contact ? (
                <span
                  style={{ textTransform: "capitalize", marginLeft: "38px" }}
                >
                  {addLeadingZero(
                    calculateDaysElapsed(info.row.original.created_date)
                  )}
                </span>
              ) : (
                "--"
              )}
            </span>
          );
        },
      }),
      // columnHelper.accessor("total_arrears", {
      //   header: "Created by",
      //   cell: (info) => (
      //     <span>
      //       <span>{info.getValue()}</span>
      //     </span>
      //   ),
      // }),
      columnHelper.accessor((row) => row, {
        id: "Address",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original;
          return (
            <span
              style={{
                maxWidth: "400px",
                display: "flex",
                textTransform: "capitalize",
              }}
            >
              {formatAddress([
                data?.property?.name + " " + data?.property?.street,
                data?.property?.city,
                data?.property?.postcode,
              ])}
            </span>
          );
        },
        header: "Address",
      }),
      columnHelper.accessor((row) => row, {
        id: "Summary",
        enableSorting: false,
        cell: (info) => (
          <span
            style={{
              maxWidth: "400px",
              display: "flex",
              textTransform: "capitalize",
            }}
          >
            {info.row.original?.issue_list?.title} ,{" "}
            {info.row.original?.issue_type?.name}
          </span>
        ),
        header: "Summary",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        enableSorting: false,
        cell: (info) => (
          <span>
            <span>{info.getValue()}</span>
          </span>
        ),
      }),
    ],
    []
  );

  useEffect(() => {
    if (fetchArrears.data?.data) {
      setTableData(fetchArrears.data?.data?.results);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data]);

  return (
    <>
      <div className="popup_main_wrapper">
        <div
          className="main_popup"
          style={{ width: "1757px", height: "755px" }}
        >
          <div className="header">
            <div className="content">
              <h1 style={{ color: titleColor, marginBottom: "40px" }}>
                {maintenanceStage}
              </h1>
              <div className="col-1 close_button_wrapper">
                <img
                  className="close"
                  alt="close"
                  onClick={() => {
                    setPopup(false);
                  }}
                  src={closebutton}
                />
              </div>
            </div>
          </div>
          <div className="maintenanceConfig">
            <TableComponent
              columns={columns}
              data={TableData}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaintenancePopup;
