import { useEffect } from "react";
import "./App.css";
import SideBarComponent from "./Client/GlobalComponents/Sidebar/sidebar";
import { Outlet } from "react-router-dom";
import CircularIndeterminate from "./MainComponent/Loader";
import { useSelector } from "react-redux";

function App() {
  useEffect(() => {
    handleChnage("1");
  }, []);
  const handleChnage = (e) => {
    // var browserZoomLevel = Math.round(
    //   (window.outerWidth / window.innerWidth) * 100
    // );
    // var doc = document.getElementsByClassName("App");
    // let zoom = (window.innerWidth / 1820) * 100;
    // doc[0].style.zoom = `${Math.round(zoom)}%`;
    // const browserZoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
    const zoom = (window.innerWidth / 1820) * 95;
    document.body.style.zoom = `${Math.round(zoom)}%`;
  };
  window.addEventListener("resize", handleChnage);
  const loading = useSelector((state) => state.counter.loader);

  return (
    <>
      {/* {loading && <CircularIndeterminate />} */}

      <div className="App">
        <SideBarComponent />

        <CircularIndeterminate />
        <Outlet />
      </div>
    </>
  );
}

export default App;
