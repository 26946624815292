import { useEffect, useState } from "react";
import Search from "../../../MainComponent/fields/search";
import "../../StyleSheet/Property/allproperties.scss";
import UploadButton from "../../../MainComponent/fields/upload";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import Propertydetails from "./popups/propertydetails";
import { createColumnHelper } from "@tanstack/react-table";
import PropertyCreate from "./popups/PropertyCreate";
import { useFetchProperties } from "../../../Api/PropertiesQuery";
import { useSelector } from "react-redux";
import { formatPounds } from "../../../Utils/Utils";
import { useFetchPropertiesPagination } from "../../../Api/PaginationQuery";
import { useInView } from "react-intersection-observer";

export default function AllProperties() {
  const columnHelper = createColumnHelper();
  const [count, setCount] = useState(0);
  const [tableCount, setTableCount] = useState(0);

  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);

  const clientId = useSelector((state) => state.counter.loginuserdata.id);

  const [search, setsearch] = useState("");
  const [switcher, setswitcher] = useState(false);
  const [popup, setpopup] = useState(false);
  const [rowvalue, setrowvalue] = useState([]);
  const [dataLoader, seDataLoader] = useState([]);
  const [createPropertyPopup, setCreatePropertyPopup] = useState(false);
  const [editData, setEditData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filter, setFiler] = useState("All");
  const filterGen = () => {
    if (filter === "All") {
      return "All";
    } else if (filter === "Approved") {
      return true;
    } else if (filter === "Under Approval") {
      return false;
    }
  };
  const fetchProperties = useFetchProperties({
    params: {
      client: clientId,
      page: pageindex,
      page_size: pagesize,
      status: filterGen(),
      count: count,
    },
  });
  const { data, fetchNextPage, hasNextPage } = useFetchPropertiesPagination({
    params: {
      client: clientId,
      status: filterGen(),
      page: pageindex,
      page_size: 10,
    },
  });
  useEffect(() => {
    if (data != undefined) {
      // dataLoader;
      const allResults = data?.pages?.flatMap((page) => page?.results);
      console.log(allResults, "lajkslfjasjdlaslfdj");
      seDataLoader(allResults);
      setCount(
        data?.pages?.[0]?.count ? data?.pages?.[0]?.count : data?.[0]?.count
      );
    }
  }, [data]);
  useEffect(() => {
    console.log(data, "lakjsljdfajksdflj");
  }, [data]);

  console.log(popup, "popup");
  function formatAddress(addressParts) {
    return addressParts
      .filter((part) => part) // Filters out undefined, null, and empty strings
      .join(", ");
  }
  const columns = [
    columnHelper.accessor((row) => row, {
      id: "address",
      cell: (info) => {
        console.log(info.row.original, "kajsdlfkjlaskjdlfij");
        const data = info.row.original;

        return (
          <p>
            {formatAddress([data.name, data.street, data.postcode, data.city])}
          </p>
        );
      },
      header: () => <span>Address</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "landlordName",
      cell: (info) => {
        const data = info.row.original?.landlord?.contact_detail?.[0];
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data?.title && data?.first_name && data?.last_name
              ? `${data.title} ${data.first_name} ${data.last_name}`
              : " -- "}
          </p>
        );
      },
      header: () => <span>Landlord Name</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "rooms",
      cell: (info) => {
        const data = info.row.original?.rooms;
        return <p style={{ textTransform: "capitalize" }}>{`  --  `}</p>;
      },
      header: () => <span>Bedrooms</span>,
    }),
    columnHelper.accessor("status", {
      header: "Occupancy Status",
      cell: (info) => {
        console.log(info.getValue());

        return (
          <p
            className={
              info.getValue() === "Approved" ? "Approved" : "NotApproved"
            }
            style={{
              fontSize: "16px",
              height: "auto",
              padding: "8px 14px",
              background:
                info.getValue() === "Let in Progress"
                  ? "#CEFFE1"
                  : info.getValue() === "Occupied"
                  ? "#CEFFE1"
                  : info.getValue() === "Available"
                  ? "rgb(255,216,212)"
                  : info.getValue() === "Vacate in Progress"
                  ? "rgb(255,240,204)"
                  : "",
            }}
          >
            {info.getValue() === "Renewal in Progress"
              ? "Occupied"
              : info.getValue() === "Let in Progress"
              ? "Occupied"
              : info.getValue() === "Vacate in Progress"
              ? "Upcoming Vacancies"
              : info.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "rent",
      cell: (info) => {
        const data = info.row.original?.tenant?.rent;
        console.log(info.row.original, "lakjsdlafj");
        return (
          <p style={{ textTransform: "capitalize" }}>
            {data ? `£${formatPounds(data)}` : "--"}
          </p>
        );
      },
      header: () => <span>Property Rent</span>,
    }),
  ];
  const clickrow = (row) => {
    console.log(row, "laksjdfljaskdfjasjofj");
    setSelectedData(row);
    setpopup(true);
    setrowvalue(row);
  };
  const handleCreateProperty = () => {
    setCreatePropertyPopup(true);
  };
  useEffect(() => {
    if (fetchProperties.data?.data?.results) {
      // seDataLoader(fetchProperties.data?.data?.results);
      console.log(fetchProperties.data?.data, "lasjdflasjdlfl");
      setTableData(fetchProperties.data?.data?.results);
      setTableCount(fetchProperties.data?.data?.count);
    }
  }, [fetchProperties.data, pageindex, pagesize]);
  const loading = useSelector((state) => state.counter.loader);

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  if (loading) {
    return null; // Or some loading component
  }
  return (
    <>
      <PropertyCreate
        isOpen={createPropertyPopup}
        setIsOpen={setCreatePropertyPopup}
        editData={editData}
        setEditData={setEditData}
      />
      <div className="allproperties_main_wrapper">
        <div className="header">
          <div
            className=" button_wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              width: "450px",
            }}
          >
            <div className="">
              <button
                className={`all ${filter === "All" && "activated"}`}
                onClick={() => {
                  setFiler("All");
                }}
              >
                <span>All</span>
              </button>
            </div>
            <div className="">
              <button
                className={`approved ${filter === "Approved" && "activated"}`}
                onClick={() => {
                  setFiler("Approved");
                }}
              >
                <span>Approved</span>
              </button>
            </div>
            <div className="">
              <button
                className={`notapproved ${
                  filter === "Under Approval" && "activated"
                }`}
                onClick={() => {
                  setFiler("Under Approval");
                }}
              >
                <span>Under Approval</span>
              </button>
            </div>
          </div>
          <div className="search_wrapper">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div className="">
                <Search setsearch={setsearch} style={{ width: "421px" }} />
              </div>
              <div className="">
                <UploadButton handleClick={handleCreateProperty} />
              </div>
              <div className="views_button_wrapper">
                <button
                  className={!switcher ? "active" : ""}
                  onClick={(e) => {
                    setswitcher(false);
                  }}
                >
                  <i className="bi bi-grid"></i>
                </button>
                <button
                  className={switcher ? "active" : ""}
                  onClick={(e) => {
                    setswitcher(true);
                  }}
                >
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="allproperties_wrapper">
          {!switcher ? (
            <div className="row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div className="totalArrears">
                  <span>Total Properties</span>
                  <span>{count}</span>
                </div>
              </div>
              {dataLoader?.[0] !== undefined &&
                dataLoader.map((item, index) => {
                  return (
                    <div
                      className="col-md-6 col-sm-6 col-lg-4 col-xs-4 mb-4"
                      onClick={(e) => {
                        clickrow(item);
                        // if (item.status !== "Available") {
                        // } else {
                        //   toast.warning("The selected property is vacant.");
                        // }
                      }}
                      style={{ width: "25%", borderRadius: "13px" }}
                    >
                      <div className="card">
                        <div
                          style={{
                            backgroundImage: `url('${item?.media?.[0].url}')`,
                          }}
                          className="imageDiv"
                        ></div>

                        <span
                          style={{
                            background:
                              item?.status === "Let in Progress"
                                ? "#CEFFE1"
                                : item?.status === "Occupied"
                                ? "#CEFFE1"
                                : item?.status === "Available"
                                ? "rgb(255,216,212)"
                                : item?.status === "Vacate in Progress"
                                ? "rgb(255,240,204)"
                                : "",
                          }}
                        >
                          {item?.status === "Let in Progress"
                            ? "Occupied"
                            : item?.status === "Vacate in Progress"
                            ? "Upcoming Vacancy"
                            : item?.status === "Available"
                            ? "Vacant"
                            : item?.status}
                        </span>
                        <div className="card-body">
                          <h1 className="card-title">
                            {item?.name} {item?.street}
                          </h1>
                          <p className="card-text">
                            {formatAddress([
                              item?.name + " " + item?.street,
                              item?.city,
                              item?.postcode,
                            ])}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <TableComponent
              data={tableData ? tableData : []}
              columns={columns}
              onclickrow={clickrow}
              count={tableCount}
              setCount={setTableCount}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
            />
          )}
        </div>
      </div>
      <div ref={ref} style={{ height: "1px" }} />
      {popup ? (
        <Propertydetails
          rowvalue={rowvalue}
          setpopup={setpopup}
          selectedData={"selectedData"}
        />
      ) : (
        ""
      )}
    </>
  );
}
