import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../popups/PropertyCreate.scss";
import closeIcon from "../../../../Assets/Common/closeIcon.svg";
import pdfThumbnail from "../../../../Assets/Common/pdf_image.svg";

import {
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePropertyFilePost,
} from "../../../../Api/PropertiesQuery";
import { addLeadingZero } from "../../../../Utils/Utils";
const UploadPropertyDocument = (props) => {
  const { dataFromParent, propertyApprovalFolderId } = props;
  const [coImage, setCoImage] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("idel");
  const [data, setData] = useState({ image: [] });
  const [ImageData, setImageData] = useState({ image: [] });
  const [enrollPresignedImage, setEnrollPresignedImage] = useState([]);
  const [presignedURL, setPresignedURL] = useState("");
  const [binaryImageData, setBinaryImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);
  const handleClose = () => {
    setCoImage([]);
    setImageData({ image: [] });
    setEnrollPresignedImage([]);
    setPresignedURL("");
    setBinaryImageData("");
    setImageType("");
    setPresignedUrlPayload({
      multiple_files: [],
    });
  };
  const postePropertyFile = usePropertyFilePost({
    data: data,
    handleClose: handleClose,
  });
  const updatePresignedUrlPUT = usePresignedUrlPut({
    url: presignedURL,
    imagePayload: binaryImageData,
    type: imageType,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Limiting to 8 files
      const newFiles = acceptedFiles.slice(0, 8).map((upFile) =>
        Object.assign(upFile, {
          preview: window.URL.createObjectURL(upFile),
        })
      );

      setUploadStatus(`pending${newFiles[0].name}`);
      console.log(newFiles[0].name, "newFiles");
      setCoImage([...coImage, ...newFiles]);
      setEnrollPresignedImage(newFiles);
    },
    maxFiles: 8, // Maximum number of files allowed
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
  });
  const removefunction = (indexValue) => {
    const updatedCoImage = coImage.filter((_, i) => i !== indexValue);
    setCoImage(updatedCoImage);
    setImageData((prevData) => {
      const updatedImages = prevData.image.filter((_, i) => i !== indexValue);
      return {
        ...prevData,
        image: updatedImages,
      };
    });
  };
  useEffect(() => {
    if (enrollPresignedImage.length > 0) {
      setPresignedUrlPayload({
        multiple_files: [
          {
            filename: `property/${enrollPresignedImage[0].path}`,
            file_type: enrollPresignedImage[0].type,
          },
        ],
      });
      setTimeout(() => {
        createPresignedURL.mutate();
      }, 100);
    }
  }, [enrollPresignedImage]);

  useEffect(() => {
    console.log(createPresignedURL.data, "data111");
    if (createPresignedURL.data?.data?.[0] !== undefined) {
      const removeQueryParams = (url) => url.split("?")[0];
      console.log("hitting bro", createPresignedURL.data.data[0]);
      const output = createPresignedURL.data.data.map(removeQueryParams);
      setPresignedURL(createPresignedURL.data.data[0]);
      setBinaryImageData(enrollPresignedImage[0]);
      setImageType(enrollPresignedImage[0].type);
      setImageData((prevValue) => ({
        ...prevValue,
        image: [...prevValue?.image, ...output],
      }));
      setTimeout(() => {
        updatePresignedUrlPUT.mutate();
      }, 200);
    }
  }, [createPresignedURL.data]);

  useEffect(() => {
    var count = 0;
    if (updatePresignedUrlPUT.data != undefined && count == 0) {
      console.log(updatePresignedUrlPUT.data, "dkfujfj");
      count = 1;
      setUploadStatus(
        `success${updatePresignedUrlPUT.data?.config?.data?.name}`
      );
      setTimeout(() => {
        setUploadStatus("idel");
      }, 1000);
    }
  }, [updatePresignedUrlPUT.data]);
  // useEffect(() => {
  //   console.log(Type1, 'slakjdflfs;ka;skd');
  // }, [Type1]);
  useEffect(() => {
    var count = 0;
    if (updatePresignedUrlPUT.data != undefined && count == 0) {
      console.log(updatePresignedUrlPUT.data, "dkfujfj");
      count = 1;
      setUploadStatus(
        `success${updatePresignedUrlPUT.data?.config?.data?.name}`
      );
      setTimeout(() => {
        setUploadStatus("idel");
      }, 1000);
    }
  }, [updatePresignedUrlPUT.data]);

  const handleSubmit = (e) => {
    const dummyData = {
      document_type: propertyApprovalFolderId?.[0]?.id,
      file: ImageData.image,
      custom_name: "Approval Folder",
      contact: dataFromParent.id,
      property: dataFromParent.id,
      type: "Property",
      expiry_date: "",
    };
    setData(dummyData);
    console.log(dummyData, "laskjdlfjalsjjsadjlsdj");
    console.log(dataFromParent, "laskjdlfjalsjjsadjlsdj");
    setTimeout(() => {
      postePropertyFile.mutate();
    }, 300);
  };
  useEffect(() => {
    console.log(propertyApprovalFolderId, "laskjdfljasldf");
    console.log(
      propertyApprovalFolderId?.[0]?.documents.length,
      "laskjdfljasldf"
    );
  }, [propertyApprovalFolderId]);
  return (
    <div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <div
          className="accountBalanceCard"
          style={{
            width: "310px",
            marginRight: "97px",
            padding: "0",
          }}
        >
          <div className="wrapper">
            <span style={{ fontSize: "22px", fontWeight: "400" }}>
              Files Under Review
            </span>
          </div>
          <span>
            <span
              style={{
                fontSize: "26px",
                fontWeight: "500",
                background: "#817eff",
                height: "54px",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                borderRadius: "13px",
              }}
            >
              {addLeadingZero(propertyApprovalFolderId?.[0]?.documents.length)}
            </span>
          </span>
        </div>
      </div>
      <div className="Upload_pic" style={{ height: "545px" }}>
        <p
          className="Header"
          style={{
            marginLeft: "77px",
            // marginTop: "40px",
            color: "#000000",
          }}
        >
          Please upload documents related to this property
        </p>
        <p
          className="Header"
          style={{
            marginLeft: "77px",
            marginTop: "0px",
            color: "#808080",
            fontWeight: "400",
          }}
        >
          The uploaded documents will be reviewed and updated by the property
          manager.
        </p>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className={`imagedropper ${coImage.length > 0 && "actives"}`}
            style={{ justifyContent: "center", width: "90%", padding: "25px" }}
          >
            <div
              {...(coImage.length < 8 ? getRootProps() : {})}
              className={`Imagedropper_inner`}
              style={{ cursor: "pointer", justifyContent: "flex-end" }}
            >
              {
                <>
                  {coImage.length < 8 ? (
                    <>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "21px",
                            marginBottom: "12px",
                            color: "black",
                          }}
                        >
                          Drag documents here to upload
                        </p>
                        <p
                          style={{
                            fontSize: "21px",
                            marginBottom: "12px",
                          }}
                        >
                          Or
                        </p>
                        <button
                          className="Next_Button"
                          style={{
                            outline: `1px solid #817eff`,
                            marginBottom: "20px",
                            width: "fit-content",
                            background: "white",
                            color: "#817eff",
                            fontSize: "22px",
                          }}
                        >
                          Upload Document
                        </button>
                      </div>
                    </>
                  ) : (
                    <span> Max upload reached</span>
                  )}
                </>
              }
            </div>
            <div className="Image_Viewer">
              {coImage.map((upFile, index) => {
                const isImage = upFile.type.includes("image");
                return (
                  <div key={index} style={{ position: "relative" }}>
                    {isImage ? (
                      <img
                        src={upFile.preview}
                        className="ImageView"
                        alt="preview"
                        style={{
                          width: "90px",
                          height: "90px",
                          marginLeft: "25px",
                          marginTop: "25px",
                        }}
                      />
                    ) : (
                      <img
                        src={pdfThumbnail}
                        className="PdfView"
                        alt="pdf preview"
                        style={{
                          width: "90px",
                          height: "90px",
                          marginLeft: "25px",
                          marginTop: "10px",
                        }}
                      />
                    )}
                    <span
                      className="cancle_img"
                      onClick={(e) => removefunction(index)}
                      style={{
                        fontSize: "14px",
                        top: isImage ? "16px" : "3px",
                        right: "-5px",
                      }}
                    >
                      <img
                        src={closeIcon}
                        alt="closeIcon"
                        style={{ width: "12px", height: "20px" }}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "94%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <button
          className="Next_Button"
          onClick={(e) => {
            handleSubmit(e);
          }}
          style={{
            outline: `1px solid #817eff`,
            marginBottom: "20px",
            width: "fit-content",
            // background: "white",
            // color: "#817eff",
            fontSize: "22px",
            width: "200px",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UploadPropertyDocument;
